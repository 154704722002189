import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { Sale } from '@/components/common/tables/services-sold-table/services-sold-tables.types';
import { PaymentTable } from '@/components/common/tables/payments-table/payments-table.types';
import { format } from 'date-fns';
import { GetPaymentsProps } from '@/hooks/queries/payments-api/useGetPaginatedPayments';

export type TNewPayment = {
  paymentType: string;
  sale: string;
  amount: number;
  observations?: string;
  paidAt: number;
};
interface Response {
  total: number;
  limit: number;
  offset: number;
  page: number;
  hasNextPage: boolean;
}
interface TNewPaymentResponse extends Response {
  data: Sale;
}
interface TGetPayments extends Response {
  results: PaymentTable[];
}
export class PaymentsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async postPayment(body: TNewPayment): Promise<any> {
    const response: ApiResponse<TNewPaymentResponse> =
      await this.api.apisauce.post(`payment`, body);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async patchPayment(paymentId: string, body: TNewPayment): Promise<any> {
    const response: ApiResponse<TNewPaymentResponse> =
      await this.api.apisauce.patch(`payment/${paymentId}`, body);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async getPayments(): Promise<any> {
    const response: ApiResponse<TGetPayments> =
      await this.api.apisauce.get(`payment`);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results || [];
  }

  async getPaginatedPayments({
    pagination,
    sorting,
    filtering,
    columnFilters
  }: GetPaymentsProps): Promise<TGetPayments | undefined> {
    //set paignation
    const page = pagination.pageIndex + 1;
    const limit = pagination.pageSize;

    // set sorting
    let sortBy = '';
    let sortDirection = '';

    //set columnFilters
    let columnFilterURI = '';

    columnFilters.forEach((filter) => {
      const valuesOfFilter = filter.value;
      let valueQueryString: string = '';

      if (Array.isArray(valuesOfFilter)) {
        valueQueryString = `&filter.${filter.id}=string.in.${valuesOfFilter.join(',')}`;
      } else {
        const dateRange = valuesOfFilter as {
          from: string;
          to: Date | undefined;
        };
        if (!dateRange.to) {
          const date = new Date(dateRange.from);
          // Set the time to the last hour of the day (23:00:00)
          date.setHours(23, 0, 0, 0);
          dateRange.to = date;
        }
        if (dateRange.from && dateRange.to) {
          valueQueryString = `&filter.createdAt=date.between.${format(dateRange.from, "yyyy-MM-dd'T'HH:mm:ss")},${format(dateRange.to, "yyyy-MM-dd'T'HH:mm:ss")}`;
        }
      }

      columnFilterURI += valueQueryString;
    });

    for (let i = 0; i < sorting.length; i++) {
      const id = sorting[i].id,
        direction = sorting[i].desc ? 'DESC' : 'ASC';
      sortBy += id;
      sortDirection += direction;

      if (i !== sorting.length - 1) {
        sortBy += ',';
        sortDirection += ',';
      }
    }

    let URI = `payment?limit=${limit}&page=${page}`;
    if (filtering) {
      const filteringWithoutDot = filtering.replace('.', ''); //we remove [.] to avoid 500 error. Needs to be reviewd if we search for emails
      URI += `&filter.or.sale.user.firstName=string.like.${filteringWithoutDot}&filter.or.sale.user.lastName=string.like.${filteringWithoutDot}&filter.or.id=string.like.${filteringWithoutDot}`;
    }
    if (sorting.length) {
      URI += `&sortBy=${sortBy}&sortDirection=${sortDirection}`;
    }
    if (columnFilters.length) {
      URI += columnFilterURI;
    }
    const response: ApiResponse<TGetPayments> =
      await this.api.apisauce.get(URI);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
