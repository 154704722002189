import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import useDeleteSports from '@/hooks/queries/sports-api/useDeleteSports';
import { useTranslation } from 'react-i18next';
import { DeleteServicesCategoryModalT } from './delete-services-category-modal.types';
import { Loader } from 'lucide-react';

const DeleteServicesCategoryModal = ({
  open,
  onOpenChange,
  sportId
}: DeleteServicesCategoryModalT) => {
  const { t } = useTranslation();
  const { mutate, isPending } = useDeleteSports();

  const handleDelete = () => {
    mutate(sportId);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('modals.alert.title')}</DialogTitle>
          <DialogDescription>
            {t('modals.alert.deleteServicesCategory')}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t('modals.alert.close')}</Button>
          </DialogClose>
          <Button
            variant="destructive"
            onClick={handleDelete}
            disabled={isPending}
          >
            {isPending ? (
              <Loader className="w-4 h-4 animate-spin" />
            ) : (
              t('modals.alert.delete')
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteServicesCategoryModal;
