import { DESCRIPTION_MAX_LENGTH } from '@/utils/utils';
import { z } from 'zod';

const serviceDivisionSchema = z.object({
  name: z
    .string({ required_error: 'El nombre de la division es requerido.' })
    .min(2, {
      message: 'El nombre debe tener al menos 2 caracteres.'
    })
    .max(100, 'El nombre no puede contener más de 100 caracteres.'),
  description: z
    .string()
    .max(
      DESCRIPTION_MAX_LENGTH.medium,
      `La descripción no puede contener más de ${DESCRIPTION_MAX_LENGTH.medium} caracteres.`
    )
    .optional(),
  duration: z.coerce
    .number({
      required_error: 'La duración es requerida.',
      invalid_type_error: 'La duración es requerida.'
    })
    .min(1, { message: 'La duracion debe ser mayor a 1 minuto' }),
  quantity: z.coerce
    .number({
      required_error: 'La cantidad es requerida.',
      invalid_type_error: 'La cantidad es requerida.'
    })
    .min(1, { message: 'La cantidad debe ser mayor a 1' })
});

export default serviceDivisionSchema;
