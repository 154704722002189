import { Button } from '@/components/ui/button';
import useGetOrganizationServices from '@/hooks/queries/services-api/useGetOrganizationServices';
import useStatus from '@/hooks/useStatus';
import { TServices } from '@/services/services';
import { Table } from '@tanstack/react-table';
import { XIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import TableColumnsToggle from '../../table-columns-toggle/TableColumnsToggle';
import { TableFacetedFilter } from '../../table-faceted-filter/TableFacetedFilter';
import TableFilters from '../../table-filters/TableFilters';
import TableSearchInput from '../common/table-search-input/TableSearchInput';

type TableToolBarProps<TData> = Readonly<{
  table: Table<TData>;
  filtering: string;
  setFiltering: (value: string) => void;
  emptyData: boolean;
}>;

export default function TableToolBar<TData>({
  table,
  emptyData,
  filtering,
  setFiltering
}: TableToolBarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  const { t } = useTranslation();
  const { isPending, data: services } =
    useGetOrganizationServices<TServices[]>();

  const { statuses } = useStatus('reservations');

  return (
    <div className="flex flex-col items-start justify-between gap-2 py-4 md:flex-row">
      <TableFilters filterActive={isFiltered}>
        <div className="flex flex-col items-start w-full gap-2">
          {table.getColumn('reservationStatus.name') && (
            <TableFacetedFilter
              column={table.getColumn('reservationStatus.name')}
              title={t('common.status.status')}
              options={statuses}
              disabled={emptyData}
              facetNumbers
            />
          )}
          {table.getColumn('service.name') && (
            <TableFacetedFilter
              column={table.getColumn('service.name')}
              title={t('tables.common.activity')}
              options={
                services?.length
                  ? services?.map((service) => {
                      return {
                        label: service.name,
                        value: service.name
                      };
                    })
                  : []
              }
              disabled={isPending || !services?.length || emptyData}
            />
          )}
          {isFiltered && (
            <Button
              variant={'secondary'}
              onClick={() => {
                table.resetColumnFilters();
              }}
              className="w-full"
            >
              {t('common.clearFilters')}
              <XIcon className="w-4 h-4 ml-2" />
            </Button>
          )}
        </div>
      </TableFilters>
      <div className="flex flex-col-reverse items-start justify-center gap-2 md:flex-row">
        <div className="w-full">
          <TableSearchInput
            value={filtering ?? ''}
            onChange={(event) => {
              setFiltering(event.target.value);
            }}
          />
        </div>
        <TableColumnsToggle disabled={emptyData} table={table} />
      </div>
    </div>
  );
}
