import ActiveDaysCard from '@/components/common/cards/active-days-card/ActiveDaysCard';
import ActiveMonthsCard from '@/components/common/cards/active-months-card/ActiveMonthsCard';
import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import {
  modalBlockReducer,
  initalBlockState
} from '@/components/common/modals/block-service-reducer/block-modal-reducer';
import BlockServiceReducerContainer from '@/components/common/modals/block-service-reducer/BlockServiceReducer';

import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SettingsPage = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(modalBlockReducer, initalBlockState);
  const { data: organization, isError } = useGetOrganization();

  if (!organization) {
    return (
      <div className="w-full">
        <Skeleton className="h-6" />
        <Skeleton className="h-10 mt-4" />
        <Skeleton className="mt-5 h-[20rem]" />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <div className="w-full">
        <h2 className="text-xl font-bold">{t('sidebar.settings')}</h2>
        <div className="mt-4">
          <div className="flex items-center gap-2">
            <p>{t('settings.schoolAvailability')}:</p>
            <Button
              variant={'outline'}
              onClick={() => dispatch({ type: 'OPEN_MODAL', payload: ' ' })}
            >
              {t('services.block')}
            </Button>
          </div>
        </div>
      </div>
      <BlockServiceReducerContainer
        isSchool
        state={state}
        dispatch={dispatch}
      />
      <div className="flex flex-wrap gap-5 my-5">
        <ActiveMonthsCard organization={organization} />
        <ActiveDaysCard organization={organization} />
      </div>
      <p className="pb-4">App version: {version}</p>
    </>
  );
};

export default SettingsPage;
