import InputSelect from '@/components/common/input/input-select/InputSelect';
import DeleteServicesModal from '@/components/common/modals/delete-services-modal/DeleteServicesModal';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import serviceSchema from '@/schemas/service';
import {
  ALLOW_PAYMENT,
  CAN_UPDATE_RESERVATIONS,
  VISIBILITY
} from '@/utils/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ServiceFormProps, ServicesFormValues } from './ServiceForm.types';

const ServiceForm: React.FC<ServiceFormProps> = ({
  defaultValues,
  onSubmit,
  isPending,
  sport
}) => {
  const { t } = useTranslation();
  const [deleteServicesModal, setDeleteServicesModal] = useState(false);

  const form = useForm<ServicesFormValues>({
    resolver: zodResolver(serviceSchema),
    defaultValues: defaultValues || {
      name: '',
      description: '',
      price: 0,
      reservationPct: 0,
      minPreReservationTime: 0,
      sport: '',
      allowPayment: 'all',
      visibility: 'all',
      canUpdateReservations: 'all',
      minConsumers: 0,
      maxConsumers: 0,
      minAge: 0,
      maxAge: 0
    },
  });
  
  const hasChanged = !form.formState.isDirty;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-8 rounded-md"
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.name')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('form.description')}
                <span className="ml-2 text-sm font-light text-gray-500">
                  {t('form.optional')}
                </span>
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="price"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.price')}</FormLabel>
              <FormControl>
                <Input type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="reservationPct"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.reservePercentage')}</FormLabel>
              <FormDescription>
                {t('form.reservePercentageDescription')}
              </FormDescription>
              <FormControl>
                <Input type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="minPreReservationTime"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.minPreReservationTime')}</FormLabel>
              <FormDescription>
                {t('form.minPreReservationTimeDescription')}
              </FormDescription>
              <FormControl>
                <Input type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="minConsumers"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('services.minConsumers')}</FormLabel>
              <FormControl>
                <Input type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="maxConsumers"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('services.maxConsumers')}</FormLabel>
              <FormControl>
                <Input type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="minAge"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('services.minAge')}</FormLabel>
              <FormControl>
                <Input type="number" {...field} value={field.value ?? ''} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="maxAge"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('services.maxAge')}</FormLabel>
              <FormControl>
                <Input type="number" {...field} value={field.value ?? ''} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="sport"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.category')}</FormLabel>
              <InputSelect
                placeholder={t('form.sportSelectPlacheHolder')}
                onValueChange={field.onChange}
                value={field.value}
                options={sport.map((sport) => ({
                  value: sport.id,
                  label: sport.name
                }))}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="allowPayment"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.allowPayment')}</FormLabel>
              <FormDescription>
                {t('form.allowPaymentDescription')}
              </FormDescription>
              <InputSelect
                placeholder={t('common.visibilitySelect')}
                onValueChange={field.onChange}
                value={field.value}
                options={Object.keys(ALLOW_PAYMENT).map((key) => ({
                  value: key,
                  label: t(`common.${key}`)
                }))}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="visibility"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.visibility')}</FormLabel>
              <FormDescription>
                {t('form.visibilityDescription')}
              </FormDescription>
              <InputSelect
                placeholder={t('common.visibilitySelect')}
                onValueChange={field.onChange}
                value={field.value}
                options={Object.keys(VISIBILITY).map((key) => ({
                  value: key,
                  label: t(`common.${key}`)
                }))}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="canUpdateReservations"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.canUpdateReservations')}</FormLabel>
              <FormDescription>
                {t('form.canUpdateReservationsDescription')}
              </FormDescription>
              <InputSelect
                placeholder={t('common.visibilitySelect')}
                onValueChange={field.onChange}
                value={field.value}
                options={Object.keys(CAN_UPDATE_RESERVATIONS).map((key) => ({
                  value: key,
                  label: t(`common.${key}`)
                }))}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-end gap-4">
          <Button disabled={isPending || hasChanged}>
            {isPending ? (
              <Loader className="w-4 h-4 animate-spin" />
            ) : defaultValues ? (
              t('form.update')
            ) : (
              t('form.create')
            )}
          </Button>
          {defaultValues && (
            <>
              <Button
                variant="destructive"
                type="button"
                onClick={() => setDeleteServicesModal(true)}
              >
                {t('form.delete')}
              </Button>
              <DeleteServicesModal
                open={deleteServicesModal}
                onOpenChange={setDeleteServicesModal}
              />
            </>
          )}
        </div>
      </form>
    </Form>
  );
};

export default ServiceForm;
