import api from '@/services/api';
import { TPatchService } from '@/services/services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
function usePatchService({
  isSwitchService
}: { isSwitchService?: boolean } = {}) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ['edit-service'],
    mutationFn: ({
      payload,
      serviceId
    }: {
      payload: Partial<TPatchService>;
      serviceId: string;
    }) => {
      return api.services.patchService(payload, serviceId);
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: (value) => {
      queryClient.invalidateQueries({
        queryKey: ['services']
      });
      toast.success(t('toast.serviceUpdated'));

      !isSwitchService && navigate(-1);

      // if the service switch enabled was executed, modify query
      if (isSwitchService) {
        queryClient.setQueryData(
          ['services', value.id],
          (previousValue: any) =>
            previousValue
              ? { ...previousValue, enabled: value.enabled }
              : undefined
        );
      }
    },
    retry: false
  });
}

export default usePatchService;
