import { Button } from '@/components/ui/button';
import api from '@/services/api';
import { useRootStore } from '@/stores/root-store';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const {
    loginWithRedirect,
    isAuthenticated,
    getAccessTokenSilently,
    isLoading
  } = useAuth0();

  const setToken = useRootStore((store) => store.setToken);
  const { t } = useTranslation();

  const refreshToken = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const newToken = await getAccessTokenSilently();
        setToken(newToken);
        api.api.setToken(newToken);
        const expirationTime =
          JSON.parse(atob(newToken.split('.')[1])).exp * 1000;
        const timeUntilExpiration = expirationTime - Date.now();
        const refreshTime = Math.max(timeUntilExpiration - 60 * 60 * 1000, 0);
        setTimeout(refreshToken, refreshTime);
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
    }
  }, [getAccessTokenSilently, setToken, isAuthenticated]);

  useEffect(() => {
    refreshToken();
  }, [refreshToken]);

  return (
    <div className="w-full h-screen bg-primary">
      <div className="absolute right-0">
        <img
          src="/form-1.png"
          className="relative lg:w-[50rem] lg:h-[40rem]"
          alt="form"
        />
      </div>
      <div className="absolute bottom-0 -left-60">
        <div className="rounded-full w-[34rem] h-[34rem] bg-[#2DBAE5]  flex items-center justify-center">
          <div className="rounded-full w-[24rem] h-[24rem] bg-primary  flex items-center justify-center">
            <div className="rounded-full w-60 h-60 bg-[#2DBAE5] " />
          </div>
        </div>
      </div>
      <div className="relative z-10 flex flex-col items-center justify-center h-full gap-14">
        <img
          src="/Ocean-flow-Logo.png"
          className="w-36 h-36"
          alt="Ocean flow"
        />
        <Button
          variant={'secondary'}
          className="w-48 shadow-lg"
          disabled={isLoading}
          onClick={() =>
            loginWithRedirect({
              appState: {
                returnTo: '/quick-actions'
              }
            })
          }
        >
          {t('session.login')}
        </Button>
      </div>
    </div>
  );
};

export default HomePage;
