import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import usePostEquipmentAssign from '@/hooks/queries/equipments-api/usePostEquipmentAssign';
import { cn } from '@/lib/utils';
import { checkAndPush } from '@/utils/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChevronsUpDown, Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { TequipmentAssign } from './form-equipment-assign.types';

const EquipmentAssignmentForm = ({
  reservationId,
  consumerId,
  onSave,
  materialAvailable,
  validationSchema,
  defaultValues
}: TequipmentAssign) => {
  const [openOption, setOpenOption] = useState<null | string>(null);
  const { t } = useTranslation();
  const form = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: defaultValues
  });
  const { mutate, isPending } = usePostEquipmentAssign();
  function onSubmit(values: z.infer<typeof validationSchema>) {
    const equipmentSelected = checkAndPush(values);

    const formValues = {
      equipment: equipmentSelected,
      reservation: reservationId,
      consumer: consumerId
    };
    mutate({ data: formValues }, { onSuccess: () => onSave() });
  }
  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          {materialAvailable.map((option, index) => (
            <FormField
              key={option.type}
              control={form.control}
              name={`select${index}`}
              render={({ field }) => (
                <>
                  <FormItem className="flex flex-col mb-6">
                    <FormLabel>{option.type}</FormLabel>
                    <Popover
                      open={openOption === option.type}
                      onOpenChange={() =>
                        openOption
                          ? setOpenOption(null)
                          : setOpenOption(option.type)
                      }
                      modal={true}
                    >
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn('justify-between')}
                          >
                            {field.value
                              ? (() => {
                                  const value = option?.equipment.find(
                                    (item) => item?.id === field.value
                                  );
                                  return `#${value?.code} ${value?.name}`;
                                })()
                              : option.type}
                            <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent>
                        {
                          <Command className={'max-h-[15rem]'}>
                            <CommandInput placeholder={option.type} />
                            <CommandList>
                              <CommandEmpty>
                                {t('common.noResults')}
                              </CommandEmpty>
                              <CommandGroup>
                                {option?.equipment.map((item) => {
                                  return (
                                    <CommandItem
                                      key={item.id}
                                      onSelect={() => {
                                        field.onChange(item.id);
                                        setOpenOption(null);
                                      }}
                                    >
                                      #{item.code} {item.name}
                                    </CommandItem>
                                  );
                                })}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        }
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                </>
              )}
            />
          ))}

          <Button type="submit" className="w-full mt-2" disabled={isPending}>
            {isPending ? (
              <Loader className="w-4 h-4 mr-2 animate-spin" />
            ) : (
              t('common.save')
            )}
          </Button>
        </form>
      </Form>
    </>
  );
};
export default EquipmentAssignmentForm;
