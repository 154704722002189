import { Button } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip';
import {
  ArrowLeft,
  ArrowRight,
  BarChart,
  CalendarClock,
  DollarSign,
  FileCheck2,
  FishIcon,
  Sailboat,
  Settings,
  UsersIcon,
  UserRoundPlus,
  Zap,
  CalendarDaysIcon
} from 'lucide-react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TMenuItem } from './sidebar.types';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
  const [menuOpen, setMenuOpen] = useState(true);
  const location = useLocation();
  const { t } = useTranslation();

  const featureFlags = process.env.FEATURE_FLAG
    ? JSON.parse(process.env.FEATURE_FLAG)
    : {};

  const isActive = (value: string) => {
    return featureFlags[value] !== undefined ? featureFlags[value] : true;
  };

  const MENU_ITEMS: TMenuItem[] = [
    {
      icon: <Zap />,
      title: t('sidebar.quickActions'),
      link: '/quick-actions',
      active: isActive('quickActions')
    },
    {
      icon: <BarChart />,
      title: t('sidebar.dashboard'),
      link: '/dashboard',
      active: isActive('dashboard')
    },
    {
      icon: <UserRoundPlus />,
      title: t('sidebar.newBooking'),
      link: '/new-booking',
      active: isActive('newBooking')
    },
    {
      icon: <FileCheck2 />,
      title: t('sidebar.sales'),
      link: '/services-sold',
      active: isActive('sales')
    },
    {
      icon: <CalendarClock />,
      title: t('sidebar.reservations'),
      link: '/reservations',
      active: isActive('reservations')
    },
    {
      icon: <UsersIcon />,
      title: t('sidebar.users'),
      link: '/users',
      active: isActive('users')
    },
    {
      icon: <DollarSign />,
      title: t('sidebar.payments'),
      link: '/payments',
      active: isActive('payments')
    },

    {
      icon: <CalendarDaysIcon />,
      title: t('sidebar.calendar'),
      link: '/calendar',
      active: isActive('calendar')
    },

    {
      icon: <FishIcon />,
      title: t('sidebar.services'),
      link: '/services',
      active: isActive('services')
    },
    {
      icon: <Sailboat />,
      title: t('sidebar.inventory'),
      link: '/inventory',
      active: isActive('inventory')
    },
    {
      icon: <Settings />,
      title: t('sidebar.settings'),
      link: '/settings',
      active: isActive('settings')
    }
  ];

  return (
    <div className="flex flex-col justify-between h-full overflow-auto shadow-md flex-grow-1">
      <ul className="flex flex-col py-5 ">
        {MENU_ITEMS.filter((item) => item.active).map((item) => (
          <TooltipProvider key={item.title}>
            <Tooltip>
              <TooltipTrigger>
                <Link to={item.link}>
                  <li
                    className={`flex align-middle hover:text-primary px-7 py-3 ${menuOpen ? 'justify-start' : 'justify-center'} ${location.pathname === item.link ? 'text-primary' : ''}`}
                  >
                    {item.icon}
                    {menuOpen ? (
                      <h3 className="ml-3 text-nowrap">{item.title}</h3>
                    ) : null}
                  </li>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="left">
                <p>{item.title}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ))}
      </ul>
      <Button
        onClick={() => setMenuOpen(!menuOpen)}
        variant={'ghost'}
        className="flex justify-end py-7"
      >
        {menuOpen ? <ArrowLeft /> : <ArrowRight />}
      </Button>
    </div>
  );
};
export default Sidebar;
