import { TCheckModalState } from '@/lib/utils';
import api from '@/services/api';
import { TBlockServiceSchedule } from '@/services/services';
import { useMutation } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function usePostClousureCheck(
  onOpenCheck: Dispatch<
    SetStateAction<TCheckModalState>
  >
) {
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['reservationsAffected'],
    mutationFn: ({
      closures,
      serviceId
    }: {
      closures: TBlockServiceSchedule;
      serviceId?: string;
    }) => {
      const body = serviceId
        ? { closures, services: [serviceId] }
        : { closures };
      return api.services.postClosureCheckReservation(body);
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: (response, dataSent) => {
      onOpenCheck({ data: dataSent, state: true });
    },
    retry: false
  });
}

export default usePostClousureCheck;
