import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';
// if we don't pass service id the endpoint returns school availability
const useGetServiceActiveDates = (serviceId?: string) => {
  return useQuery({
    queryKey: ['services-active-dates', serviceId],
    queryFn: () => api.services.getServiceActiveDates(serviceId)
  });
};

export default useGetServiceActiveDates;
