import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip';
type TGenericCard = {
  title: string;
  content: string | number;
  Icon?: React.ElementType;
  description?: string;
  truncate?: boolean;
};
const GenericCard = ({
  title,
  Icon,
  content,
  description,
  truncate = false
}: TGenericCard) => {
  return (
    <Card
      className={`flex flex-col justify-between ${Icon ? '' : 'items-center'}`}
    >
      <CardHeader className="flex flex-row items-center justify-between pb-2 space-y-0 min-h-20">
        <CardTitle
          className={`text-sm font-medium ${Icon ? '' : 'text-center'}`}
        >
          {title}
        </CardTitle>
        {!!Icon && <Icon className="opacity-30" />}
      </CardHeader>
      <CardContent
        className={`flex-grow flex flex-col ${Icon ? '' : 'items-center'}`}
      >
        <p className="text-2xl font-bold">{content}</p>
        {!!description && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <p
                  className={`text-xs text-muted-foreground ${truncate ? 'truncate' : ''}`}
                >
                  {description}
                </p>
              </TooltipTrigger>
              <TooltipContent side="bottom">
                <p>{description}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </CardContent>
    </Card>
  );
};

export default GenericCard;
