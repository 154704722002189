import api from '@/services/api';
import { TNewPayment } from '@/services/payments';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

const usePatchPayments = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['patchPayments'],
    mutationFn: ({
      paymentId,
      data
    }: {
      paymentId: string;
      data: TNewPayment;
    }) => {
      return api.payments.patchPayment(paymentId, data);
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'sale' ||
          query.queryKey[0] === 'sales' ||
          query.queryKey[0] === 'payments'
      });

      toast.success(t('toast.paymentUpdatedSuccess'));
    },
    retry: false
  });
};

export default usePatchPayments;
