import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetSale= (saleId: string) => {
  return useQuery({
    queryKey: ['sale', saleId],
    queryFn: () => api.sales.getSaleById(saleId),
  });
};

export default useGetSale;
