import { enUS, es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

export const useCurrentLanguage = () => {
  const { i18n } = useTranslation();
  const getPrimaryLanguage = (locale: string) => {
    if (locale.includes('-')) {
      return locale.split('-')[0];
    } else {
      return locale;
    }
  };
  const locale = getPrimaryLanguage(i18n.language);
  return locale === 'es' ? es : enUS;
};
