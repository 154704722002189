import { ReservationsPendingTable } from '@/components/common/tables/reservations-pending-table/ReservationsPendingTable';
import { ReservationsTodayTable } from '@/components/common/tables/reservations-today-table/ReservationsTodayTable';
import QuickActionsStats from '@/components/section/quick-actions/QuickActionsStats';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useRootStore } from '@/stores/root-store';
import { TActiveTab } from '@/stores/slices/dashboard/dashboard.types';
import { useTranslation } from 'react-i18next';

const QuickActionsPage = () => {
  const { t } = useTranslation();
  const activeTab = useRootStore((store) => store.activeTab);
  const setActiveTab = useRootStore((store) => store.setActiveTab);

  return (
    <div className="w-full">
      <QuickActionsStats />
      <Tabs
        onValueChange={(value) => setActiveTab(value as TActiveTab)}
        defaultValue={activeTab}
      >
        <TabsList>
          <TabsTrigger value="today"> {t('common.today')}</TabsTrigger>
          <TabsTrigger value="pending">
            {t('common.status.pending')}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="today">
          <ReservationsTodayTable />
        </TabsContent>
        <TabsContent value="pending">
          <ReservationsPendingTable />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default QuickActionsPage;
