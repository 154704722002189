import { DESCRIPTION_MAX_LENGTH } from '@/utils/utils';
import { z } from 'zod';

const optionSchema = z.object({
  label: z.string(),
  value: z.string(),
  disable: z.boolean().optional()
});

const inventoryCategorySchema = () => {
  return z.object({
    name: z
      .string({
        required_error:
          'Es necesario un nombre para la creación de una categoría de inventario.'
      })
      .min(4, 'El nombre debe contener al menos 4 caracteres.')
      .max(100, 'El nombre no puede contener más de 100 caracteres.'),
    description: z
      .string()
      .max(
        DESCRIPTION_MAX_LENGTH.medium,
        `La descripción no puede contener más de ${DESCRIPTION_MAX_LENGTH.medium} caracteres.`
      )
      .optional(),
    sports: z.array(optionSchema).optional()
  });
};

export default inventoryCategorySchema;
