import { InventoryCategoriesTable } from '@/components/common/tables/inventory-categories-table/InventoryCategoriesTable';

const InventoryCategoriesPage = () => {
  return (
    <div className="w-full">
      <InventoryCategoriesTable />
    </div>
  );
};

export default InventoryCategoriesPage;
