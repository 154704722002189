import api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

const usePostEquipmentUnassign = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['postEquipmentUnassign'],
    mutationFn: ({ data }: { data: any }) => {
      return api.equipments.postEquipmentUnassign(data);
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'reservation' ||
          query.queryKey[0] === 'reservations'
      });
    },
    retry: false
  });
};

export default usePostEquipmentUnassign;
