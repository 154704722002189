import { DESCRIPTION_MAX_LENGTH } from '@/utils/utils';
import { z } from 'zod';

const inventorySchema = () => {
  return z.object({
    name: z
      .string({
        required_error: 'Es necesario un nombre para la creación de inventario.'
      })
      .min(4, 'El nombre debe contener al menos 4 caracteres.')
      .max(100, 'El nombre no puede contener más de 100 caracteres.'),
    code: z
      .string({
        required_error: 'Es necesario un codigo para la creación de inventario.'
      })
      .min(1, 'El codigo debe contener al menos 1 caracter.'),
    description: z
      .string()
      .max(
        DESCRIPTION_MAX_LENGTH.medium,
        `La descripción no puede contener más de ${DESCRIPTION_MAX_LENGTH.medium} caracteres.`
      )
      .optional(),
    equipmentType: z.string({
      required_error:
        'Es necesario asignar una categoria para la creación de inventario.'
    })
  });
};

export default inventorySchema;
