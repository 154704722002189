import api from '@/services/api';
import { TPostPatchEquipmentType } from '@/services/equipments/equipments';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const usePatchEquipmentTypes = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: ['patchEquipmentTypes'],
    mutationFn: (data: {
      payload: Partial<TPostPatchEquipmentType>;
      equipmentTypeId: string;
    }) => {
      return api.equipments.patchEquipmentType(
        data.payload,
        data.equipmentTypeId
      );
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['equipmentTypes'] });
      toast.success(t('toast.categoryUpdated'));
      navigate(-1);
    },
    retry: false
  });
};

export default usePatchEquipmentTypes;
