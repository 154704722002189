import { InventoryTable } from '@/components/common/tables/inventory-table/InventoryTable';

const InventoryPage = () => {
  return (
    <div className="w-full">
      <InventoryTable />
    </div>
  );
};

export default InventoryPage;
