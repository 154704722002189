import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import NoData from '@/components/common/no-data/NoData';
import { ServicesSoldTableVP } from '@/components/common/tables/services-sold-table-vp/ServicesSoldTable';
import { ServicesSoldTable } from '@/components/common/tables/services-sold-table/ServicesSoldTable';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';

const ServicesSold = () => {
  const organization = useGetOrganization();

  if (!organization.data) return <NoData />;

  if (organization.isError) {
    return <ErrorMessage />;
  }

  if (organization.data.code === 'viento-en-popa') {
    return <ServicesSoldTableVP />;
  }

  return (
    <div className="w-full">
      <ServicesSoldTable />
    </div>
  );
};

export default ServicesSold;
