import { TabsList, TabsTrigger } from '@/components/ui/tabs';
import type { TCategoryServices } from '@/services/services';

const BasicTabsList: React.FC<{ services: TCategoryServices[] }> = ({
  services
}) => {
  return (
    <div className="w-full relative h-16 ">
      <TabsList className="flex absolute h-full ">
        {services.map((category) => (
          <TabsTrigger
            key={category.sport.id}
            value={category.sport.id}
            className="h-full min-w-40"
          >
            {category.sport.name}
          </TabsTrigger>
        ))}
      </TabsList>
    </div>
  );
};

export default BasicTabsList;
