import { Accordion } from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import formParticipantDataSchema from '@/schemas/formParticipantData';
import { useRootStore } from '@/stores/root-store';
import type { StepService } from '@/stores/slices/step-service/step-service.types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type {
  FormParticipantDataAccordionProps,
  TForm
} from './FormParticipantDataAccordion.types';
import AccordionFormItem from './components/AccordionFormItem';
import ParticipantForm from './components/ParticipantForm';
import UserForm from './components/UserForm';

const FormParticipantDataAccordion: React.FC<
  FormParticipantDataAccordionProps
> = ({ user, emailUser, setEmailUser, isPending, onSubmit }) => {
  const { t } = useTranslation();

  //store
  const removeStep = useRootStore((store) => store.removeStep);
  const stepServicesData: StepService | null = useRootStore(
    (store) => store.stepServicesData
  );

  const currentStep = useRootStore((store) => store.currentStep);

  const defaultValues = {
    participants: [
      ...Array(
        stepServicesData?.participants ? stepServicesData?.participants - 1 : 1
      )
    ].map(() => ({
      firstName: '',
      lastName: '',
      birthdate: undefined,
      height: 0,
      weight: 0,
      id: ''
    })),
    user: {
      email: user?.email ? user.email : emailUser ? emailUser : '',
      firstName: user?.firstName ? user.firstName : '',
      lastName: user?.lastName ? user.lastName : '',
      birthdate: user?.birthdate ? new Date(user.birthdate) : undefined,
      height: user?.height ? user.height : 0,
      weight: user?.weight ? user.weight : 0,
      phone: user?.phone ? user.phone : '',
      identification: {
        number: user?.identification?.number
          ? user?.identification?.number
          : '',
        type: user?.identification?.type ? user?.identification?.type : ''
      },
      userIsParticipant: true
    },
    isAccept: false,
    declarationLegalAge: false
  };
  const minAge = stepServicesData?.service?.minAge;
  const maxAge = stepServicesData?.service?.maxAge;
  const form = useForm<TForm>({
    resolver: zodResolver(formParticipantDataSchema({ minAge, maxAge })),
    // TODO: Encontrar error de TS o RHF por que algunos valores no le agrega el tpye indicado, en este caso los valores que TS: birthdate, height, weight
    mode: 'onChange',
    defaultValues
  });

  const [accordionValue, setAccordionValue] = useState(() => {
    if (user && stepServicesData?.participants) {
      form.trigger('user');
      return 'participant.0';
    } else {
      return 'participant.user';
    }
  });

  useEffect(() => {
    // if the value arrives from the user, validate them
    const initializeAccordionValue = async () => {
      if (user && stepServicesData?.participants) {
        const isValid = await form.trigger('user');
        if (!isValid) setAccordionValue('participant.user');
      }
    };
    initializeAccordionValue();
  }, []);

  const { fields } = useFieldArray({
    control: form.control,
    name: 'participants'
  });

  const userIsParticipant = form.getValues().user.userIsParticipant;

  const onBack = () => {
    removeStep(currentStep);
  };
  const userEmail = form.watch('user.email');

  const handleBlur = async () => {
    const isEmail = await form.trigger('user.email');
    if (userEmail !== '' && isEmail) {
      setEmailUser(userEmail);
    }
  };
  const formError = Boolean(Object.keys(form.formState.errors).length);

  return (
    <Form {...form}>
      <h2 className="font-bold text-primary">
        {stepServicesData?.service?.name}
      </h2>
      <h2 className="font-bold mb-6">
        {t('newBooking.formParticipantData.title')}
      </h2>

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        {/* User is participant Checkbox */}
        <FormField
          control={form.control}
          name={'user.userIsParticipant'}
          render={({ field }) => (
            <FormItem>
              <div className="space-y-3 p-2">
                <FormControl>
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="user.userIsParticipant"
                      checked={!field.value}
                      onCheckedChange={() => {
                        // remove a participant when the user is participant
                        if (stepServicesData?.participants) {
                          const currentValue = form.getValues().participants;
                          if (!field.value) {
                            form.setValue(
                              'participants',
                              currentValue.slice(1)
                            );
                          } else if (currentValue?.length === 0) {
                            form.setValue('participants', [
                              {
                                firstName: '',
                                lastName: '',
                                birthdate: new Date(),
                                height: 0,
                                weight: 0,
                                id: ''
                              }
                            ]);
                          } else {
                            form.setValue('participants', [
                              ...currentValue,
                              {
                                firstName: '',
                                lastName: '',
                                birthdate: new Date(),
                                height: 0,
                                weight: 0,
                                id: ''
                              }
                            ]);
                          }
                        }

                        form.clearErrors();
                        field.onChange(!field.value);
                      }}
                    />
                    <FormLabel
                      htmlFor="user.userIsParticipant"
                      className="ml-2 cursor-pointer"
                    >
                      {t(
                        'newBooking.formParticipantData.userIsParticipantLabel'
                      )}
                    </FormLabel>
                  </div>
                </FormControl>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        {/* mail input */}
        <FormField
          control={form.control}
          name="user.email"
          render={({ field }) => {
            return (
              <FormItem className="p-2">
                <FormLabel>{t('form.email')}</FormLabel>
                <FormControl>
                  <Input
                    placeholder="example@email.com"
                    type="email"
                    {...field}
                    onBlur={handleBlur}
                  />
                </FormControl>
                <FormDescription>
                  {t('newBooking.formParticipantData.mail_description')}
                </FormDescription>
                <FormMessage />
              </FormItem>
            );
          }}
        />

        <Accordion
          type="single"
          collapsible
          className="w-full space-y-6 md:container"
          onValueChange={setAccordionValue}
          value={accordionValue}
        >
          {/* User Form */}
          <AccordionFormItem
            accordionValue="participant.user"
            form={form}
            formField="user"
            setAccordionValue={setAccordionValue}
            userIsParticipant={userIsParticipant}
          >
            {(onSave) => (
              <div>
                <UserForm
                  form={form}
                  userIsParticipant={userIsParticipant}
                  user={user}
                  onSave={onSave}
                />
              </div>
            )}
          </AccordionFormItem>

          {/* Participants Form */}
          {fields.map((field, index) => {
            return (
              <AccordionFormItem
                index={index}
                key={`participant.${index}`}
                accordionValue={`participant.${index}`}
                participantNumber={userIsParticipant ? index + 2 : index + 1}
                userIsParticipant={userIsParticipant}
                form={form}
                formField={'participants'}
                setAccordionValue={setAccordionValue}
              >
                {(onSave) => (
                  <ParticipantForm
                    form={form}
                    user={user}
                    index={index}
                    onSave={onSave}
                  />
                )}
              </AccordionFormItem>
            );
          })}
        </Accordion>

        {formError && (
          <p className="text-sm font-medium text-destructive">
            {t('newBooking.formParticipantData.errorMessage')}
          </p>
        )}
        <div className="flex flex-col gap-2">
          <Button type="submit" className="w-full" disabled={isPending}>
            {isPending ? (
              <Loader className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              t('newBooking.continue')
            )}
          </Button>
          <Button
            onClick={() => {
              onBack();
            }}
            type="button"
            variant="outline"
            className="w-full"
          >
            {t('newBooking.goBack')}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default FormParticipantDataAccordion;
