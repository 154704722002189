import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import formChooseServiceSchema from '@/schemas/form-choose-service';
import { useRootStore } from '@/stores/root-store';
import { currencyFormat } from '@/utils/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { FormChooseServicesProps } from './formchooseServices.types';
import { useRef } from 'react';
import { TServices } from '@/services/services';

type TForm = z.input<ReturnType<typeof formChooseServiceSchema>>;

const FormChooseService: React.FC<FormChooseServicesProps> = ({
  services,
  organization
}) => {
  // i18n text
  const { t } = useTranslation();

  //store
  const addStep = useRootStore((store) => store.addStep);
  const currentStep = useRootStore((store) => store.currentStep);
  const loadStepServicesData = useRootStore(
    (store) => store.loadStepServicesData
  );
  const stepServicesData = useRootStore((store) => store.stepServicesData);

  const SelectedServiceRef = useRef<null | TServices>(null);
  const minParticipants = SelectedServiceRef.current?.minConsumers || 1;
  const maxParticipants = SelectedServiceRef.current?.maxConsumers || 4;

  // Initializing useForm hook with the schema and default values
  const form = useForm<TForm>({
    resolver: zodResolver(
      formChooseServiceSchema({ minParticipants, maxParticipants })
    ),
    defaultValues: {
      service: stepServicesData?.service,
      participants: stepServicesData?.participants
    }
  });

  // get price and participant from form with listeners
  const price = useWatch({ control: form.control, name: 'service' })?.price;
  const participants = useWatch({
    control: form.control,
    name: 'participants'
  });

  const selectedService = form.getValues().service;
  const total = price * Number(participants);
  const recervationPct = form.getValues()?.service?.reservationPct;
  const deposit = total * (recervationPct / 100);

  async function onSubmit(data: TForm) {
    const service = data.service;
    const participants = data.participants;
    loadStepServicesData({
      service,
      participants
    });

    addStep(currentStep);
  }

  return (
    <Form {...form}>
      <h2 className="font-bold mb-6">{t('newBooking.stepServiceTitle')}</h2>
      <form onSubmit={form.handleSubmit(onSubmit)} className={`space-y-8`}>
        <FormField
          control={form.control}
          name="service"
          render={({ field }) => {
            return (
              <FormItem>
                <Select
                  onValueChange={(e) => {
                    field.onChange(services.find((act: any) => act.id === e));
                  }}
                >
                  <FormControl>
                    <SelectTrigger className="w-full ">
                      <div>
                        {field.value ? (
                          <p>{field.value.name}</p>
                        ) : (
                          t('tables.common.activity')
                        )}
                      </div>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {services?.map((service: any, i: number) => (
                      <SelectItem value={service.id} key={`${service.id}${i}`}>
                        <div className="flex justify-between">
                          <p>{service.name}</p>
                          <p>
                            {currencyFormat(
                              service.price,
                              organization.currency
                            )}
                          </p>
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="participants"
          render={({ field }) => {
            return (
              <FormItem>
                <Select
                  onValueChange={(value) => field.onChange(value)}
                  defaultValue={undefined}
                  value={field.value ? String(field.value) : undefined}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t('newBooking.participant')} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {selectedService
                      ? [...Array(selectedService?.maxConsumers)].map(
                          (_, i) => {
                            const participant = i + 1;
                            return participant === 1 ? (
                              <SelectItem
                                value={String(participant)}
                                key={participant}
                              >
                                {t(
                                  'newBooking.participants.singularParticipant',
                                  { participant: participant }
                                )}
                              </SelectItem>
                            ) : (
                              <SelectItem
                                value={String(participant)}
                                key={participant}
                              >
                                {t(
                                  'newBooking.participants.pluralParticipant',
                                  { participant: participant }
                                )}
                              </SelectItem>
                            );
                          }
                        )
                      : undefined}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        {total ? (
          <div className="w-full bg-accent rounded p-3 space-y-2">
            <div className="flex justify-between">
              <p>{t('newBooking.total')}</p>
              <p>
                {currencyFormat(
                  form.control._formValues?.service?.price *
                    Number(form.control._formValues?.participants),
                  organization.currency
                )}
              </p>
            </div>
            <div className="flex justify-between">
              <p>{t('newBooking.deposit')}</p>
              <p>{currencyFormat(deposit, organization.currency)}</p>
            </div>
          </div>
        ) : null}
        <Button type="submit" className="w-full">
          {t('newBooking.continue')}
        </Button>
      </form>
    </Form>
  );
};

export default FormChooseService;
