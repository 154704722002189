import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import serviceDivisionSchema from '@/schemas/serviceDivision';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ServiceDivisionFormProps, TForm } from './ServiceDivisionForm.types';

const ServiceDivisionForm: React.FC<ServiceDivisionFormProps> = ({
  defaultValues,
  onSubmit,
  isPending,
  onDelete,
  isPendingDelete
}) => {
  const { t } = useTranslation();

  const form = useForm<TForm>({
    resolver: zodResolver(serviceDivisionSchema),
    defaultValues: defaultValues,
    mode: 'onChange'
  });

  const hasChanged = !form.formState.isDirty;

  const renderButtonContent = (isEdit: boolean, isPending: boolean) => {
    if (isPending) {
      return <Loader className="w-4 h-4 animate-spin" />;
    }
    if (isEdit) {
      return t('form.update');
    }
    return t('form.create');
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-8 rounded-md"
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.name')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('form.description')}
                <span className="ml-2 text-sm font-light text-gray-500">
                  {t('form.optional')}
                </span>
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="quantity"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.quantity')}</FormLabel>
              <FormDescription>{t('form.quantityPlaceholder')}</FormDescription>
              <FormControl>
                <Input type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="duration"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.duration')}</FormLabel>
              <FormDescription>{t('form.durationPlaceholder')}</FormDescription>
              <FormControl>
                <Input type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-end gap-2">
          {onDelete && (
            <Button
              type="button"
              disabled={isPendingDelete}
              variant={'destructive'}
              onClick={onDelete}
            >
              {isPendingDelete ? (
                <Loader className="w-4 h-4 animate-spin" />
              ) : (
                t('form.delete')
              )}
            </Button>
          )}
          <Button disabled={isPending || hasChanged}>
            {renderButtonContent(!!defaultValues, isPending)}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default ServiceDivisionForm;
