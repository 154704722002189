import ReprogrameDateForm from '@/components/forms/form-reprograme-date/ReprogrameDateForm';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import useServicesAvailability from '@/hooks/queries/services-api/useServicesAvailability';
import useCalendarPaginateNavigation from '@/hooks/useCalendarPaginateNavigation';
import { getTimeZone } from '@/utils/dates';
import { t } from 'i18next';
import { Loader } from 'lucide-react';
import ErrorMessage from '../../error-message/ErrorMessage';
import NoData from '../../no-data/NoData';
import { ReprogrameDateModalT } from './reprograme-date-modal.types';

const ReprogrameDateModal: React.FC<ReprogrameDateModalT> = ({
  open,
  onOpenChange,
  onSave,
  serviceId,
  participants,
  startTime
}) => {
  const organization = useGetOrganization();

  const currentReservationDate =
    startTime && organization.data?.tz
      ? getTimeZone(startTime, organization.data.tz)
      : undefined;
  const calendarPaginateNavigation = useCalendarPaginateNavigation(
    currentReservationDate
  );
  const { month, year } = calendarPaginateNavigation;

  const {
    data: availableDates,
    isLoading,
    isError
  } = useServicesAvailability({
    serviceId,
    participants,
    month,
    year
  });

  const getModalContent = () => {
    if (isError || organization.isError) {
      return <ErrorMessage />;
    }
    if (isLoading || organization.isLoading) {
      return (
        <div className="flex items-center justify-center">
          <Loader className="animate-spin" />
        </div>
      );
    }
    if (!availableDates || !organization.data) {
      return <NoData />;
    }
    return (
      <ReprogrameDateForm
        availableDates={availableDates}
        onSave={onSave}
        calendarPaginateNavigation={calendarPaginateNavigation}
        startTime={startTime}
        organization={organization.data}
      />
    );
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('common.schedule')}</DialogTitle>
        </DialogHeader>
        {getModalContent()}
      </DialogContent>
    </Dialog>
  );
};
export default ReprogrameDateModal;
