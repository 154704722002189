import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetUser = () => {
  return useQuery({
    queryKey: ['user'],
    queryFn: () => api.user.getUser()
  });
};

export default useGetUser;
