import api from '@/services/api';
import { SalePostPayload } from '@/services/sales';
import { useRootStore } from '@/stores/root-store';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function useCreateSale() {
  const queryClient = useQueryClient();
  const addStep = useRootStore((store) => store.addStep);
  const currentStep = useRootStore((store) => store.currentStep);
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['createSale'],
    mutationFn: (data: SalePostPayload) => api.sales.newBooking(data),
    onSuccess: (data) => {
      queryClient.setQueryData(['createSale'], () => data);
      addStep(currentStep);
    },
    onError: (error) => {
      const errorMessage = error?.response?.data.message;
      toast.error(t('toast.error', { errorMessage }), { duration: 10000 });
    }
  });
}
export default useCreateSale;
