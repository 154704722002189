import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetReservationsPendingActions = () => {
  return useQuery({
    queryKey: ['reservation', 'pendingActions'],
    queryFn: () => api.reservations.getReservationsPendingActions()
  });
};

export default useGetReservationsPendingActions;
