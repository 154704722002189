import api from '@/services/api';
import { useAuth0 } from '@auth0/auth0-react';
import { skipToken, useQuery } from '@tanstack/react-query';

const useGetOrganization = () => {
  const { user } = useAuth0();
  const organizationName = user?.user_metadata?.organizationCode;
  return useQuery({
    queryKey: ['Getorganization', organizationName],
    queryFn:
      organizationName === undefined
        ? skipToken
        : () => api.organization.getOrganization(organizationName),

    staleTime: Infinity,
    gcTime: Infinity
  });
};

export default useGetOrganization;
