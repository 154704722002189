import api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

const usePostFile = (consumerId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['upload-image'],
    mutationFn: async ({ file }: { file: File }) => {
      // get the presigned URL to upload the file to S3
      const presignedUrl = await api.file.getPresignedUrl({
        consumer: consumerId,
        contentType: file.type
      });

      // upload the file to S3
      if (presignedUrl?.url) {
        const response = await fetch(presignedUrl?.url, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type
          },
          body: file
        });

        if (!response.ok) {
          throw new Error(`Upload failed: ${response.statusText}`);
        }
        return { id: presignedUrl.id, url: response };
      } else {
        throw new Error('Presigned URL not generated');
      }
    },
    onError: () => {
      toast.error(t('toast.errorUploadingImage'), { duration: 5000 });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['consumer-image', consumerId]
      });
      toast.success(t('toast.uploadingImageSuccess'));
    },
    retry: false
  });
};

export default usePostFile;
