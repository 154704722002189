import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/components/ui/accordion';
import { AlertTriangle, CheckCircle2 } from 'lucide-react';
import { type SetStateAction, useEffect, useRef, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const AccordionFormItem: React.FC<
  | {
      accordionValue: string;
      form: UseFormReturn<any, any, undefined>;
      formField: 'user';
      index?: number;
      participantNumber?: number;
      setAccordionValue: (value: SetStateAction<string>) => void;
      children: (onSave: () => Promise<void>) => React.ReactElement;
      userIsParticipant: boolean;
    }
  | {
      accordionValue: string;
      form: UseFormReturn<any, any, undefined>;
      formField: 'participants';
      index: number;
      participantNumber?: number;
      setAccordionValue: (value: SetStateAction<string>) => void;
      children: (onSave: () => Promise<void>) => React.ReactElement;
      userIsParticipant: boolean;
    }
> = ({
  accordionValue,
  form,
  formField,
  index,
  setAccordionValue,
  participantNumber,
  userIsParticipant,
  children
}) => {
  const headerRef = useRef<HTMLHeadingElement>(null);
  const { t } = useTranslation();
  const isParticipant = formField === 'participants';
  const [isError, setIsError] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const formValue = isParticipant
    ? form.getValues()[formField][index]
    : form.getValues()[formField];
  const { firstName, lastName } = formValue;

  const field = isParticipant ? `${formField}.${index}` : formField;
  const isInvalid = form.getFieldState(field).invalid;
  const isValidated = form.getFieldState(field).isValidating;
  const isReady =
    (!isInvalid && isValidated && !isError) || (!isInvalid && isValid);
  useEffect(() => {
    // Validate field when the checkbox 'userIsParticipant' has been change
    const validateField = async () => {
      const { errors } = await form.control._executeSchema([]);
      if (isParticipant) {
        const fieldErrors = (errors as any)[formField][index];
        const isValid = fieldErrors ? !!Object.keys(fieldErrors).length : false;
        setIsValid(!isValid);
        setIsError(isValid);
      } else {
        const fieldErrors = errors[formField];
        const isValid = fieldErrors ? !!Object.keys(fieldErrors).length : false;
        setIsValid(!isValid);
        setIsError(isValid);
      }
    };
    validateField();
  }, [form.getValues().user.userIsParticipant]);

  const onSave = async () => {
    const isValid = await form.trigger(field);
    if (isValid) {
      // scroll to the header
      if (headerRef?.current) {
        headerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      setIsError(false);
      setIsValid(true);
      setAccordionValue('');
    } else {
      setIsError(true);
    }
  };

  const renderHeader = () => {
    const header = () => {
      if (participantNumber) {
        return `${t('newBooking.participants.participant')} ${participantNumber}`;
      }

      if (userIsParticipant) {
        return `${t('newBooking.formParticipantData.yourData')} (${t(
          'newBooking.participants.participant'
        )} 1)`;
      }

      return t('newBooking.formParticipantData.yourData');
    };

    const name = (
      <span className="text-sm text-muted-foreground">{`${firstName} ${lastName}`}</span>
    );

    const description = !isParticipant ? (
      <span className="text-xs">{t('newBooking.userDescription')}</span>
    ) : null;

    if (isReady) {
      return (
        <div className="pb-4">
          <AccordionTrigger
            className="pb-0 text-green-600"
            rightcomponent={<CheckCircle2 className=" shrink-0 h-4 w-4" />}
          >
            {header()}
          </AccordionTrigger>
          <div className="flex flex-col gap-1">
            {description}
            {name}
          </div>
        </div>
      );
    }

    if (isInvalid) {
      return (
        <div className="pb-4">
          <AccordionTrigger
            className="pb-0 text-red-600"
            rightcomponent={
              <AlertTriangle className="text-red-600 shrink-0 h-4 w-4" />
            }
          >
            {header()}
          </AccordionTrigger>
          <div className="flex flex-col gap-1">
            {description}
            {name}
          </div>
        </div>
      );
    }

    return (
      <div className="pb-4 flex flex-col">
        <AccordionTrigger className="pb-0">{header()}</AccordionTrigger>
        {description}
        {name}
      </div>
    );
  };

  return (
    <AccordionItem value={accordionValue}>
      <div ref={headerRef}>{renderHeader()}</div>
      <AccordionContent>{children(onSave)}</AccordionContent>
    </AccordionItem>
  );
};

export default AccordionFormItem;
