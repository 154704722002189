import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import { format, parse } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveMonthsCardProps } from './ActiveMonthsCard.types';

const ActiveMonthsCard: React.FC<ActiveMonthsCardProps> = ({
  organization
}) => {
  const { i18n } = useTranslation();
  const lng = i18n.language === 'es' ? es : enUS;
  const months = JSON.parse(organization.activeMonths);

  const formatRange = (ranges: number[][]) => {
    return ranges.map((range, i) => {
      const [start, end] = range;
      return (
        <p key={`${start - end}-${i}}`}>
          {start} - {end}
        </p>
      );
    });
  };

  const monthText = (month: string) => {
    const date = parse(month, 'LLLL', new Date());
    const monthName = format(date, 'LLLL', { locale: lng });
    return monthName;
  };

  return (
    <Card className="w-[300px]">
      <CardHeader>
        <CardTitle>{t('activeMonths.title')}</CardTitle>
        <CardDescription>
          {t('activeMonths.description', { name: organization.name })}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          {Object.keys(months).map((month) => {
            const range = months[month];
            const isActive = range.length;
            if (isActive) {
              return (
                <div key={month}>
                  <p className="font-bold">{monthText(month)}</p>
                  <div className="flex gap-5">{formatRange(range)}</div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </CardContent>
    </Card>
  );
};

export default ActiveMonthsCard;
