import { Progress } from '@/components/ui/progress';
import { useRootStore } from '@/stores/root-store';

const Stepper: React.FC<{ totalSteps: number }> = ({ totalSteps }) => {
  const currentStep = useRootStore((state) => state.currentStep);

  const stepPercentage = (currentStep / totalSteps) * 100;

  return (
    <div>
      <div className="flex flex-row align-middle justify-around text-primary font-bold">
        {Array.from({ length: totalSteps }, (_, i) => (
          <h3 key={i + 1}>{i + 1}</h3>
        ))}
      </div>

      <Progress value={stepPercentage} />
    </div>
  );
};

export default Stepper;
