import IdentificationTypeSelect from '@/components/common/input/identification-types-select/IdentificationTypeSelect';
import CalendarModal from '@/components/common/modals/calendar-modal/CalendarModal';
import { Button } from '@/components/ui/button';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  maxHeightInputValidation,
  maxWeightInputValidation
} from '@/lib/utils';
import { TUsers } from '@/services/user';
import { useRootStore } from '@/stores/root-store';
import { StepService } from '@/stores/slices/step-service/step-service.types';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const UserForm: React.FC<{
  form: UseFormReturn<any, any, undefined>;
  userIsParticipant: boolean;
  user: TUsers | undefined;
  onSave: () => Promise<void>;
}> = ({ form, userIsParticipant, user, onSave }) => {
  const stepServicesData: StepService | null = useRootStore(
    (store) => store.stepServicesData
  );
  const { t } = useTranslation();

  const renderConditionalInputs = () =>
    userIsParticipant ? (
      <>
        <FormField
          control={form.control}
          name={`user.birthdate`}
          render={({ field }) => {
            const disabled = !!user?.birthdate;
            return (
              <FormItem className="flex flex-col ">
                <FormLabel>{t('form.birthdate')}</FormLabel>
                <CalendarModal
                  value={field.value}
                  placeholder={t('form.birthdate')}
                  onAccept={(value) => {
                    field.onChange(value);
                  }}
                  disabledInputLabel={disabled}
                  initialFocus
                  dropDownPicker
                />
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name={`user.weight`}
          render={({ field }) => {
            const disabled = !!user?.weight;
            return (
              <FormItem>
                <FormLabel>{t('form.weight')}</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    {...field}
                    readOnly={disabled}
                    onChange={(e) => {
                      if (maxWeightInputValidation(Number(e.target.value))) {
                        field.onChange(e);
                      }
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name={`user.height`}
          render={({ field }) => {
            const disabled = !!user?.height;
            return (
              <FormItem>
                <FormLabel>{t('form.height')}</FormLabel>
                <FormControl>
                  <Input
                    step="1"
                    type="number"
                    {...field}
                    readOnly={disabled}
                    onChange={(e) => {
                      if (maxHeightInputValidation(Number(e.target.value))) {
                        field.onChange(e);
                      }
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
      </>
    ) : (
      <>
        <FormField
          control={form.control}
          name="user.identification.type"
          render={({ field }) => {
            const disabled = !!user?.identification?.type;
            return (
              <FormItem>
                <IdentificationTypeSelect
                  disabled={disabled}
                  defaultValue={field?.value}
                  onValueChange={(value) => {
                    field.onChange(value);
                  }}
                />
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="user.identification.number"
          render={({ field }) => {
            const disabled = !!user?.identification?.number;
            return (
              <FormItem>
                <FormLabel>
                  {t('identificationTypes.identificationNumber')}
                </FormLabel>
                <FormControl>
                  <Input
                    readOnly={disabled}
                    placeholder={t('identificationTypes.identificationNumber')}
                    {...field}
                    value={field?.value || ''}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
      </>
    );

  return (
    <div
      className="border border-primary p-6 rounded-md flex flex-col gap-8"
      key={`user-participant`}
    >
      <FormField
        control={form.control}
        name={`user.firstName`}
        render={({ field }) => {
          const disabled = !!user?.firstName;
          return (
            <FormItem>
              <FormLabel>{t('form.firstName')}</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  readOnly={disabled}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={`user.lastName`}
        render={({ field }) => {
          const disabled = !!user?.firstName;
          return (
            <FormItem>
              <FormLabel>{t('form.lastName')}</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  readOnly={disabled}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        key={'user.phone'}
        control={form.control}
        name={`user.phone`}
        render={({ field }) => {
          const disabled = !!user?.phone;
          return (
            <FormItem>
              <FormLabel>{t('common.phone')}</FormLabel>
              <FormControl>
                <Input
                  type="tel"
                  {...field}
                  readOnly={disabled}
                  placeholder={t('common.phone')}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      {renderConditionalInputs()}
      <Button type="button" onClick={onSave}>
        {t('common.validate')}
      </Button>
    </div>
  );
};

export default UserForm;
