import { format, formatDistanceStrict } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

const useFormattedDate = () => {
  const { i18n } = useTranslation();
  const lng = i18n.language === 'es' ? es : enUS;

  const formattedDate = ({
    date,
    dateFormat = 'dateTime'
  }: {
    date: string | number | Date;
    dateFormat?: 'date' | 'dateTime' | 'time';
  }) => {
    if (
      date === undefined ||
      date === null ||
      (typeof date === 'number' && isNaN(date))
    ) {
      return 'undefined';
    }
    let formatterDateString = 'PP, H:mm';
    if (dateFormat === 'date') {
      formatterDateString = 'PP';
    }
    if (dateFormat === 'time') {
      formatterDateString = 'H:mm';
    }
    return format(date, formatterDateString, { locale: lng });
  };

  const formattedDistance = ({
    start,
    end
  }: {
    start: string | number | Date;
    end: string | number | Date;
  }) => {
    if (
      start === undefined ||
      start === null ||
      (typeof start === 'number' && isNaN(start)) ||
      end === undefined ||
      end === null ||
      (typeof end === 'number' && isNaN(end))
    ) {
      return 'undefined';
    }
    return formatDistanceStrict(start, end, { locale: lng });
  };

  return {
    formattedDate,
    formattedDistance
  };
};

export default useFormattedDate;
