import { Button } from '@/components/ui/button';
import { TConsumer, TGetConsumers } from '@/services/consumer';
import { TIdentificationType } from '@/services/identification';
import { exportConsumerCSV } from '@/utils/consumer';
import { Table } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { DownloadIcon, Loader, XIcon } from 'lucide-react';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableColumnsToggle from '../../table-columns-toggle/TableColumnsToggle';
import TableSearchInput from '../common/table-search-input/TableSearchInput';
import useGetConsumer from '@/hooks/queries/consumer-api/useGetConsumers';

type TableToolBarProps = Readonly<{
  table: Table<TConsumer>;
  filtering: string;
  setFiltering: (value: string) => void;
  isFetching: boolean;
  allIdentificationTypes: TIdentificationType[] | undefined;
  disabled: boolean;
}>;

export default function TableToolBar({
  table,
  filtering,
  setFiltering,
  isFetching,
  allIdentificationTypes,
  disabled
}: TableToolBarProps) {
  const { t } = useTranslation();
  const [search, setSearch] = useState(filtering || '');

  const debounceFn = useCallback(
    debounce((value) => {
      setFiltering(value);
    }, 750),
    []
  );

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debounceFn(event.target.value);
  };

  const { data, isError, isPending } = useGetConsumer();

  const renderDownloadButtonContent = () => {
    if (isPending)
      return (
        <>
          <Loader className="animate-spin w-5" />
          <span>{t('common.getUserMessage')}</span>
        </>
      );
    if (isError) {
      return (
        <>
          <XIcon className="w-5" />
          <span>{t('common.errorGetUserMessage')}</span>
        </>
      );
    }
    return (
      <>
        <DownloadIcon className="w-5" />
        <p>{t('common.downloadTable')}</p>
      </>
    );
  };

  const buttonStyles = () => {
    if (isPending)
      return 'flex items-center gap-2 cursor-not-allowed animate-pulse';
    if (isError)
      return 'flex items-center gap-2 cursor-not-allowed bg-red-500 text-white';
    return 'flex items-center gap-2 cursor-pointer text-white';
  };

  return (
    <div className="flex flex-col items-start justify-between gap-2 py-4 md:flex-row">
      <Button
        disabled={isPending || isError}
        onClick={() =>
          exportConsumerCSV(
            (data as TGetConsumers).results,
            allIdentificationTypes
          )
        }
        className={`${buttonStyles()} transition-all duration-700 ease-in-out`}
      >
        {renderDownloadButtonContent()}
      </Button>
      <div className="flex flex-col-reverse items-start justify-center gap-2 md:flex-row">
        <div className="flex items-center w-full gap-3">
          <TableSearchInput value={search ?? ''} onChange={onSearch} />
          {filtering && isFetching && (
            <Loader className="text-gray-600 animate-spin" />
          )}
        </div>

        <TableColumnsToggle disabled={disabled} table={table} />
      </div>
    </div>
  );
}
