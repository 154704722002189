import {
  endOfMonth,
  format,
  getDay,
  parse,
  startOfMonth,
  startOfWeek
} from 'date-fns';

import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import NoData from '@/components/common/no-data/NoData';
import MyEventWrapper from '@/components/section/calendar/MyEventWrapper';
import { Skeleton } from '@/components/ui/skeleton';
import TimeRangePicker from '@/components/ui/time-range-picker';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import useGetReservationsByCategory from '@/hooks/queries/reservations-api/useGetReservationsByCategory';
import useFormattedDate from '@/hooks/useFormattedDate';
import { ReservationWithResourceId } from '@/services/reservations';
import { enUS, es } from 'date-fns/locale';
import { Loader } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import {
  Calendar as BigCalendar,
  dateFnsLocalizer,
  Messages
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useTranslation } from 'react-i18next';

const locales = {
  'en-US': {
    ...enUS,
    week: 'Week',
    work_week: 'Work of week',
    day: 'Day',
    month: 'Month',
    previous: '<',
    next: '>',
    today: 'Today',
    agenda: 'Agenda',

    showMore: (total: number) => `+${total} more`
  },
  es: {
    ...es,
    week: 'Semana',
    work_week: 'Semana de trabajo',
    day: 'Día',
    month: 'Mes',
    previous: '<',
    next: '>',
    today: 'Hoy',
    agenda: 'El Diario',

    showMore: (total: number) => `+${total} más`
  }
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});

export type TDateRange = {
  from: Date;
  to: Date;
};

const CalendarV1Page = () => {
  const { i18n } = useTranslation();
  const language = i18n.language as keyof typeof locales;
  const { formattedDate } = useFormattedDate();

  const [dateRange, setDateRange] = useState<TDateRange>({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date())
  });
  const reservationsByCategory = useGetReservationsByCategory(dateRange);
  const { data, isError, isLoading } = useGetOrganization();

  const components = useMemo(
    () => ({
      eventWrapper: MyEventWrapper
    }),
    []
  );


  const defaultStartTime = new Date();
  const defaultEndTime = new Date();

  if (data && data.activeDays) {
    const activeDays = JSON.parse(data.activeDays);
  
    //  Get current day
    const today = new Date();
    const dayOfWeek = today.getDay();
    const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const todayName = dayNames[dayOfWeek];
  
    // Get active hours for today
    const todayActiveHours = activeDays[todayName]; 
  
    // If there are active hours for today, set the earliest start and latest end
    if (todayActiveHours && todayActiveHours.length > 0) {
      let earliestStart = 24;
      let latestEnd = 0;
  
      todayActiveHours.forEach((range:number[]) => {
        const [start, end] = range;
        if (start < earliestStart) earliestStart = start;
        if (end > latestEnd) latestEnd = end;
      });
  
      defaultStartTime.setHours(earliestStart, 0, 0, 0);
      defaultEndTime.setHours(latestEnd, 0, 0, 0);
    } else {
      defaultStartTime.setHours(0, 0, 0, 0);
      defaultEndTime.setHours(23, 59, 59, 999);
    }
  } else {
    defaultStartTime.setHours(0, 0, 0, 0);
    defaultEndTime.setHours(23, 59, 59, 999);
  }


  const [startTime, setStarTime] = useState(defaultStartTime);
  const [endTime, setEndTime] = useState(defaultEndTime);

  const eventPropGetter = useCallback(() => {
    return {
      className: 'bg-accent-90 text-xs py-4 px-3 hover:bg-primary hover:z-10'
    };
  }, []);

  const onRangeChange = useCallback(
    (date: Date[] | { start: Date; end: Date }) => {
      if (Array.isArray(date)) {
        setDateRange({
          from: startOfMonth(date[0]),
          to: endOfMonth(date[date.length - 1])
        });
      } else {
        setDateRange({
          from: date.start,
          to: date.end
        });
      }
    },
    []
  );

  const titleAccessor = useCallback(
    (event: ReservationWithResourceId) => {
      const consumersNames = event.consumers
        .map((consumer) => consumer.firstName)
        .join(', ');
      const startTime = formattedDate({
        date: event.startTime,
        dateFormat: 'time'
      });
      const endTime = formattedDate({
        date: event.endTime,
        dateFormat: 'time'
      });

      return `${event.consumers.length} - ${consumersNames} [${startTime}-${endTime}]`;
    },
    [formattedDate]
  );

  if (reservationsByCategory.isLoading || isLoading) {
    return <Skeleton className="w-screen h-screen" />;
  }
  if (reservationsByCategory.isError || isError) {
    return <ErrorMessage />;
  }
  if (!reservationsByCategory.data?.length || !data) {
    return <NoData />;
  }

  const messages: Messages = locales[language] || locales['en-US'];

  const resources = reservationsByCategory.data.map((cat) => cat.category);

  const resourcesEvents: ReservationWithResourceId[] =
    reservationsByCategory.data.reduce(
      (acc, cat) => acc.concat(cat.reservations),
      [] as ReservationWithResourceId[]
    );

  return (
    <div
      className={
        reservationsByCategory.isFetching
          ? 'opacity-50 pointer-events-none'
          : ''
      }
    >
      {reservationsByCategory.isFetching && (
        <Loader className="absolute z-20 w-12 h-12 animate-spin left-1/2 top-1/3" />
      )}
      <div className="flex mb-4">
        <TimeRangePicker
          startTime={startTime}
          endTime={endTime}
          setStartTime={setStarTime}
          setEndTime={setEndTime}
        />
      </div>
      <BigCalendar
        min={startTime}
        max={endTime}
        dayLayoutAlgorithm={'no-overlap'}
        localizer={localizer}
        events={resourcesEvents}
        startAccessor={(event) => new Date(event.startTime)}
        endAccessor={(event) => new Date(event.endTime)}
        titleAccessor={titleAccessor}
        className="h-[800px] min-w-[1300px]"
        culture={i18n.language}
        messages={messages}
        defaultView="day"
        resourceIdAccessor="resourceId"
        resourceTitleAccessor="name"
        resources={resources}
        views={['day', 'week', 'month']}
        components={components}
        tooltipAccessor={null}
        onRangeChange={onRangeChange}
        formats={{
          eventTimeRangeFormat: () => ''
        }}
        eventPropGetter={eventPropGetter}
        showAllEvents
      />
    </div>
  );
};
export default CalendarV1Page;
