import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetSaleByConsumer= (consumerId: string) => {
  return useQuery({
    queryKey: ['sale', 'consumer', consumerId],
    queryFn: () => api.sales.getSaleByConsumer(consumerId),
  });
};

export default useGetSaleByConsumer;
