import { Button } from '@/components/ui/button';
import useGetOrganizationServices from '@/hooks/queries/services-api/useGetOrganizationServices';
import useDateRange from '@/hooks/useDateRange';
import useFormattedDate from '@/hooks/useFormattedDate';
import useStatus from '@/hooks/useStatus';
import { TServices } from '@/services/services';
import { exportPaymentCSV } from '@/utils/payments';
import { Table } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { DownloadIcon, Loader, XIcon } from 'lucide-react';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RangeDatePicker from '../../range-date-picker/RangeDatePicker';
import TableColumnsToggle from '../../table-columns-toggle/TableColumnsToggle';
import { TableFacetedFilter } from '../../table-faceted-filter/TableFacetedFilter';
import TableFilters from '../../table-filters/TableFilters';
import TableSearchInput from '../common/table-search-input/TableSearchInput';
import { PaymentTable } from './payments-table.types';

type TableToolBarProps = Readonly<{
  table: Table<PaymentTable>;
  filtering: string;
  setFiltering: (value: string) => void;
  emptyData: boolean;
  isFetching: boolean;
}>;

export default function TableToolBar({
  table,
  emptyData,
  filtering,
  setFiltering,
  isFetching
}: TableToolBarProps) {
  const { formattedDate } = useFormattedDate();
  const isFiltered = table.getState().columnFilters.length > 0;

  const { t } = useTranslation();
  const { isPending, data: services } =
    useGetOrganizationServices<TServices[]>();

  const [search, setSearch] = useState(filtering || '');

  const { statuses } = useStatus('payments');

  const { date, resetDateRange, handleOnSelectDate } = useDateRange({
    column: table.getColumn('paidAt')
  });

  const debounceFn = useCallback(
    debounce((value) => {
      setFiltering(value);
    }, 750),
    []
  );

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debounceFn(event.target.value);
  };

  return (
    <div className="flex flex-col items-start justify-between gap-2 py-4 md:flex-row">
      <div className="flex gap-2">
        <TableFilters filterActive={isFiltered}>
          <div className="flex flex-col items-start w-full gap-2">
            {table.getColumn('paymentStatus.name') && (
              <TableFacetedFilter
                column={table.getColumn('paymentStatus.name')}
                title={t('modals.addPayment.paymentStatus')}
                options={statuses}
                disabled={emptyData}
              />
            )}
            {table.getColumn('sale.service.name') && (
              <TableFacetedFilter
                column={table.getColumn('sale.service.name')}
                title={t('tables.common.activity')}
                options={
                  services?.length
                    ? services?.map((service) => {
                        return {
                          label: service.name,
                          value: service.name
                        };
                      })
                    : []
                }
                disabled={isPending || !services?.length || emptyData}
              />
            )}
            {table.getColumn('paidAt') && (
              <div className="w-full">
                <RangeDatePicker
                  date={date}
                  onSelect={(value) => handleOnSelectDate(value)}
                  disabled={emptyData}
                />
              </div>
            )}
            {isFiltered && (
              <Button
                variant={'secondary'}
                onClick={() => {
                  table.resetColumnFilters();
                  resetDateRange();
                }}
                className="w-full"
              >
                {t('common.clearFilters')}
                <XIcon className="w-4 h-4 ml-2" />
              </Button>
            )}
          </div>
        </TableFilters>
        <Button
          onClick={() =>
            exportPaymentCSV(table.getFilteredRowModel().rows, formattedDate)
          }
          className="flex items-center gap-2"
        >
          <DownloadIcon className="w-5" />
          <p> {t('common.downloadTable')}</p>
        </Button>
      </div>

      <div className="flex flex-col-reverse items-start justify-center gap-2 md:flex-row">
        <div className="flex items-center w-full gap-3">
          <TableSearchInput value={search ?? ''} onChange={onSearch} />
          {search && isFetching && (
            <Loader className="text-gray-600 animate-spin" />
          )}
        </div>
        <TableColumnsToggle disabled={emptyData} table={table} />
      </div>
    </div>
  );
}
