import DeleteServicesCategoryModal from '@/components/common/modals/delete-services-category-modal/DeleteServicesCategoryModal';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import servicesCategorySchema from '@/schemas/servicesCategory-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

type TForm = ReturnType<typeof servicesCategorySchema>;
export type ServicesCategoryValues = z.infer<TForm>;
type FormServicesCategoryProps = {
  onSubmit: (data: ServicesCategoryValues) => void;
  isPending: boolean;
  defaultValues?: ServicesCategoryValues;
  isEdit?: boolean;
  sportId?: string;
};

const FormServicesCategory = ({
  defaultValues,
  isPending,
  onSubmit,
  isEdit,
  sportId
}: FormServicesCategoryProps) => {
  const form = useForm<ServicesCategoryValues>({
    resolver: zodResolver(servicesCategorySchema()),
    defaultValues: defaultValues ?? undefined
  });

  const text = isEdit ? t('form.update') : t('form.create');
  const [deleteServicesCategory, setDeleteServicesCategory] = useState(false);

  const openDeleteModal = () => {
    setDeleteServicesCategory(true);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={`bg-accent p-8 rounded-md w-full`}
      >
        <div className="flex flex-col gap-3">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.name')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('form.description')}
                  <span className="ml-2 text-sm font-light text-gray-500">
                    {t('form.optional')}
                  </span>
                </FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button type="submit" className="mt-8" disabled={isPending}>
            {isPending ? <Loader className="w-4 h-4 animate-spin" /> : text}
          </Button>
          <Button
            className="mt-8"
            variant="destructive"
            type="button"
            onClick={openDeleteModal}
          >
            {t('form.delete')}
          </Button>
          <DeleteServicesCategoryModal
            open={deleteServicesCategory}
            onOpenChange={setDeleteServicesCategory}
            sportId={sportId ?? ''}
          />
        </div>
      </form>
    </Form>
  );
};

export default FormServicesCategory;
