import { availableDateType, Slot } from '@/services/services';
import { parseDate } from './dates';
import { set } from 'date-fns';

/**
 * Enum representing the possible statuses of a schedule slot.
 */
export enum SCHEDULE_STATUS {
  available = 'available',
  unavailable = 'unavailable',
  blocked = 'blocked',
}

/**
 * Type representing a schedule with a date and an array of slots.
 */
export type TSchedule = {
  date: Date;
  slots: Slot[];
};

/**
 * Sorts and filters available dates.
 * @param availableDates - Array of available dates from the service.
 * @returns An array of TSchedule objects with parsed dates and available slots.
 */
export const sortSchedule = (availableDates: availableDateType[]): TSchedule[] => {
  return availableDates
    .map((dateEntry) => ({
      ...dateEntry,
      date: parseDate(dateEntry.date),
    }))
    .filter((dateEntry) =>
      dateEntry.slots?.some((slot) => slot.status === SCHEDULE_STATUS.available)
    );
};

/**
 * Finds the schedule for a specific date.
 * @param params - An object containing the date to find and the schedule array.
 * @returns The TSchedule object matching the date, or undefined if not found.
 */
export const findSchedule = ({
  date,
  schedule,
}: {
  date: Date;
  schedule: TSchedule[];
}): TSchedule | undefined => {
  return schedule.find((scheduleEntry) => scheduleEntry.date.getDate() === date.getDate());
};

/**
 * Validates if a custom time is valid based on blocked time slots.
 * @param params - An object containing the date and the schedule array.
 * @returns A boolean indicating whether the selected time is invalid (true) or valid (false).
 */
export const validateCustomTime = ({
  date,
  schedule,
}: {
  date: Date;
  schedule: TSchedule[];
}): boolean => {
  // Find the schedule for the specific date
  const scheduleForDate = findSchedule({ date, schedule });

  // If there's no schedule or slots for the date, the time is considered valid
  if (!scheduleForDate || !scheduleForDate.slots) {
    return false;
  }

  // Filter out the slots that are blocked
  const blockedSlots = scheduleForDate.slots.filter(
    (slot) => slot.status !== SCHEDULE_STATUS.available
  );

  // Convert blocked slots into time ranges
  const blockedTimeRanges = blockedSlots.map((slot) => {
    // Parse 'HH:mm' strings into hours and minutes
    const [startHours, startMinutes] = slot.from.split(':').map(Number);
    const [endHours, endMinutes] = slot.to.split(':').map(Number);

    // Create Date objects for start and end times on the given date
    const startTime = set(date, {
      hours: startHours,
      minutes: startMinutes,
    });

    const endTime = set(date, {
      hours: endHours,
      minutes: endMinutes,
    });

    return { from: startTime.getTime(), to: endTime.getTime() };
  });


  // Get the timestamp of the selected date and time
  const selectedTime = date.getTime();

  // Check if the selected time falls within any blocked time range
  const isInvalidTime = blockedTimeRanges.some(
    (timeRange) => selectedTime >= timeRange.from && selectedTime < timeRange.to
  );

  return isInvalidTime;
};