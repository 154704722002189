import { PaginatedResponse } from '@/utils/utils';
import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { TServices } from './services';

export interface TServiceDivision {
  createdBy: string;
  updatedBy: string;
  createdAt: number;
  updatedAt: number;
  active: true;
  id: string;
  service: TServices;
  name: string;
  description: string;
  duration: number;
  quantity: number;
  order: number;
}

export interface TPostServiceDivision {
  service: string;
  name: string;
  description?: string;
  duration: number;
  quantity: number;
}

export class ServiceDivisionApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getServiceDivisionByServiceId(
    serviceId: string
  ): Promise<TServiceDivision[] | undefined> {
    const response: ApiResponse<PaginatedResponse<TServiceDivision>> =
      await this.api.apisauce.get(
        `service-division?filter.service.id=string.eq.${serviceId}`
      );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results;
  }

  async getServiceDivisionById(
    divisionId: string
  ): Promise<TServiceDivision | undefined> {
    const response: ApiResponse<TServiceDivision> = await this.api.apisauce.get(
      `service-division/${divisionId}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async postServiceDivision(
    payload: TPostServiceDivision
  ): Promise<TServiceDivision | undefined> {
    const response: ApiResponse<TServiceDivision> =
      await this.api.apisauce.post('service-division', payload);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async patchServiceDivision(
    payload: Partial<TPostServiceDivision>,
    divisionId: string
  ): Promise<TServiceDivision | undefined> {
    const response: ApiResponse<TServiceDivision> =
      await this.api.apisauce.patch(`service-division/${divisionId}`, payload);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async deleteServiceDivision(
    divisionId: string
  ): Promise<TServiceDivision | undefined> {
    const response: ApiResponse<TServiceDivision> =
      await this.api.apisauce.delete(`service-division/${divisionId}`);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
