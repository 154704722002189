import type { StoreApi } from 'zustand';
import type { RootStore } from '@/stores/root-store.types';
import type { StepService } from './step-service.types';

const ONE_HOUR = 60 * 60 * 1000;

export const createStepServiceSlice = (
  set: StoreApi<RootStore>['setState'],
  get: StoreApi<RootStore>['getState'],
  ...rest: StoreApi<RootStore>[]
) => ({
  stepServicesData: undefined,
  lastUpdated: null,
  loadStepServicesData: (data: StepService) =>
    set((state) => ({
      stepServicesData: (state.stepServicesData = {
        ...state.stepServicesData,
        ...data
      }),
      lastUpdated: Date.now()
    })),
  cleanStepServicesData: () =>
    set({
      stepServicesData: undefined
    }),

  cleanConsumers: (filterType = 'all') =>
    set((state) => ({
      stepServicesData: {
        ...state.stepServicesData,
        consumers: (state?.stepServicesData?.consumers || []).filter(
          (consumer: any) => {
            if (filterType === 'user') return !consumer.userIsParticipant;
            if (filterType === 'participants')
              return consumer.userIsParticipant;
            return false;
          }
        )
      }
    })),

  isDataExpired: () => {
    const lastUpdated = get().lastUpdated;
    return !lastUpdated || Date.now() - lastUpdated > ONE_HOUR;
  }
});
