import BasicCard from '@/components/common/cards/basic-card/BasicCard';
import BasicTabsList from '@/components/common/tab/basic-tab-list/BasicTabsList';
import { Button } from '@/components/ui/button';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Tabs, TabsContent } from '@/components/ui/tabs';
import type {
  TCategoryServices,
  TOrganizationService
} from '@/services/services';
import { useRootStore } from '@/stores/root-store';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ChooseService: React.FC<{ services: TCategoryServices[] }> = ({
  services
}) => {
  const { t } = useTranslation();

  const continueRef = useRef<null | HTMLDivElement>(null);
  const addStep = useRootStore((store) => store.addStep);
  const [selectedTab, setSelectedTab] = useState(services[0].sport.id);
  const [selectedService, setSelectedService] =
    useState<TOrganizationService>();
  const loadStepServicesData = useRootStore(
    (store) => store.loadStepServicesData
  );

  return (
    <>
      <h2 className="mb-6 font-bold">{t('newBooking.stepServiceTitle')}</h2>
      <Tabs
        value={selectedTab}
        onValueChange={(value) => setSelectedTab(value)}
      >
        <ScrollArea>
          <BasicTabsList services={services} />
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        {services.map((category) => (
          <TabsContent
            key={category.sport.id}
            value={category.sport.id}
            className="grid items-stretch grid-cols-1 gap-3 md:grid-cols-2"
          >
            {category.services.map((service) => (
              <BasicCard
                key={service.id}
                service={service}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
              />
            ))}
          </TabsContent>
        ))}
      </Tabs>
      <div ref={continueRef}>
        <Button
          type="button"
          className="w-full mt-9"
          disabled={!selectedService}
          onClick={() => {
            if (selectedService) {
              loadStepServicesData({
                service: selectedService
              });
              addStep(1);
            }
          }}
        >
          {t('newBooking.continue')}
        </Button>
      </div>
    </>
  );
};

export default ChooseService;
