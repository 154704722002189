import { ApiResponse } from 'apisauce';
import { Api } from './api';

export interface TOrganization {
  id: string;
  name: string;
  code: string;
  description: string;
  email: string;
  maxCancellationTime: number;
  overdueCancellationPenalty: number;
  activeDays: string;
  activeMonths: string;
  tz: string;
  phone: string;
  address: string;
  location: string;
  postalCode: string;
  currency: string;
  icon: string;
  createdBy: string;
  updatedBy: string;
  createdAt: number;
  updatedAt: number;
  active: boolean;
}

type localeTypes = 'en' | 'es';

interface TLanguage {
  createdBy: string;
  updatedBy: string;
  createdAt: number;
  updatedAt: number;
  active: boolean;
  id: string;
  name: string;
  englishName: string;
  code: localeTypes;
}

export interface TFaq {
  id: string;
  language: TLanguage;
  question: string;
  answer: string;
  order: number;
}

export interface TOrganizationFAQ extends TOrganization {
  faq: TFaq[];
}

export interface TGetOganization {
  total: number;
  limit: number;
  offset: number;
  page: number;
  hasNextPage: boolean;
  results: TOrganizationFAQ[];
}

export class OrganizationApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getOrganization(
    organizationName: string
  ): Promise<TOrganizationFAQ | undefined> {
    const response: ApiResponse<TOrganizationFAQ> = await this.api.apisauce.get(
      `organization/${organizationName}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
