/* eslint-disable react-hooks/exhaustive-deps */
import api from '@/services/api';
import { useRootStore } from '@/stores/root-store';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

const useAuth = () => {
  const { getAccessTokenSilently, logout, user } = useAuth0();
  const [tokenIsPending, setTokenIsPending] = useState(true);
  const setToken = useRootStore((store) => store.setToken);
  const token = useRootStore((state) => state.token);
  const removeToken = useRootStore((store) => store.removeToken);

  const userRoleKey = `${process.env.AUTH0_ISSUER_BASE_URL}/roles`;
  if (user) {
    const userRoles = user[userRoleKey] as string[];
    const isAdmin = userRoles.some((userRole) => userRole === 'admin');
    if (!isAdmin) {
      logout({ logoutParams: { returnTo: window.location.origin } });
      removeToken();
    }
  }

  const setAuthToken = (authToken: string) => {
    api.api.setToken(authToken);
    setTokenIsPending(false);
  };

  (async function getAuthToken() {
    if (!token) {
      const authToken = await getAccessTokenSilently();
      if (authToken && authToken !== null) {
        setToken(authToken);
        setAuthToken(authToken);
      }
    }
  })();

  useEffect(() => {
    api.api.setAuth0Logout(logout);
    if (token) {
      setAuthToken(token);
    }
  }, [token]);
  return { tokenIsPending };
};

export default useAuth;
