import MetabaseDashboard from '@/components/section/metabase-dashboard/metabaseDashboard';

const DashboardPage = () => {
  const dashboardId = process.env.MAIN_DASHBOARD_METABASE_ID ?? '';

  return (
    <div className="w-full">
      <MetabaseDashboard dashboardId={dashboardId} />
    </div>
  );
};

export default DashboardPage;
