import { ReservationsTable } from '@/components/common/tables/reservations-table/ReservationsTable';

const ReservationsPage = () => {

  return (
    <div className="w-full">
      <ReservationsTable />
    </div>
  );
};

export default ReservationsPage;
