import IdentificationTypeSelect from '@/components/common/input/identification-types-select/IdentificationTypeSelect';
import { Button } from '@/components/ui/button';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { useFieldArray, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ParentsForm: React.FC<{
  form: UseFormReturn<any, any, undefined>;
  formField: 'parents';
  onSave: () => Promise<void>;
}> = ({ form, formField, onSave }) => {
  const { t } = useTranslation();

  const handleValidate = () => {
    onSave();
  };

  const { fields } = useFieldArray({
    control: form.control,
    name: formField
  });

  return (
    <>
      {fields.map((_field, index) => {
        const isError = Boolean(
          (form.formState.errors?.parents as any)?.[index]
        );
        return (
          <div key={`parent-${index}`}>
            <p
              className={cn(
                isError && 'text-destructive',
                'font-semibold my-5'
              )}
            >
              {t('parents', { number: index + 1 })}
            </p>
            {index === 0 && (
              <p className="mb-1 text-primary">{t('userDescription')}</p>
            )}
            <div
              className="flex flex-col gap-8 p-6 mb-3 border rounded-md border-primary"
              key={'participant-participant'}
            >
              <FormField
                control={form.control}
                name={`${formField}.${index}.firstName`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>
                        {t('stepParticipants.form.firstName')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={`${formField}.${index}.lastName`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>
                        {t('stepParticipants.form.lastName')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={`${formField}.${index}.connection`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>{t('connection')}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder={t('connectionPlaceholder')}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={`${formField}.${index}.phone`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>{t('phone')}</FormLabel>
                      <FormControl>
                        <Input
                          type="tel"
                          {...field}
                          placeholder={t('common.placeholder.phone')}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={`${formField}.${index}.identification.type`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <IdentificationTypeSelect
                        defaultValue={field?.value}
                        onValueChange={(value) => {
                          field.onChange(value);
                        }}
                      />
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={`${formField}.${index}.identification.number`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>
                        {t('stepParticipants.form.identificationNumber')}
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t('stepParticipants.form.id')}
                          {...field}
                          value={field?.value || ''}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={`${formField}.${index}.email`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>{t('stepParticipants.form.mail')}</FormLabel>
                      <FormControl>
                        <Input type="tel" {...field} />
                      </FormControl>
                      <FormMessage />
                      {index === 0 && (
                        <FormDescription>
                          {t('newBooking.formParticipantData.mail_description')}
                        </FormDescription>
                      )}
                    </FormItem>
                  );
                }}
              />
            </div>
          </div>
        );
      })}
      <Button className="w-full" type="button" onClick={handleValidate}>
        {t('common.validate')}
      </Button>
    </>
  );
};

export default ParentsForm;
