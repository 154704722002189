import { InventoryValues } from '@/components/forms/form-inventory/FormInventory';
import api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const usePatchEquipment = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: ['patchEquipment'],
    mutationFn: (data: {
      payload: Partial<InventoryValues>;
      equipmentId: string;
    }) => {
      return api.equipments.patchEquipment(data.payload, data.equipmentId);
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['equipments'] });
      toast.success(t('toast.equipmentUpdated'));
      navigate(-1);
    },
    retry: false
  });
};

export default usePatchEquipment;
