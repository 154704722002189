import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import LanguageChanger from './LanguageChangerButton/LanguageChanger';
import { UserNav } from './UserNav';

const Navbar = () => {
  const { data: organization } = useGetOrganization();
  return (
    <nav className="bg-accent w-ful">
      <div className="flex flex-row items-center justify-between py-2 px-7 lg:py-3 ">
        <div className="flex flex-col items-start ">
          <h1 className="text-xl font-extrabold text-primary">
            {organization?.name}
          </h1>
          <p className="text-xs font-thin">
            Powered by <span className="font-semibold text-primary">Zazu</span>
          </p>
        </div>
        <div className="flex flex-row items-center gap-6">
          <LanguageChanger />
          <UserNav />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
