import { Button } from '@/components/ui/button';
import { AlertCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const ErrorMessage = ({ refetch }: { refetch?: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center p-6 bg-red-100 text-red-700 border border-red-400 rounded-md mt-4">
      <AlertCircle className="w-16 h-16 text-red-700 mb-4" />

      <h2 className="text-xl font-semibold mb-2">{t('error.title')}</h2>
      <p className="mb-4">{t('error.message')}</p>
      {refetch ? (
        <Button variant={'secondary'} onClick={() => refetch()}>
          {t('error.retry')}
        </Button>
      ) : null}
    </div>
  );
};

export default ErrorMessage;
