import { FormControl, FormLabel } from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Skeleton } from '@/components/ui/skeleton';
import useGetIdentificationType from '@/hooks/queries/identification-api/useGetIdentificationType';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../error-message/ErrorMessage';
import type { TIdentificationTypeSelect } from './IdentificationTypeSelect.types';

const IdentificationTypeSelect: React.FC<TIdentificationTypeSelect> = ({
  disabled,
  defaultValue,
  onValueChange
}) => {
  const { t: commonTranslation } = useTranslation();

  const {
    data: allIdentificationTypes,
    isLoading,
    isError
  } = useGetIdentificationType();

  if (isLoading || !allIdentificationTypes?.length) {
    return <Skeleton className="w-8 h-4" />;
  }
  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <Select
      disabled={disabled}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
    >
      <FormLabel>
        {commonTranslation('identificationTypes.identificationType')}
      </FormLabel>
      <FormControl>
        <SelectTrigger className="w-full space-x-2">
          <SelectValue
            placeholder={commonTranslation('identificationTypes.placeholder')}
          />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        {allIdentificationTypes.map((identificationType) => {
          return (
            <SelectItem
              value={identificationType.id}
              key={identificationType.id}
            >
              {identificationType.name}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
};

export default IdentificationTypeSelect;
