import useGetEquipmentById from '@/hooks/queries/equipments-api/useGetEquimentsAvailability';
import React from 'react';
import { BlockEquipmentProps } from './BlockEquipment.types';
import BlockEquipmentForm from '@/components/forms/form-block-equipment/FormBlockEquipment';
import NoData from '@/components/common/no-data/NoData';
import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import { Loader } from 'lucide-react';

const BlockEquipment: React.FC<BlockEquipmentProps> = ({
  equipment,
  onOpenChange
}) => {
  const { id } = equipment;
  const { data: availableDates, isFetching, isError } = useGetEquipmentById(id);

  if (isFetching) {
    return (
      <div className="flex justify-center">
        <Loader className="w-4 h-4 mr-4 animate-spin" />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage />;
  }

  if (!availableDates) {
    return <NoData />;
  }

  return (
    <BlockEquipmentForm
      equipmentId={id}
      availableDates={availableDates}
      onOpenChange={onOpenChange}
    />
  );
};

export default BlockEquipment;
