import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { Skeleton } from '@/components/ui/skeleton';
import { Switch } from '@/components/ui/switch';
import useGetUser from '@/hooks/queries/users-api/useGetUser';
import useUpdateAdmin from '@/hooks/queries/users-api/useUptadeAdmin';
import { useRootStore } from '@/stores/root-store';
import { useAuth0 } from '@auth0/auth0-react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

export function UserNav() {
  const { t } = useTranslation();
  const removeToken = useRootStore((store) => store.removeToken);

  const { logout, user } = useAuth0();
  const { mutate, isPending } = useUpdateAdmin();
  const { data: userData } = useGetUser();

  const handleSwitchChange = debounce((value) => {
    mutate({ receiveNotifications: value });
  }, 500);

  if (!userData) {
    return null;
  }

  if (user) {
    const userRoleKey = `${process.env.AUTH0_ISSUER_BASE_URL}/roles`;
    const userRoles = user[userRoleKey] as string[];
    return (
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="ghost" className="relative w-8 h-8 rounded-full">
            <Avatar className="w-8 h-8">
              <AvatarImage src={user?.picture ?? ''} alt={user?.name ?? ''} />
              <AvatarFallback>{user?.name?.[0]}</AvatarFallback>
            </Avatar>
          </Button>
        </SheetTrigger>
        <SheetContent className="w-[18rem]" forceMount>
          <div className="mt-5">
            <div className="flex items-end space-x-2">
              <Avatar className="w-8 h-8">
                <AvatarImage src={user?.picture ?? ''} alt={user?.name ?? ''} />
                <AvatarFallback>{user?.name?.[0]}</AvatarFallback>
              </Avatar>
              <div className="flex flex-col space-y-1">
                <p className="text-sm font-medium leading-none">
                  {`${user?.name} (${userRoles.join(', ')})`}
                </p>
                <p className="text-xs leading-none text-muted-foreground">
                  {user?.email}
                </p>
              </div>
            </div>

            <Separator className="my-6" />

            <div className="space-y-3 ">
              {userData ? (
                <Label
                  htmlFor="notifications"
                  className="flex items-center justify-between h-8 mt-4 space-x-2"
                >
                  <p className="disabled">{t('session.notifications')}</p>
                  <div className="flex items-center">
                    <Switch
                      onCheckedChange={handleSwitchChange}
                      checked={userData.receiveNotifications}
                      id="notifications"
                      disabled={isPending}
                      className={isPending ? 'opacity-50' : ''}
                    />
                  </div>
                </Label>
              ) : (
                <Skeleton className="w-full h-8 mt-4" />
              )}
            </div>

            <Button
              variant={null}
              onClick={() => {
                logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                    federated: true,
                    localOnly: false
                  }
                });
                removeToken();
              }}
              className="justify-between w-full h-8 pl-0 mt-3"
            >
              <p> {t('session.logout')}</p>
            </Button>
          </div>
        </SheetContent>
      </Sheet>
    );
  }
}
