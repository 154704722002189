import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetConsumer = (id: string | undefined) => {
  return useQuery({
    queryKey: ['consumers', id],
    queryFn: () => (id ? api.consumer.getConsumerById(id) : undefined),
    enabled: Boolean(id)
  });
};
export default useGetConsumer;
