import api from '@/services/api';
import {
  keepPreviousData,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { ColumnFiltersState, PaginationState, SortingState } from '@tanstack/react-table';
import { useEffect } from 'react';


export type GetSalesProps = {
  pagination: PaginationState,
  filtering: string,
  sorting: SortingState,
  columnFilters: ColumnFiltersState
}

const getQueryOptions = ({pagination, filtering, sorting, columnFilters}: GetSalesProps) => {
  return {
    queryKey: ['sales', pagination, {search: filtering}, sorting, columnFilters],
    queryFn: () => api.sales.getPaginatedSales({pagination, filtering, sorting, columnFilters})
  };
};

const useGetPaginatedSales = (props: GetSalesProps) => {

  const queryClient = useQueryClient();

  //prefetch next page
  useEffect(() => {
    queryClient.prefetchQuery(
      getQueryOptions({ 
        ...props, 
        pagination: { 
          ...props.pagination, 
          pageIndex: props.pagination.pageIndex + 1 
        } 
      })
    );
  }, [queryClient, props]);

  return useQuery({
    ...getQueryOptions(props),
    staleTime: 5 * 1000,
    placeholderData: keepPreviousData
  });
};

export default useGetPaginatedSales;
