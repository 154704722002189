import api from '@/services/api';
import { TServices } from '@/services/services';
import { useQuery } from '@tanstack/react-query';

const useGetOrganizationServices = <TResult,>(select?: (sales: TServices[]) =>  TResult) => {
  return useQuery({
    queryKey: ['services'],
    queryFn: () => api.services.getServices(),
    select
  });
}

export const useGetServicesById = (id: string) =>
  useGetOrganizationServices<TServices | undefined>((sales) => sales.find((sale) => sale.id === id));

export default useGetOrganizationServices;
