import api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const useDeleteEquipmentType = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: ['deleteEquipmentType'],
    mutationFn: (equipmentTypeId: string) =>
      api.equipments.deleteEquipmentType(equipmentTypeId),
    onError: (error) => {
      toast.error(t('toast.errorDeleteEquipmentType'), {duration: 10000});
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['equipmentTypes']
      });
      toast.success(t('toast.deleteEquipmentType'));
      navigate(-1);
    },
    retry: false
  });
};

export default useDeleteEquipmentType;
