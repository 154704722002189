import { getMonth, getYear, startOfDay } from 'date-fns';
import { useReducer } from 'react';
interface CalendarState {
  month: number;
  year: number;
  date: Date;
}
type CalendarAction =
  | { type: 'NEXT_MONTH' }
  | { type: 'PREVIOUS_MONTH' }
  | { type: 'NEXT_DAY' }
  | { type: 'PREVIOUS_DAY' }
  | { type: 'SET_MONTH'; payload: number }
  | { type: 'SET_YEAR'; payload: number }
  | { type: 'SET_DAY'; payload: Date };

const initialState = (initialDate?: Date): CalendarState => {
  const currentMonth = getMonth(new Date()) + 1;
  const currentYear = new Date().getFullYear();
  const currentDate = new Date();
  const initialMonth = initialDate && getMonth(initialDate) + 1;
  const initialYear = initialDate && getYear(initialDate);

  if (initialMonth && initialYear && initialDate) {
    return {
      month: initialMonth,
      year: initialYear,
      date: startOfDay(initialDate)
    };
  }

  return {
    month: currentMonth,
    year: currentYear,
    date: currentDate
  };
};

const reducer = (
  state: CalendarState,
  action: CalendarAction
): CalendarState => {
  switch (action.type) {
    case 'NEXT_MONTH':
      return state.month === 12
        ? { month: 1, year: state.year + 1, date: state.date }
        : { month: state.month + 1, year: state.year, date: state.date };
    case 'PREVIOUS_MONTH':
      return state.month === 1
        ? { month: 12, year: state.year - 1, date: state.date }
        : { month: state.month - 1, year: state.year, date: state.date };
    case 'NEXT_DAY':
      return state.month === 31
        ? { month: 1, year: state.year + 1, date: state.date }
        : { month: state.month + 1, year: state.year, date: state.date };
    case 'PREVIOUS_DAY':
      return state.month === 1
        ? { month: 31, year: state.year - 1, date: state.date }
        : { month: state.month - 1, year: state.year, date: state.date };
    case 'SET_DAY': {
      const month = getMonth(action.payload) + 1;
      const year = getYear(action.payload);
      return { year, month, date: action.payload };
    }
    case 'SET_MONTH':
      return { ...state, month: action.payload };
    case 'SET_YEAR':
      return { ...state, year: action.payload };
    default:
      return state;
  }
};
export interface TCalendarPaginateNavigation {
  month: number;
  year: number;
  date: Date;
  goToNextMonth: () => void;
  goToPreviousMonth: () => void;
  goToNextDay: () => void;
  goToPreviousDay: () => void;
  setMonth: (newMonth: number) => void;
  setYear: (newYear: number) => void;
  setDay: (newDay: Date) => void;
}
export default function useCalendarPaginateNavigation(
  initialDate?: Date
): TCalendarPaginateNavigation {
  const [state, dispatch] = useReducer(reducer, initialState(initialDate));
  const { month, year, date } = state;
  const goToNextMonth = () => dispatch({ type: 'NEXT_MONTH' });
  const goToPreviousMonth = () => dispatch({ type: 'PREVIOUS_MONTH' });
  const goToNextDay = () => dispatch({ type: 'NEXT_DAY' });
  const goToPreviousDay = () => dispatch({ type: 'PREVIOUS_DAY' });
  const setMonth = (newMonth: number) =>
    dispatch({ type: 'SET_MONTH', payload: newMonth });
  const setDay = (newDay: Date) =>
    dispatch({ type: 'SET_DAY', payload: newDay });
  const setYear = (newYear: number) =>
    dispatch({ type: 'SET_YEAR', payload: newYear });
  return {
    month,
    year,
    goToNextMonth,
    goToPreviousMonth,
    goToNextDay,
    goToPreviousDay,
    setMonth,
    setYear,
    setDay,
    date
  };
}
