import { PaymentTable } from '@/components/common/tables/payments-table/payments-table.types';
import { Row } from '@tanstack/react-table';
import { download, generateCsv } from 'export-to-csv';
import { csvConfig } from './utils';

export const paymentMethods = [
  {
    name: 'Cash',
    id: '22182c18-2fda-4356-8bde-abf17733e72c'
  },
  {
    name: 'Debit/Credit',
    id: 'c878a406-63a0-45d9-bed1-ba818ebf2433'
  },
  {
    name: 'TPV',
    id: 'ef75aed8-40fb-489f-963c-11722aa12440'
  }
];

export const exportPaymentCSV = (
  rows: Row<PaymentTable>[],
  formattedDate: ({ date, dateFormat }: {
    date: string | number | Date;
    dateFormat?: "date" | "dateTime" | "time";
}) => string
  // allIdentificationTypes: TIdentificationType[] | undefined
) => {
  // if (!allIdentificationTypes) return;
  const rowData = rows.map((row) => {
    const data = row.original;

    return {
      id: data.id,
      active: data.active,
      reference: data.reference,
      'user id': data.sale.user.id,
      'user full name': `${data.sale.user.firstName} ${data.sale.user.lastName}`,
      'user location': data.sale.user.location,
      'user phone': data.sale.user.phone,
      activity: data.sale.service.name,
      'amount paid': data.amount,
      'amount pending': data.sale.amountPending,
      total: data.sale.service.price,
      paymentStatus: data.paymentStatus.name,
      paidAt: formattedDate({ date: Number(data.paidAt) }) ,
      updatedAt: formattedDate({ date: Number(data.updatedAt) }),
      'payment type': data.paymentType.name,
      comments: data.observations,
    };
  });

  const config = csvConfig({ filename: 'payments' });

  const csv = generateCsv(config)(rowData);
  download(config)(csv);
};
