import { Badge } from '@/components/ui/badge';
import useGetOrganizationServices from '@/hooks/queries/services-api/useGetOrganizationServices';
import useDateRange from '@/hooks/useDateRange';
import { TServices } from '@/services/services';
import { ReservationStatus } from '@/utils/reservations';
import { Table } from '@tanstack/react-table';
import { subYears } from 'date-fns';
import { debounce } from 'lodash';
import { Loader } from 'lucide-react';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableColumnsToggle from '../../table-columns-toggle/TableColumnsToggle';
import { TableFacetedFilter } from '../../table-faceted-filter/TableFacetedFilter';
import TableFilters from '../../table-filters/TableFilters';
import TableSearchInput from '../common/table-search-input/TableSearchInput';
import GroupFilter from './GroupFilter';

type TableToolBarProps<TData> = Readonly<{
  table: Table<TData>;
  filtering: string;
  setFiltering: (value: string) => void;
  isFetching: boolean;
}>;

export default function TableToolBar<TData>({
  table,
  filtering,
  setFiltering,
  isFetching
}: TableToolBarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  const { t } = useTranslation();
  const { isPending, data: services } =
    useGetOrganizationServices<TServices[]>();

  const [search, setSearch] = useState(filtering || '');

  const { resetDateRange, handleOnSelectDate } = useDateRange({
    column: table.getColumn('sale.saleConsumers.consumer.birthdate')
  });

  const debounceFn = useCallback(
    debounce((value) => {
      setFiltering(value);
    }, 750),
    []
  );

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debounceFn(event.target.value);
  };

  const ageRanges = [
    { minAge: 4, maxAge: 5 },
    { minAge: 6, maxAge: 7 },
    { minAge: 8, maxAge: 9 },
    { minAge: 10, maxAge: 100 } // 10+
  ];

  const groupOptions = ageRanges.map(({ minAge, maxAge }, index) => {
    const today = new Date();
    const maxDate = subYears(today, minAge);
    const minDate = maxAge !== null ? subYears(today, maxAge + 1) : undefined;

    return {
      value: index.toString(),
      minDate,
      maxDate,
      label: t(`tables.group.${index + 1}`)
    };
  });

  const statusesOptions = [
    {
      value: ReservationStatus.Completed,
      label: t('common.status.present'),
      icon: () => (
        <Badge
          variant={'Pending'}
          className="w-4 h-4 mx-1 rounded-full bg-primary"
        />
      )
    },
    {
      value: ReservationStatus.Absent,
      label: t('common.status.absent'),
      icon: () => (
        <Badge
          variant={'Accepted'}
          className="w-4 h-4 mx-1 rounded-full bg-red-500"
        />
      )
    },
    {
      value: ReservationStatus.Accepted,
      label: t('common.status.pending'),
      icon: () => (
        <Badge
          variant={'Rejected'}
          className="w-4 h-4 mx-1 rounded-full bg-gray-400"
        />
      )
    }
  ];

  return (
    <div className="flex flex-col items-start justify-between gap-2 py-4 md:flex-row">
      <TableFilters filterActive={isFiltered}>
        <div className="flex flex-col items-start w-full gap-2">
          {table.getColumn('sale.saleConsumers.consumer.birthdate') && (
            <GroupFilter
              column={table.getColumn('sale.saleConsumers.consumer.birthdate')}
              title={t('activity.student.group')}
              options={groupOptions}
              resetDateRange={resetDateRange}
              onSelect={({ minDate, maxDate }) =>
                handleOnSelectDate({ from: minDate, to: maxDate })
              }
            />
          )}
          {table.getColumn('reservationStatus.name') && (
            <TableFacetedFilter
              column={table.getColumn('reservationStatus.name')}
              title={t('tables.servicesSold.statuses')}
              options={statusesOptions}
              disabled={isPending}
            />
          )}
        </div>
      </TableFilters>

      <div className="flex flex-col-reverse items-start justify-center gap-2 md:flex-row">
        <div className="flex items-center w-full gap-3">
          <TableSearchInput value={search ?? ''} onChange={onSearch} />
          {search && isFetching && (
            <Loader className="text-gray-600 animate-spin" />
          )}
        </div>
        <TableColumnsToggle table={table} />
      </div>
    </div>
  );
}
