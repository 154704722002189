import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetReservationsToday = () => {
  return useQuery({
    queryKey: ['reservation', 'today'],
    queryFn: () => api.reservations.getReservationsToday()
  });
};

export default useGetReservationsToday;
