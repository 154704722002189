import { useRootStore } from '@/stores/root-store';
import { LogoutOptions } from '@auth0/auth0-react';
import { ApisauceInstance, create } from 'apisauce';
import { t } from 'i18next';
import { toast } from 'sonner';
import { AnalyticsApi } from '../analytics';
import { EquipmentsApi } from '../equipments/equipments';
import { FileApi } from '../file';
import { IdentificationApi } from '../identification';
import { OrganizationApi } from '../organization';
import { PaymentsApi } from '../payments';
import { ReservationsApi } from '../reservations';
import { SalesApi } from '../sales';
import { ServicesApi } from '../services';
import { ServiceDivisionApi } from '../servicesDivision';
import { SportsApi } from '../sports';
import { UserApi } from '../user';
import { ApiConfig, DEFAULT_API_CONFIG } from './config';
import { ConsumerApi } from '../consumer';

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  token: string | null;

  auth0Logout?: (options?: LogoutOptions | undefined) => void;
  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.token = null;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json'
      }
    });
  }

  removeToken() {
    this.token = null;
    this.apisauce.setHeaders({
      Accept: 'application/json'
    });
    this.apisauce.deleteHeader('Authorization');
  }

  setToken(token: string) {
    this.token = token;
    this.apisauce.setHeaders({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    });
  }

  setAuth0Logout(auth0Logout: () => void) {
    this.auth0Logout = auth0Logout;
  }
}

const baseApi = new Api();
baseApi.setup();
const api = {
  api: baseApi,
  reservations: new ReservationsApi(baseApi),
  services: new ServicesApi(baseApi),
  serviceDivision: new ServiceDivisionApi(baseApi),
  sales: new SalesApi(baseApi),
  payments: new PaymentsApi(baseApi),
  equipments: new EquipmentsApi(baseApi),
  user: new UserApi(baseApi),
  analytics: new AnalyticsApi(baseApi),
  organization: new OrganizationApi(baseApi),
  identification: new IdentificationApi(baseApi),
  sports: new SportsApi(baseApi),
  file: new FileApi(baseApi),
  consumer: new ConsumerApi(baseApi)
};

const responseMonitor = async (response: any) => {
  // we force to the user to login await if token is expired
  if (response.status === 401) {
    if (api.api.auth0Logout) {
      toast.error(t('toast.tokenExpired'));
      useRootStore.getState().removeToken();
      api.api.auth0Logout({
        logoutParams: { returnTo: window.location.origin }
      });
    }
  }
};

api.api.apisauce.addMonitor(responseMonitor);

export default api;
