import { Button } from '@/components/ui/button';
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '@/components/ui/form';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { CheckCircle, Circle } from 'lucide-react';
import { useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { z } from 'zod';
import type { FormChooseServiceV3Props } from './FormChooseServiceV3Props.types';
import formChooseServiceSchema from '@/schemas/form-choose-service';
import type { TServices } from '@/services/services';
import { useRootStore } from '@/stores/root-store';
import { zodResolver } from '@hookform/resolvers/zod';
import { currencyFormat } from '@/utils/utils';

type TForm = z.input<ReturnType<typeof formChooseServiceSchema>>;

const FormChooseServiceV3: React.FC<FormChooseServiceV3Props> = ({
  services,
  organization
}) => {
  // i18n text
  const { t } = useTranslation();

  const continueRef = useRef<null | HTMLDivElement>(null);
  const [seeMore, setSeeMore] = useState<string[]>([]);

  const SelectedServiceRef = useRef<null | TServices>(null);
  const minParticipants = SelectedServiceRef.current?.minConsumers || 1;
  const maxParticipants = SelectedServiceRef.current?.maxConsumers || 4;

  //store
  const addStep = useRootStore((store) => store.addStep);
  const currentStep = useRootStore((store) => store.currentStep);
  const loadStepServicesData = useRootStore(
    (store) => store.loadStepServicesData
  );
  const stepServicesData = useRootStore((store) => store.stepServicesData);

  // Initializing useForm hook with the schema and default values
  const form = useForm<TForm>({
    resolver: zodResolver(
      formChooseServiceSchema({ minParticipants, maxParticipants })
    ),
    defaultValues: {
      service: stepServicesData?.service,
      participants: stepServicesData?.participants
        ? stepServicesData?.participants
        : 1
    }
  });

  // get price and participant from form with listeners
  const price = useWatch({ control: form.control, name: 'service' })?.price;

  const participants = useWatch({
    control: form.control,
    name: 'participants'
  });

  const selectedService = form.getValues().service;
  const total = price * Number(participants);
  const recervationPct = selectedService?.reservationPct;
  const deposit = total * (recervationPct / 100);

  async function onSubmit(data: TForm) {
    const service = data.service;
    const participants = Number(data.participants);
    loadStepServicesData({
      service,
      participants
    });

    addStep(currentStep);
  }

  const findCategoryWithServiceSelected = services.find((category) =>
    category.services.find((service) => service.id === selectedService?.id)
  );

  return (
    <Form {...form}>
      <h2 className="font-bold mb-6">{t('newBooking.stepServiceTitle')}</h2>
      <form onSubmit={form.handleSubmit(onSubmit)} className={'space-y-8'}>
        <Tabs
          defaultValue={
            findCategoryWithServiceSelected?.sport?.id || services[0].sport.id
          }
        >
          <ScrollArea>
            <div className="w-full relative h-16 ">
              <TabsList className="flex absolute h-full ">
                {services.map((category) => (
                  <TabsTrigger
                    key={category.sport.id}
                    value={category.sport.id}
                    className="h-full min-w-40"
                  >
                    {category.sport.name}
                  </TabsTrigger>
                ))}
              </TabsList>
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
          {services.map((category) => (
            <TabsContent
              key={category.sport.id}
              value={category.sport.id}
              className="grid grid-cols-1 gap-3 lg:grid-cols-2 items-stretch"
            >
              {category.services.map((service) => (
                <FormField
                  key={service.id}
                  control={form.control}
                  name="service"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Card
                          onClick={() => {
                            field.onChange(service);
                            SelectedServiceRef.current = service;
                            form.setValue('participants', service.minConsumers);

                            setTimeout(() => {
                              if (continueRef.current) {
                                continueRef.current.scrollIntoView({
                                  behavior: 'smooth'
                                });
                              }
                            }, 0);
                          }}
                          className={`flex items-start h-full ${
                            field.value?.id === service?.id
                              ? 'border-primary border-2'
                              : ''
                          }`}
                        >
                          <div className="flex items-center p-6 pr-0">
                            {field.value?.id === service?.id ? (
                              <CheckCircle
                                width={20}
                                className="text-primary"
                              />
                            ) : (
                              <Circle className="text-zinc-400" width={20} />
                            )}
                          </div>
                          <div>
                            <CardHeader>
                              <CardTitle className="flex items-start justify-between mb-1">
                                <span className="text-xl">{service.name}</span>
                                <span className="ml-5 text-xl">
                                  {currencyFormat(
                                    service.price,
                                    organization.currency
                                  )}
                                </span>
                              </CardTitle>
                              <CardDescription className="flex flex-col items-end">
                                <span
                                  className={`${
                                    seeMore.includes(service.id)
                                      ? 'line-clamp-none'
                                      : 'line-clamp-3'
                                  }`}
                                >
                                  {service.description}
                                </span>
                                {!seeMore.includes(service.id) && (
                                  <Button
                                    className="pr-0 pl-9 pt-7"
                                    variant={'link'}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSeeMore((prev) =>
                                        prev.concat([service.id])
                                      );
                                    }}
                                  >
                                    {t('newBooking.seeMore')}
                                  </Button>
                                )}
                              </CardDescription>
                            </CardHeader>
                          </div>
                        </Card>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}
            </TabsContent>
          ))}
        </Tabs>

        <FormField
          control={form.control}
          name="participants"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div
                  ref={continueRef}
                  className={`w-full bg-accent rounded p-6 ${
                    selectedService?.name ? 'block' : 'hidden'
                  }`}
                >
                  <h2 className="font-bold mb-1">
                    {t('newBooking.selectedService')}
                  </h2>
                  <p>
                    {selectedService?.name}
                    <span className="whitespace-nowrap ml-3 font-semibold">
                      {currencyFormat(
                        selectedService?.price,
                        organization.currency
                      )}
                    </span>
                  </p>
                  <h2 className="font-bold mt-6">
                    {t('newBooking.selectParticipants')}
                  </h2>
                  <div className="flex items-center gap-2 mt-2">
                    <Button
                      className="hover:bg-white"
                      type="button"
                      variant={'outline'}
                      disabled={field.value <= selectedService?.minConsumers}
                      onClick={() => field.onChange(field.value - 1)}
                    >
                      -
                    </Button>
                    <p>{field.value}</p>
                    <Button
                      className="hover:bg-white"
                      type="button"
                      disabled={field.value >= selectedService?.maxConsumers}
                      onClick={() => field.onChange(field.value + 1)}
                      variant={'outline'}
                    >
                      +
                    </Button>
                    <p>
                      {t('newBooking.participants.participantv3', {
                        count: Number(form.getValues()?.participants)
                      })}
                    </p>
                  </div>
                  <FormMessage />
                  <div className="w-full bg-white rounded p-4 mt-6 space-y-2">
                    <div className="flex justify-between">
                      <p className="font-semibold">{t('newBooking.total')}</p>
                      <p>
                        {currencyFormat(
                          form.control._formValues?.service?.price *
                            Number(form.control._formValues?.participants),
                          organization.currency
                        )}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="font-semibold">{t('newBooking.deposit')}</p>
                      <p>{currencyFormat(deposit, organization.currency)}</p>
                    </div>
                  </div>

                  <Button type="submit" className="w-full mt-9">
                    {t('newBooking.continue')}
                  </Button>
                </div>
              </FormControl>
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
};

export default FormChooseServiceV3;
