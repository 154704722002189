import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip';
import {
  ArrowLeft,
  ArrowRight,
  BarChart,
  Box,
  CalendarClock,
  CalendarDaysIcon,
  DollarSign,
  FileCheck2,
  HandHeart,
  LayoutGrid,
  PersonStanding,
  Settings,
  UserRoundPlus,
  UsersIcon,
  Zap
} from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { TMenuItem } from './sidebarV2.types';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';

type ItemLinkProps = {
  item: TMenuItem;
  menuOpen: boolean;
  isLinkActive: boolean;
  isAcordionTitle?: boolean;
};

const ItemLink = ({
  item,
  menuOpen,
  isLinkActive,
  isAcordionTitle = false
}: ItemLinkProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        {isAcordionTitle ? (
          <TooltipTrigger asChild>
            <div
              className={`flex align-middle hover:text-primary px-7 py-3  ${isLinkActive ? 'text-primary' : ''}`}
            >
              {<item.Icon className={'flex-shrink-0'} />}
              {menuOpen ? (
                <h3 className="ml-3 text-start ">{item.title}</h3>
              ) : null}
            </div>
          </TooltipTrigger>
        ) : (
          <TooltipTrigger>
            <Link to={item.link}>
              <li
                className={`flex align-middle hover:text-primary px-7 py-3  ${isLinkActive ? 'text-primary' : ''}`}
              >
                {<item.Icon className={'flex-shrink-0'} />}
                {menuOpen ? (
                  <h3 className="ml-3 text-start ">{item.title}</h3>
                ) : null}
              </li>
            </Link>
          </TooltipTrigger>
        )}

        <TooltipContent side="left">
          <p>{item.title}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const SidebarV2 = () => {
  const { data: organization } = useGetOrganization();
  const [menuOpen, setMenuOpen] = useState(true);
  const location = useLocation();
  const { t } = useTranslation();

  const defaultView: { [key: string]: boolean } = {
    dashboard: true,
    quickActions: true,
    users: true,
    calendar: true,
    services: true,
    inventory: true,
    payments: true,
    reservations: true,
    sales: true,
    settings: true,
    newBooking: true,
    categories: true,
    activity: false
  };

  const viewsPerOrg = process.env.VIEWS_PER_ORG
    ? JSON.parse(process.env.VIEWS_PER_ORG)
    : {};

  const isActive = (value: string) => {
    const { code } = organization || { code: '' };

    if (viewsPerOrg[code] === undefined) {
      return defaultView[value];
    }

    return viewsPerOrg[code][value];
  };

  const MENU_ITEMS: TMenuItem[] = [
    {
      Icon: Zap,
      title: t('sidebar.quickActions'),
      link: '/quick-actions',
      active: isActive('quickActions')
    },
    {
      Icon: BarChart,
      title: t('sidebar.dashboard'),
      link: '/dashboard',
      active: isActive('dashboard')
    },
    {
      Icon: UserRoundPlus,
      title: t('sidebar.newBooking'),
      link: '/new-booking',
      active: isActive('newBooking')
    },
    {
      Icon: FileCheck2,
      title: t('sidebar.sales'),
      link: '/services-sold',
      active: isActive('sales')
    },
    {
      Icon: PersonStanding,
      title: t('sidebar.activity'),
      link: '/activity',
      active: isActive('activity')
    },
    {
      Icon: CalendarClock,
      title: t('sidebar.reservations'),
      link: '/reservations',
      active: isActive('reservations')
    },
    {
      Icon: UsersIcon,
      title: t('sidebar.users'),
      link: '/users',
      active: isActive('users')
    },
    {
      Icon: DollarSign,
      title: t('sidebar.payments'),
      link: '/payments',
      active: isActive('payments')
    },

    {
      Icon: CalendarDaysIcon,
      title: t('sidebar.calendar'),
      link: '/calendar',
      active: isActive('calendar')
    },
    {
      Icon: HandHeart,
      title: t('sidebar.services'),
      link: '/services',
      active: isActive('services'),
      sub: [
        {
          Icon: HandHeart,
          title: t('sidebar.services'),
          link: '/services',
          active: isActive('services')
        },
        {
          Icon: LayoutGrid,
          title: t('sidebar.categories'),
          link: '/services/categories',
          active: isActive('servicesCategories')
        }
      ]
    },
    {
      Icon: Box,
      title: t('sidebar.inventory'),
      link: '/inventory',
      active: isActive('inventory'),
      sub: [
        {
          Icon: Box,
          title: t('sidebar.inventory'),
          link: '/inventory',
          active: isActive('inventory')
        },
        {
          Icon: LayoutGrid,
          title: t('sidebar.categories'),
          link: '/inventory/categories',
          active: isActive('inventoryCategories')
        }
      ]
    },
    {
      Icon: Settings,
      title: t('sidebar.settings'),
      link: '/settings',
      active: isActive('settings')
    }
  ];

  return (
    <div
      className="flex flex-col justify-between h-full overflow-auto shadow-md flex-grow-1 max-w-[230px]"
      style={{ scrollbarWidth: 'none' }}
    >
      <ul className="flex flex-col py-5 ">
        {MENU_ITEMS.filter((item) => item.active).map((item) => {
          const isLinkActive =
            '/' + location.pathname.split('/')[1] === item.link;
          const hasSubLinks = !!item.sub;
          if (hasSubLinks) {
            return (
              <Accordion key={item.title} type="multiple">
                <AccordionItem value={item.title}>
                  <AccordionTrigger className="p-0 pr-4 hover:no-underline">
                    <ItemLink
                      isLinkActive={isLinkActive}
                      menuOpen={menuOpen}
                      item={item}
                      isAcordionTitle
                    />
                  </AccordionTrigger>
                  <AccordionContent className="flex flex-col">
                    {item.sub?.map((subItem) => (
                      <div key={subItem.title} className="pl-2">
                        <ItemLink
                          isLinkActive={location.pathname === subItem.link}
                          menuOpen={menuOpen}
                          item={subItem}
                        />
                      </div>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            );
          }
          return (
            <ItemLink
              key={item.title}
              isLinkActive={isLinkActive}
              menuOpen={menuOpen}
              item={item}
            />
          );
        })}
      </ul>
      <Button
        onClick={() => setMenuOpen(!menuOpen)}
        variant={'ghost'}
        className="flex justify-end py-7"
      >
        {menuOpen ? <ArrowLeft /> : <ArrowRight />}
      </Button>
    </div>
  );
};
export default SidebarV2;
