import { Switch } from '@/components/ui/switch';
import { TServices } from '@/services/services';
import { currencyFormat } from '@/utils/utils';
import {
  CalendarDays,
  Check,
  CreditCard,
  DollarSign,
  Edit3,
  EditIcon,
  Eye,
  Percent,
  PowerCircleIcon,
  Puzzle,
  Users,
  X
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TableTooltip from '../../tooltip/table-tooltip/TableTooltip';

const ServiceCard: React.FC<{
  service: TServices;
  onSwitchService: () => void;
  isPendingSwitch: boolean;
  disabledSwitch: boolean;
}> = ({ service, onSwitchService, isPendingSwitch, disabledSwitch }) => {
  const { t } = useTranslation();
  const {
    name,
    price,
    organization,
    reservationPct,
    isExtension,
    visibility,
    allowPayment,
    canUpdateReservations,
    enabled,
    description,
    minConsumers,
    maxConsumers
  } = service;

  const items = [
    {
      title: t('common.price'),
      value: currencyFormat(price, organization.currency),
      icon: DollarSign
    },
    {
      title: t('common.reservePercentage'),
      value: reservationPct,
      icon: Percent
    },
    {
      tooltip: () => (
        <TableTooltip
          text={t('services.visibility')}
          className="font-semibold"
          content={t('services.visibilityDescription')}
        />
      ),
      title: t('common.visibility'),
      value: t(`common.${visibility}`),
      icon: Eye
    },
    {
      title: t('services.minConsumers'),
      value: minConsumers,
      icon: Users
    },
    {
      title: t('services.maxConsumers'),
      value: maxConsumers,
      icon: Users
    },
    {
      title: t('services.minAge'),
      value: service.minAge || t('services.noDefined'),
      icon: CalendarDays
    },
    {
      title: t('services.maxAge'),
      value: service.maxAge || t('services.noDefined'),
      icon: CalendarDays
    },
    {
      tooltip: () => (
        <TableTooltip
          text={t('common.allowPayment')}
          className="font-semibold"
          content={t('services.allowPaymentDescription')}
        />
      ),
      title: t('common.allowPayment'),
      value: t(`common.${allowPayment}`),
      icon: CreditCard
    },
    {
      tooltip: () => (
        <TableTooltip
          text={t('common.canUpdateReservations')}
          className="font-semibold"
          content={t('services.canUpdateReservationsDescription')}
        />
      ),
      title: t('common.canUpdateReservations'),
      value: t(`common.${canUpdateReservations}`),
      icon: Edit3
    },
    {
      title: t('common.isExtension'),
      value: isExtension ? <Check color="#10B981" /> : <X color="#EF4444" />,
      icon: Puzzle
    }
  ];

  return (
    <div className="p-6 rounded-md bg-accent">
      <Link to={`./edit`}>
        <div className="flex items-start justify-between ">
          <h1 className="font-bold text-md text-primary">{name}</h1>
          <EditIcon className="cursor-pointer" />
        </div>
      </Link>
      <p className="py-4">{description}</p>
      <div className="items-baseline grid-cols-2 gap-5 p-6 space-y-4 bg-white rounded-md md:grid auto-rows-auto md:space-y-0 lg:grid-cols-3 lg:items-end ">
        {items.map((item, index) => (
          <div key={`${item.title}-${index}`}>
            <div className="flex items-center">
              {item.icon ? <item.icon className="w-4 h-4 mr-2" /> : null}
              {item.tooltip ? (
                item.tooltip()
              ) : (
                <p className="font-semibold">{item.title}</p>
              )}
            </div>
            <span className="flex ml-7">{item.value}</span>
          </div>
        ))}
      </div>
      <div className="p-6 mt-3 bg-white rounded-md">
        <div className="flex items-center justify-between ">
          <div className="flex items-center">
            <PowerCircleIcon className="w-4 h-4 mr-2" />
            <p className="font-semibold">{t('common.enabled')}</p>
          </div>
          <Switch
            className="ml-2"
            checked={isPendingSwitch ? !enabled : enabled}
            onCheckedChange={onSwitchService}
            disabled={disabledSwitch || isPendingSwitch}
            id="airplane-mode"
          />
        </div>
        <p className="mt-3 text-sm">{t('serviceDetail.enabledDescription')}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
