import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import FormInventoryCategory, {
    InventoryCategoryValues
} from '@/components/forms/form-inventory-category/FormInventoryCategory';
import { Skeleton } from '@/components/ui/skeleton';
import usePostEquipmentTypes from '@/hooks/queries/equipments-api/usePostEquipmentTypes';
import useGetOrganizationSports from '@/hooks/queries/sports-api/useGetOrganizationSports';

const CreateInventoryCategory = () => {
  const { mutate, isPending } = usePostEquipmentTypes();
  const { data: sports, isLoading, isError } = useGetOrganizationSports();

  const onSubmit = (data: InventoryCategoryValues) => {
    mutate({ ...data, sports: data.sports?.map(sport=> sport.value) });
  };

  if (isLoading) {
    return <Skeleton className="w-full h-2/3" />;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  if (!sports?.length) {
    return <NoData />;
  }

  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <FormInventoryCategory onSubmit={onSubmit} isPending={isPending} sports={sports} />
      </div>
    </>
  );
};

export default CreateInventoryCategory;
