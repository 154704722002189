import { TConsumer } from "@/services/consumer";
import { TIdentificationType } from "@/services/identification";
import { SortingFn } from "@tanstack/react-table";
import { format } from "date-fns";
import { download, generateCsv } from 'export-to-csv';
import { csvConfig } from "./utils";

const config = csvConfig({ filename: 'users' });

export const exportConsumerCSV = (
    data: TConsumer[],
    allIdentificationTypes: TIdentificationType[] | undefined
  ) => {
    if (!allIdentificationTypes) return;
    const values = data.map((value) => {
      return {
        id: value.id ?? '',
        create_at: value.createdAt ? format(value.createdAt, 'dd/M/y H:mm') : '',
        user_type: value.isOwnUser ? 'Consumer' : 'User',
        related_user: value.isOwnUser
          ? `${value.user.firstName} ${value.user.lastName}`
          : '',
        emergency_full_name: value.emergencyContact?.fullName ?? '',
        emergency_phone: value.emergencyContact?.phone ?? '',
        first_name: value.firstName ?? '',
        last_name: value.lastName ?? '',
        birthdate: value.birthdate ? format(value.birthdate, 'dd/M/y') : '',
        email: value.email ?? '',
        phone: value.phone ?? '',
        identification: value.identification?.number
          ? value.identification?.number
          : '',
        identification_type: value.identification?.type
          ? allIdentificationTypes?.find(
              (identification) => identification.id === value.identification?.type
            )?.name
          : '',
        location: value.location ?? '',
        address: value.address ?? '',
        postal_code: value.postalCode ?? '',
        weight: value.weight ?? '',
        height: value.height ?? ''
      };
    });
    const csv = generateCsv(config)(values);
    download(config)(csv);
  };

export const sortBirthdateFn: SortingFn<TConsumer> = (rowA, rowB, _columnId) => {
    const statusA = rowA.original.birthdate
      ? new Date(rowA.original.birthdate).getTime()
      : 0;
    const statusB = rowB.original.birthdate
      ? new Date(rowB.original.birthdate).getTime()
      : 0;
    return statusA - statusB;
  };
  
  export const sortUserFn: SortingFn<TConsumer> = (rowA, rowB, _columnId) => {
    const statusA = rowA.original?.active;
    const statusB = rowB.original?.active;
    if (statusA && !statusB) return -1;
    else if (!statusA && statusB) return 1;
    else return 0;
  };
  
  export const sortNameFn: SortingFn<TConsumer> = (rowA, rowB, _columnId) => {
    const firstNameA = rowA.original?.firstName || '';
    const firstNameB = rowB.original?.firstName || '';
    return firstNameA.localeCompare(firstNameB);
  };