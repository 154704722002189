import { ErrorBoundary } from '@rollbar/react';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren;

export const ErrorBoundaryRollbar = ({ children }: Props) => {
  return <ErrorBoundary fallbackUI={FallbackUI}>{children}</ErrorBoundary>;
};

const FallbackUI = () => <div>Oops, something went wrong</div>;
