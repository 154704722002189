import usePatchReservation from './queries/reservations-api/usePatchReservation';

const useReservationActions = (
) => {
  const { mutate, isPending } = usePatchReservation();

  const handleAbsent = (reservationId: string) => {
    mutate({ status: 'absent', reservationId: reservationId });
  };
  const handleCompleted = (reservationId: string) => {
    mutate({ status: 'complete', reservationId: reservationId });
  };
  const handleAccept = (reservationId: string) => {
    mutate({ status: 'accept', reservationId: reservationId });
  };
  const handleReject = (reservationId: string) => {
    mutate({ status: 'reject', reservationId: reservationId });
  };
  const handleClose = (reservationId: string) => {
    mutate({ status: 'cancel', reservationId: reservationId });
  };
  const handlePropose = (date: number, reservationId: string) => {
    mutate({
      status: 'propose',
      reservationId: reservationId,
      data: { date }
    });
  };
  return {
    handleAbsent,
    handleCompleted,
    handleAccept,
    handleReject,
    handleClose,
    handlePropose,
    isPending
  };
};

export default useReservationActions;
