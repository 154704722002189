import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import usePostEquipmentUnassign from '@/hooks/queries/equipments-api/usePostEquipmentUnassign';
import useReservationActions from '@/hooks/useReservationActions';
import {
  ReservationStatus,
  TReservationStatus,
  isBeforeToday
} from '@/utils/reservations';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../../modals/alert-modal/AlertModal';
import { MaterialModal } from '../../modals/material-modal/MaterialModal';
import ReprogrameDateModal from '../../modals/reprograme-date-modal/ReprogrameDateModal';
import {
  ReservationCardProps,
  TConsumerEquipment,
  TTextAndButtons
} from './reservationCard.types';

const ReservationCard: React.FC<ReservationCardProps> = ({
  reservation,
  service,
  isFetching
}) => {
  const navigate = useNavigate();
  const {
    handleAbsent,
    handleCompleted,
    handleAccept,
    handleReject,
    handleClose,
    handlePropose,
    isPending
  } = useReservationActions();

  const isLoading = isPending || isFetching;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showReprogrameModal, setShowReprogrameModal] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [consumerSelected, setConsumerSelected] = useState<{
    id: string;
    equipment: TConsumerEquipment[];
  } | null>(null);
  const isReservationBeforeToday = isBeforeToday(reservation.endTime);

  const isEquipmentAssigned = reservation.consumers.every(
    (consumer) => !!consumer.equipment.length
  );
  const { mutate, isPending: isPendingUnassign } = usePostEquipmentUnassign();
  const [pendingId, setPendingId] = useState('');

  const handleReschedule = () => {
    setShowReprogrameModal(true);
  };
  const openCloseAlertModal = () => {
    setShowCloseModal(true);
  };
  const handleCloseReservation = () => {
    setShowCloseModal(false);
    handleClose(reservation.id);
  };
  const handleProposeDate = (date: number) => {
    setShowReprogrameModal(false);
    handlePropose(date, reservation.id);
  };
  const handleUnassign = (equipmentsIds: string[], consumerId: string) => {
    setPendingId(consumerId);
    mutate({
      data: {
        equipment: equipmentsIds,
        consumer: consumerId,
        reservation: reservation.id
      }
    });
  };

  function getTextAndButtons(status: TReservationStatus) {
    let textAndButtons: TTextAndButtons = {
      text: t('common.status.pending'),
      buttons: [
        {
          text: t('common.reserve'),
          onClick: handleReschedule
        },
        {
          text: t('common.close'),
          onClick: openCloseAlertModal,
          variant: 'ghost'
        }
      ]
    };
    switch (status) {
      case ReservationStatus.Proposed:
        if (isReservationBeforeToday) {
          textAndButtons = {
            text: t('common.status.proposed'),
            buttons: [
              {
                text: t('common.reschedule'),
                onClick: handleReschedule
              },
              {
                text: t('common.reject'),
                onClick: () => handleReject(reservation.id),
                variant: 'destructive'
              },
              {
                text: t('common.status.completed'),
                onClick: () => handleCompleted(reservation.id),
                variant: 'ghost'
              },
              {
                text: t('common.close'),
                onClick: openCloseAlertModal,
                variant: 'ghost'
              }
            ]
          };
        } else {
          textAndButtons = {
            text: t('common.status.proposed'),
            buttons: [
              {
                text: t('common.reschedule'),
                onClick: handleReschedule
              },
              {
                text: t('common.reject'),
                onClick: () => handleReject(reservation.id),
                variant: 'destructive'
              },
              {
                text: t('common.close'),
                onClick: openCloseAlertModal,
                variant: 'ghost'
              }
            ]
          };
        }

        break;
      case ReservationStatus.Accepted:
        if (isReservationBeforeToday) {
          textAndButtons = {
            text: t('common.status.accepted'),
            buttons: [
              {
                text: t('common.status.completed'),
                onClick: () => handleCompleted(reservation.id)
              },
              {
                text: t('common.status.absent'),
                onClick: () => handleAbsent(reservation.id),
                variant: 'outline'
              }
            ]
          };
        } else {
          textAndButtons = {
            text: t('common.status.accepted'),
            buttons: [
              {
                text: t('common.reject'),
                onClick: () => handleReject(reservation.id),
                variant: 'destructive'
              },
              {
                text: t('common.close'),
                onClick: openCloseAlertModal,
                variant: 'ghost'
              }
            ]
          };
        }

        break;
      case ReservationStatus.Rejected:
        textAndButtons = {
          text: t('common.status.rejected'),
          buttons: [
            {
              text: t('common.reserve'),
              onClick: handleReschedule
            },
            {
              text: t('common.close'),
              onClick: openCloseAlertModal,
              variant: 'ghost'
            }
          ]
        };

        break;
      case ReservationStatus.Requested:
        if (isReservationBeforeToday) {
          textAndButtons = {
            text: t('common.status.requested'),
            buttons: [
              {
                text: t('common.reschedule'),
                onClick: handleReschedule
              },
              {
                text: t('common.reject'),
                onClick: () => handleReject(reservation.id),
                variant: 'destructive'
              },
              {
                text: t('common.status.completed'),
                onClick: () => handleCompleted(reservation.id),
                variant: 'ghost'
              },
              {
                text: t('common.close'),
                onClick: openCloseAlertModal,
                variant: 'ghost'
              }
            ]
          };
        } else {
          textAndButtons = {
            text: t('common.status.requested'),
            buttons: [
              {
                text: t('common.accept'),
                onClick: () => handleAccept(reservation.id),
                disabled: !isEquipmentAssigned
              },
              {
                text: t('common.reschedule'),
                onClick: handleReschedule
              },
              {
                text: t('common.reject'),
                onClick: () => handleReject(reservation.id),
                variant: 'destructive'
              },
              {
                text: t('common.close'),
                onClick: openCloseAlertModal,
                variant: 'ghost'
              }
            ]
          };
        }

        break;
      case ReservationStatus.Completed:
        textAndButtons = {
          text: t('common.status.completed'),
          buttons: [
            {
              text: t('common.status.absent'),
              onClick: () => handleAbsent(reservation.id)
            }
          ]
        };
        break;
      case ReservationStatus.Absent:
        textAndButtons = {
          text: t('common.status.absent'),
          buttons: [
            {
              text: t('common.status.completed'),
              onClick: () => handleCompleted(reservation.id)
            }
          ]
        };
        break;
      case ReservationStatus.Canceled:
        textAndButtons = { text: t('common.status.canceled'), buttons: [] };
        break;
      default:
        break;
    }
    return textAndButtons;
  }
  const { text, buttons } = getTextAndButtons(reservation.status);
  const cardItems = [
    { title: t('card.day'), value: reservation.day || '-' },
    { title: t('card.time'), value: reservation.time || '-' }
  ];

  return (
    <>
      <div className="flex flex-col justify-between p-5 rounded-md bg-accent md:min-w-[32rem] lg:min-w-[40rem]">
        <div className="flex flex-col items-start justify-between gap-3 mb-6 md:flex-row md:items-center">
          <Button
            variant={'link'}
            className="self-end h-auto pl-0 font-bold text-left whitespace-normal"
            onClick={() =>
              navigate(`/services-sold/${reservation.serviceSoldId}`)
            }
          >
            {reservation.title}
          </Button>
          <Badge
            variant={reservation.status}
            className="justify-center h-8 justify-self-end"
          >
            {text}
          </Badge>
        </div>
        <ul>
          {cardItems.map((item) => (
            <li key={item.title} className="flex justify-between mb-4">
              <h3 className="font-bold">{item.title}</h3>
              <p className="text-end">{item.value}</p>
            </li>
          ))}
        </ul>
        <div className="flex flex-col items-start mt-6 mb-4">
          <h3 className="font-bold ">{t('card.consumers')}</h3>
          {reservation.consumers.map((consumer) => (
            <div
              className="flex items-center justify-between w-full p-4 mt-6 border border-solid rounded-md border-border"
              key={consumer.id}
            >
              <div className="flex flex-col w-full gap-2">
                <p className="font-bold text-start">{consumer.name}</p>
                <p className="text-start">
                  {consumer.weight}kg - {consumer.height}cm
                </p>
                <h3 className="font-bold">{t('card.materialAssigned')}</h3>
                <div className="flex flex-col justify-between w-full">
                  {Array.isArray(consumer.equipment) &&
                  consumer.equipment.every(
                    (obj) => Object.keys(obj).length === 0
                  ) ? (
                    <p className="mt-2">{t('card.unassignedMaterial')}</p>
                  ) : (
                    consumer.equipment.map((equipment) => (
                      <>
                        {Object.keys(equipment).length !== 0 ? (
                          <div
                            key={equipment.id}
                            className="flex items-center justify-between"
                          >
                            <p>
                              #{equipment.code} {equipment.name}
                            </p>
                          </div>
                        ) : null}
                      </>
                    ))
                  )}
                </div>
                <div className="flex items-center gap-2 ">
                  {(reservation.status === ReservationStatus.Requested ||
                    reservation.status === ReservationStatus.Accepted ||
                    reservation.status === ReservationStatus.Proposed) &&
                    !isReservationBeforeToday && (
                      <Button
                        variant={'outline'}
                        className="w-1/2"
                        onClick={() => {
                          setConsumerSelected({
                            id: consumer.id,
                            equipment: consumer.equipment
                          });
                          setShowModal(true);
                        }}
                      >
                        {t('card.assignMaterial')}
                      </Button>
                    )}

                  <Button
                    variant="destructive"
                    onClick={() =>
                      handleUnassign(
                        consumer.equipment.map((e) => e.id),
                        consumer.id
                      )
                    }
                    className="w-1/2"
                    disabled={
                      (isPendingUnassign && pendingId === consumer.id) ||
                      consumer.equipment.length === 0
                    }
                  >
                    {isPendingUnassign && pendingId === consumer.id ? (
                      <Loader2 className="h-4 w-14 animate-spin" />
                    ) : (
                      t('card.unassign')
                    )}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {(reservation.status === ReservationStatus.Requested ||
          reservation.status === ReservationStatus.Proposed) &&
          isReservationBeforeToday && (
            <p className="text-xs text-gray-500">
              {t('card.warningExpiredDate')}
            </p>
          )}
        <div className="flex flex-col gap-4 mt-6">
          {buttons.map((button) =>
            isLoading ? (
              <Skeleton
                className="w-full h-10 bg-slate-300"
                key={button.text}
              />
            ) : (
              <Button
                key={button.text}
                variant={button.variant}
                onClick={button.onClick}
                disabled={button.disabled}
              >
                {button.text}
              </Button>
            )
          )}
        </div>
      </div>
      {showModal && consumerSelected ? (
        <MaterialModal
          open={showModal}
          onOpenChange={setShowModal}
          onSave={() => {
            setShowModal(false);
          }}
          reservation={reservation}
          consumer={{
            id: consumerSelected.id,
            equipment: consumerSelected.equipment
          }}
        />
      ) : null}
      {showReprogrameModal ? (
        <ReprogrameDateModal
          open={showReprogrameModal}
          onOpenChange={setShowReprogrameModal}
          onSave={(date: number) => handleProposeDate(date)}
          serviceId={service?.id}
          participants={reservation.consumers.length}
          startTime={Number(reservation.startTime)}
        />
      ) : null}
      {showCloseModal ? (
        <AlertModal
          open={showCloseModal}
          onOpenChange={setShowCloseModal}
          onAccept={handleCloseReservation}
          title={t('alert.closeReservation.title')}
          description={t('alert.closeReservation.description')}
          onCancel={() => setShowCloseModal(false)}
          cancelText={t('common.cancel')}
          acceptText={t('common.close')}
        />
      ) : null}
    </>
  );
};

export { ReservationCard };
