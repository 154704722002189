import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/components/ui/accordion';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { TOrganization } from '@/services/organization';
import { statusVariant } from '@/utils/sales';
import { capitalizeFirstLetter } from '@/utils/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AlertSection from '../../alert/AlertSection';
import { ServiceSold } from '../../tables/services-sold-table/services-sold-tables.types';
import { ServicesSoldPaymentsTableVP } from '../../tables/services-sold-payments-table-vp/ServicesSoldPaymentsTable';

const ServiceSoldCardVP = ({
  sale,
  handleAddPayment,
  handleCancelSale,
  organization
}: {
  sale: ServiceSold;
  handleAddPayment: () => void;
  handleCancelSale: () => void;
  organization: TOrganization;
}) => {
  const { t } = useTranslation();
  const saleCanceled = sale.status.name === 'canceled';

  return (
    <div className="flex flex-col justify-between min-w-full p-5 rounded-md bg-accent">
      <div className="flex items-start justify-between mb-6">
        <div>
          <h1 className="font-bold">{sale.service.name}</h1>
          <h3 className="mt-2 mb-1 text-sm font-bold">
            {t('tables.servicesSold.participants')}
          </h3>
          {sale.participants.map((consumer) => (
            <div className="flex flex-col gap-2" key={consumer.id}>
              <Link to={`/activity/students/${consumer.id}`}>
                <p className="mt-1 cursor-pointer text-start hover:underline">
                  {capitalizeFirstLetter(consumer.firstName)}{' '}
                  {capitalizeFirstLetter(consumer.lastName)}
                </p>
              </Link>
            </div>
          ))}
        </div>

        <Badge variant={statusVariant[sale.status.name]}>
          {t(`tables.servicesSold.${sale.status.name}`)}
        </Badge>
      </div>

      {saleCanceled ? (
        <AlertSection
          variant="error"
          text={t('alertSection.saleCanceled', {
            observation: sale.status.observation
          })}
        />
      ) : (
        <>
          <Accordion
            type="single"
            className="w-full px-4 mt-2 bg-white rounded-md"
            collapsible
            defaultValue="1"
          >
            <AccordionItem value={'1'}>
              <AccordionTrigger className="px-2">
                {t('tables.servicesSold.payments')}
              </AccordionTrigger>
              <AccordionContent className="flex flex-col">
                <ServicesSoldPaymentsTableVP
                  sale={sale}
                  organization={organization}
                />
              </AccordionContent>
            </AccordionItem>
          </Accordion>

          <div className="flex flex-col gap-4 mt-6">
            <Button onClick={handleAddPayment} disabled={!sale.amountPending}>
              {t('modals.addPayment.title')}
            </Button>
            <Button variant="destructive" onClick={handleCancelSale}>
              {t('modals.canceledServiceSold.title')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ServiceSoldCardVP;
