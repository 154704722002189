import { InventoryValues } from '@/components/forms/form-inventory/FormInventory';
import { GetEquipmentsProps } from '@/hooks/queries/equipments-api/useGetPaginatedEquipments';
import { createQueryParams } from '@/utils/utils';
import { ApiResponse } from 'apisauce';
import { format } from 'date-fns';
import { Api } from '../api';
import {
  EquipmentUnavailability,
  EquipmentUnavailabilityParams,
  IEquipimentAssignable,
  TEquipment,
  TEquipmentType,
  TGetEquipments,
  TGetEquipmentTypes,
  TPostEquipmentAssign
} from './equipments.types';

export type TPostPatchEquipmentType = {
  name: string;
  description?: string;
  sports: string[] | undefined;
};

type SlotStatus = string;

export interface TimeSlot {
  slot: string; //  "HH:mm-HH:mm"
  status: SlotStatus;
}

export type TequipmentSchedule = Record<string, TimeSlot[]>;

export class EquipmentsApi {
  private readonly api: Api;

  constructor(api: Api) {
    this.api = api;
  }
  async getEquipments(): Promise<TEquipment[] | undefined> {
    const response: ApiResponse<TGetEquipments> =
      await this.api.apisauce.get(`equipment`);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results;
  }
  async getEquipmentAssignable(
    reservationId: string
  ): Promise<IEquipimentAssignable[] | undefined> {
    const response: ApiResponse<IEquipimentAssignable[]> =
      await this.api.apisauce.get(
        `equipment/assign?reservation=${reservationId}`
      );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async postEquipmentAssign(body: {
    consumer: string;
    reservation: string;
  }): Promise<TPostEquipmentAssign | undefined> {
    const response: ApiResponse<TPostEquipmentAssign> =
      await this.api.apisauce.post(`equipment/assign`, body);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async postEquipmentUnassign(body: {
    equipment: string[];
    consumer: string;
    reservation: string;
  }): Promise<TPostEquipmentAssign | undefined> {
    const response: ApiResponse<TPostEquipmentAssign> =
      await this.api.apisauce.post(`equipment/unassign`, body);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async getEquipmentsAvailability(
    equipmentId: string
  ): Promise<TequipmentSchedule | undefined> {
    const response: ApiResponse<TequipmentSchedule | undefined> =
      await this.api.apisauce.get(`equipment/schedule/${equipmentId}`);
    if (!response.ok) {
      throw response.originalError;
    }
    return response?.data;
  }
  async postClosureEquipment(body: {
    blocks: TequipmentSchedule;
    equipment: string[];
  }): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      'block',
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response?.data;
  }
  async getEquipmentsUnavailability({
    startTime,
    endTime,
    id
  }: EquipmentUnavailabilityParams): Promise<any> {
    const queryParams = createQueryParams({ startTime, endTime, id });
    const response: ApiResponse<EquipmentUnavailability> =
      await this.api.apisauce.get(`equipment/unavailability?${queryParams}`);
    if (!response.ok) {
      throw response.originalError;
    }
    return response?.data;
  }
  async getEquipmentTypes(): Promise<TEquipmentType[] | undefined> {
    const response: ApiResponse<TGetEquipmentTypes> =
      await this.api.apisauce.get(`equipment-type`);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results || [];
  }

  async postEquipmentType(
    body: TPostPatchEquipmentType
  ): Promise<any | undefined> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `equipment-type`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async postEquipment(body: InventoryValues): Promise<any | undefined> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `equipment`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async patchEquipmentType(
    body: Partial<TPostPatchEquipmentType>,
    equipmentTypeId: string
  ): Promise<any | undefined> {
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `equipment-type/${equipmentTypeId}`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async patchEquipment(
    body: Partial<InventoryValues>,
    equipmentId: string
  ): Promise<any | undefined> {
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `equipment/${equipmentId}`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async deleteEquipmentType(equipmentTypeId: string): Promise<any | undefined> {
    const response: ApiResponse<any> = await this.api.apisauce.delete(
      `equipment-type/${equipmentTypeId}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async deleteEquipment(equipmentId: string): Promise<any | undefined> {
    const response: ApiResponse<any> = await this.api.apisauce.delete(
      `equipment/${equipmentId}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getPaginatedEquipments({
    pagination,
    sorting,
    filtering,
    columnFilters
  }: GetEquipmentsProps): Promise<TGetEquipments | undefined> {
    //set paignation
    const page = pagination.pageIndex + 1;
    const limit = pagination.pageSize;

    // set sorting
    let sortBy = '';
    let sortDirection = '';

    //set columnFilters
    let columnFilterURI = '';

    columnFilters.forEach((filter) => {
      const valuesOfFilter = filter.value;
      let valueQueryString: string = '';

      if (Array.isArray(valuesOfFilter)) {
        valueQueryString = `&filter.${filter.id}=string.in.${valuesOfFilter.join(',')}`;
      } else {
        const dateRange = valuesOfFilter as {
          from: string;
          to: Date | undefined;
        };
        if (!dateRange.to) {
          const date = new Date(dateRange.from);
          // Set the time to the last hour of the day (23:00:00)
          date.setHours(23, 0, 0, 0);
          dateRange.to = date;
        }
        if (dateRange.from && dateRange.to) {
          valueQueryString = `&filter.createdAt=date.between.${format(dateRange.from, "yyyy-MM-dd'T'HH:mm:ss")},${format(dateRange.to, "yyyy-MM-dd'T'HH:mm:ss")}`;
        }
      }

      columnFilterURI += valueQueryString;
    });

    for (let i = 0; i < sorting.length; i++) {
      const id = sorting[i].id,
        direction = sorting[i].desc ? 'DESC' : 'ASC';
      sortBy += id;
      sortDirection += direction;

      if (i !== sorting.length - 1) {
        sortBy += ',';
        sortDirection += ',';
      }
    }

    let URI = `equipment?limit=${limit}&page=${page}`;
    if (filtering) {
      const filteringWithoutDot = filtering.replace('.', ''); //we remove [.] to avoid 500 error. Needs to be reviewd if we search for emails
      URI += `&filter.or.code=string.like.${filteringWithoutDot}&filter.or.name=string.like.${filteringWithoutDot}&filter.or.equipmentType.name=string.like.${filteringWithoutDot}&filter.or.description=string.like.${filteringWithoutDot}&filter.or.id=string.like.${filteringWithoutDot}`;
    }
    if (sorting.length) {
      URI += `&sortBy=${sortBy}&sortDirection=${sortDirection}`;
    }
    if (columnFilters.length) {
      URI += columnFilterURI;
    }
    const response: ApiResponse<TGetEquipments> =
      await this.api.apisauce.get(URI);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
