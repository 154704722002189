import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

function useGetOrgCategoriesWIthServices(organizationId: string) {
  return useQuery({
    queryKey: ['categoriesWithServices', organizationId],
    queryFn: () =>
      api.services.getOrganizationCategoriesWithServices(organizationId)
  });
}

export default useGetOrgCategoriesWIthServices;
