import api from '@/services/api';
import { skipToken, useQuery } from '@tanstack/react-query';

const useGetServiceDivisionByServiceId = (serviceId: string | undefined) => {
  return useQuery({
    queryKey: ['service-division', serviceId],
    queryFn: serviceId
      ? () => api.serviceDivision.getServiceDivisionByServiceId(serviceId)
      : skipToken
  });
};

export default useGetServiceDivisionByServiceId;
