import api from '@/services/api';
import { TBlockServiceSchedule } from '@/services/services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function usePostClouserServices() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['post-clouser-services'],
    mutationFn: ({
      closures,
      serviceId
    }: {
      closures: TBlockServiceSchedule;
      serviceId?: string;
    }) => {
      const body = serviceId
        ? { closures, services: [serviceId] }
        : { closures };
      return api.services.postClosureServices(body);
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: (response) => {
      if (response.serviceId) {
        queryClient.invalidateQueries({
          queryKey: ['services-active-dates', response.serviceId]
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['services-active-dates']
        });
      }
      toast.success(t('toast.availabilitySuccess'));
    },
    retry: false
  });
}

export default usePostClouserServices;
