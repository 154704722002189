import UsersTable from '@/components/common/tables/users-table/UsersTable';

const Users = () => {
  return (
    <div className="w-full">
      <UsersTable />
    </div>
  );
};

export default Users;
