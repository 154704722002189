import { z } from 'zod';
import {
  birthdateSchema,
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  phoneSchema
} from './common/schemas';
import { minAges } from '@/utils/utils';

const formUserDetailSchema = () => {
  const minAge = minAges.paddle;
  return z.object({
    firstName: firstNameSchema
      .optional()
      .transform((value) => value || undefined),
    lastName: lastNameSchema
      .optional()
      .transform((value) => value || undefined),
    birthdate: birthdateSchema({ minAge: 18 })
      .optional()
      .transform((value) => value || undefined),
    identification: z
      .object({
        number: z.union([
          z.coerce.string().min(1, 'Ingresa un documento.'),
          z.literal('').transform((value) => value || undefined)
        ]),
        type: z.union([
          z.coerce.string().min(1, 'Selecciona un tipo de documento.'),
          z.literal('').transform((value) => value || undefined)
        ])
      })
      .optional(),
    address: z
      .string()
      .optional()
      .transform((value) => value || undefined),
    location: z
      .string()
      .optional()
      .transform((value) => value || undefined),
    email: z
      .union([z.literal(''), emailSchema])
      .transform((value) => value || undefined),
    phone: z
      .union([z.literal(''), phoneSchema])
      .transform((value) => value || undefined),
    postalCode: z
      .string({ required_error: 'Se requiere un código postal.' })
      .optional()
      .transform((value) => value || undefined),
    height: z.coerce
      .number()
      .optional()
      .transform((value) => value || undefined),
    weight: z.coerce
      .number()
      .optional()
      .transform((value) => value || undefined),
    contactOrigin: z
      .string()
      .optional()
      .transform((value) => value || undefined),
    emergencyContact: z
      .object({
        fullName: z
          .string()
          .optional()
          .transform((value) => value || undefined),
        phone: z
          .union([z.literal(''), phoneSchema])
          .transform((value) => value || undefined)
      })
      .transform((value) => (value.fullName || value.phone ? value : undefined))
  });
};

export default formUserDetailSchema;
