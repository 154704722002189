import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetReservation = (reservationId: string) => {
  return useQuery({
    queryKey: ['reservation', reservationId],
    queryFn: () => api.reservations.getReservationById(reservationId)
  });
};

export default useGetReservation;
