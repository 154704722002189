import GoBack from '@/components/common/go-back/GoBack';
import FormServicesCategory, {
  ServicesCategoryValues
} from '@/components/forms/form-services-category/FormServicesCategory';
import usePatchSports from '@/hooks/queries/sports-api/usePatchSports';
import { getChangedValues } from '@/utils/forms';
import { useLocation } from 'react-router-dom';

const EditServicesCategory = () => {
  const { search } = useLocation();
  const data = new URLSearchParams(search).get('category');

  const parsedServicesType = typeof data === 'string' ? JSON.parse(data) : null;
  const sportId: string = parsedServicesType?.id ?? '';

  const defaultValues = {
    name: parsedServicesType?.name ?? '',
    description: parsedServicesType?.description ?? ''
  };
  const { mutate, isPending } = usePatchSports();

  const onSubmit = (data: ServicesCategoryValues) => {
    const onlyChangedData = getChangedValues<Partial<ServicesCategoryValues>>(
      defaultValues,
      data
    );
    mutate({
      payload: onlyChangedData,
      sportId
    });
  };

  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <FormServicesCategory
          onSubmit={onSubmit}
          isPending={isPending}
          defaultValues={defaultValues}
          isEdit={true}
          sportId={sportId}
        />
      </div>
    </>
  );
};

export default EditServicesCategory;
