import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/ui/checkbox';
import { Eye } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from '@/components/ui/drawer';
import { differenceInYears } from 'date-fns';
import { getAgeRange } from '@/lib/utils';
import type { ReservationInterface } from '../tables/tables.types';
import { useEffect, useState } from 'react';
import type { TReservationStatus } from '@/utils/reservations';
import useReservationActions from '@/hooks/useReservationActions';

interface StudentInterface {
  students: ReservationInterface['consumers'];
  status: TReservationStatus;
  reservationId: string;
}

const StudentAttendenceRow = ({
  students,
  status,
  reservationId
}: StudentInterface) => {
  const { t } = useTranslation();
  const { handleAbsent, handleCompleted } = useReservationActions();

  const handlePresent = () => {
    if (status === 'Completed') {
      handleAbsent(reservationId);
    } else {
      handleCompleted(reservationId);
    }
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const studentData =
    Array.isArray(students) && students.length > 0 ? students[0] : null;

  if (!studentData) {
    return <p>{t('noStudentData')}</p>;
  }

  const name = `${studentData.firstName} ${studentData.lastName}`;
  const age = differenceInYears(new Date(), new Date(studentData.birthdate));

  const details = [
    {
      label: t('activity.student.age'),
      value: age
    },
    {
      label: t('activity.student.group'),
      value: getAgeRange(new Date(studentData?.birthdate))
    },
    {
      label: t('levelLabel'),
      value: studentData.additionalData?.experience
    },
    {
      label: t('form.tShirtSize'),
      value: studentData.additionalData?.size
    },
    {
      label: t('authorizedToPickUp'),
      value: `${studentData.additionalData?.authorizedToPickUp?.firstName} ${studentData.additionalData?.authorizedToPickUp?.lastName}`
    },
    {
      label: t('form.medicalCoverage'),
      value: studentData.additionalData?.insurance
    },
    {
      label: t('responsible'),
      value: `${studentData.additionalData?.parents[0]?.firstName} ${studentData.additionalData?.parents[0]?.lastName} - ${studentData.additionalData?.parents[1]?.firstName} ${studentData.additionalData?.parents[1]?.lastName}`
    },
    {
      label: t('diseasesLabel'),
      values: studentData.additionalData?.diseases.filter(
        (disease: { answer: boolean }) => disease.answer
      )
    },
    {
      label: t('treatmentsLabel'),
      values: studentData.additionalData?.treatments.filter(
        (disease: { answer: boolean }) => disease.answer
      )
    },
    {
      label: t('form.holidayDate'),
      value: studentData.additionalData?.holidayDate
    },
    {
      label: t('isAluarWorker'),
      value: studentData.additionalData?.isAularWorker
        ? t('form.yes')
        : t('form.no')
    },
    {
      label: t('isPartner'),
      value: studentData.additionalData?.isPartner
        ? t('form.yes')
        : t('form.no')
    }
  ];

  return (
    <Drawer direction={isDesktop ? 'right' : 'bottom'}>
      <div className="flex justify-between gap-2 p-2 rounded-md bg-accent">
        <div className="flex items-center w-full space-x-3 overflow-hidden whitespace-nowrap text-ellipsis">
          <Checkbox
            onCheckedChange={handlePresent}
            id="1"
            className="w-5 h-5 "
            checked={status === 'Completed'}
          />
          <label htmlFor="1" className="font-medium truncate">
            {name}
          </label>
        </div>
        <div className="flex items-center gap-2">
          <span>😃</span>
          <DrawerTrigger asChild>
            <Button variant="ghost" className="p-0">
              <Eye />
            </Button>
          </DrawerTrigger>
        </div>
        <DrawerContent className="w-full max-w-[760px]">
          <div className="max-h-[80vh] lg:max-h-[100vh] overflow-auto no-scrollbar px-2">
            <DrawerHeader>
              <DrawerTitle>
                {studentData.lastName}, {studentData.firstName}
              </DrawerTitle>
            </DrawerHeader>
            {details.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-between p-2 border-b border-accent"
                >
                  <span className="font-bold">{item.label}</span>
                  <span>
                    {item.value
                      ? item.value
                      : item.values
                        ? item.values.map(
                            (
                              value: { question: string; answer: string },
                              index: number
                            ) => {
                              return (
                                <div key={index} className="py-2 flex flex-col">
                                  <span className="font-bold">
                                    {value.question}
                                  </span>
                                  <span>{value.answer}</span>
                                </div>
                              );
                            }
                          )
                        : '-'}
                  </span>
                </div>
              );
            })}
            <DrawerFooter>
              <DrawerClose>
                <Button variant="outline" className="w-full">
                  {t('modals.alert.close')}
                </Button>
              </DrawerClose>
            </DrawerFooter>
          </div>
        </DrawerContent>
      </div>
    </Drawer>
  );
};

export default StudentAttendenceRow;
