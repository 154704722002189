import StudentAttendanceDays from '@/components/common/student-attendance/StudentAttendanceDays';
import { TForm } from '@/components/forms/form-participant-data-vp/FormParticipantDataVp.types';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Form } from '@/components/ui/form';
import formParticipantDataVpSchema from '@/schemas/formParticipantDataVp';
import { TConsumer } from '@/services/consumer';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ParentsForm from './components/ParentsForm';
import Questionsform from './components/QuestionsForm';
import UserForm from './components/UserForm';
import AuthorizedToPickUpForm from './components/AuthorizedToPickUpForm';

const FormStudents: React.FC<{
  consumer: TConsumer;
  onSubmit: (data: TForm) => void;
  isSending: boolean;
}> = ({ consumer, onSubmit, isSending }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const defaultValues = {
    user: {
      firstName: consumer?.firstName || '',
      lastName: consumer?.lastName || '',
      identification: {
        number: consumer?.identification?.number || '',
        type: consumer?.identificationType?.id || ''
      },
      birthdate: consumer?.birthdate ? new Date(consumer.birthdate) : undefined,
      location: consumer?.location || '',
      phone: consumer?.phone || '',
      insurance: consumer?.additionalData?.insurance || '',
      size: consumer?.additionalData?.size || '',
      age: consumer?.additionalData?.age || '',
      holidayDate: consumer?.additionalData?.holidayDate || '',
      isExtension: consumer?.additionalData?.isExtension ?? undefined,
      isAularWorker: consumer?.additionalData?.isAularWorker ?? undefined,
      isPartner: consumer?.additionalData?.isPartner ?? undefined
    },
    parents:
      consumer?.additionalData?.parents ||
      Array.from({ length: 2 }, () => ({
        connection: '',
        identification: {
          number: '',
          type: ''
        },
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
      })),
    authorizedToPickUp: consumer?.additionalData?.authorizedToPickUp || {
      firstName: '',
      lastName: '',
      identification: {
        number: '',
        type: ''
      },
      phone: ''
    },
    experience: consumer?.additionalData?.experience || '',
    diseases: consumer?.additionalData?.diseases || [
      {
        question:
          '¿Tiene Alguna enfermedad que requiera periódicamente tratamiento o control médico?',
        value: undefined,
        answer: ''
      },
      {
        question: '¿Durante los últimos tres años fue internado alguna vez?',
        value: undefined,
        answer: ''
      },
      {
        question: '¿Tiene alguna alergia?',
        placeholder: t('allergyPlaceholder'),
        value: undefined,
        answer: ''
      }
    ],
    treatments: consumer?.additionalData?.treatments || [
      {
        question: '¿Recibe tratamiento médico?',
        value: undefined,
        answer: ''
      },
      {
        question: '¿Presenta alguna limitacion fisica?',
        value: undefined,
        answer: ''
      },
      {
        question: '¿Otros problemas de salud?',
        value: undefined,
        answer: ''
      }
    ]
  };

  const form = useForm<TForm>({
    resolver: zodResolver(formParticipantDataVpSchema({ minAge: 0 })),
    defaultValues
  });

  const hasChanged = !form.formState.isDirty;

  return (
    <Card className="w-full p-8 mb-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div>
            <h2 className="mb-6 text-xl font-semibold">
              {t('activity.student.personalData')}
            </h2>
            <UserForm form={form} />
          </div>

          <div>
            <h2 className="mt-10 text-xl font-semibold">{t('responsible')}</h2>
            <ParentsForm form={form} />
          </div>

          <div>
            <h2 className="mt-10 mb-6 text-xl font-semibold">
              {t('activity.student.authorizedPickup')}
            </h2>
            <AuthorizedToPickUpForm form={form} />
          </div>

          <div>
            <h2 className="mt-10 mb-6 text-xl font-semibold">
              {t('diseasesLabel')}
            </h2>
            <Questionsform form={form} formField={'diseases'} />
          </div>

          <div>
            <h2 className="mt-10 mb-6 text-xl font-semibold">
              {t('treatmentsLabel')}
            </h2>
            <Questionsform form={form} formField={'diseases'} />
          </div>

          <div className="flex justify-end gap-4">
            <Button type="button" variant="outline" asChild>
              <Link
                to={'..'}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                className="flex items-center gap-2 "
              >
                {t('common.cancel')}
              </Link>
            </Button>
            <Button disabled={isSending || hasChanged}>{t('form.save')}</Button>
          </div>
        </form>
      </Form>
      {/* Attendance Section */}
      <div>
        <h2 className="mb-4 text-xl font-semibold">
          {t('activity.student.attendance')}
        </h2>
        <StudentAttendanceDays consumerId={consumer.id} />
      </div>
    </Card>
  );
};

export default FormStudents;
