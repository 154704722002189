import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import addPaymentSchema from '@/schemas/add-payment';
import { paymentMethods } from '@/utils/payments';
import { capitalizeFirstLetter, currencyFormat } from '@/utils/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import CalendarModal from '../calendar-modal/CalendarModal';
import { AddPaymentModalT, TForm } from './add-payment-modal.types';
const AddPaymentModal = ({
  open,
  onOpenChange,
  onSave,
  sale,
  paymentSelected,
  loading,
  organization
}: AddPaymentModalT) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const form = useForm({
    resolver: zodResolver(
      addPaymentSchema(sale.amountPending, paymentSelected?.amount)
    ),
    defaultValues: {
      user: `${capitalizeFirstLetter(sale.user.firstName)} ${capitalizeFirstLetter(sale.user.lastName)}`,
      service: sale.service.name ?? '',
      amount: paymentSelected?.amount ? paymentSelected.amount : 0,
      paymentMethod: paymentSelected?.paymentType.id ?? '',
      amountPending: currencyFormat(sale.amountPending, organization.currency),
      total: '',
      comments: paymentSelected?.observations ?? '',
      date: paymentSelected?.paidAt
        ? new Date(Number(paymentSelected.paidAt))
        : new Date()
    }
  });
  const { t } = useTranslation();
  function onSubmit(values: z.infer<TForm>) {
    const formValues = {
      paymentType: values.paymentMethod,
      sale: sale.id,
      amount: Number(values.amount),
      observations: values.comments,
      paidAt: values.date.getTime()
    };

    onSave(formValues);
  }
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="overflow-y-scroll h-5/6">
        <DialogHeader>
          <DialogTitle>{t('modals.addPayment.title')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name={'user'}
              disabled
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>{t('modals.addPayment.user')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name={'service'}
              disabled
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>
                      {t('modals.addPayment.serviceContracted')}
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name={'amountPending'}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>
                      {t('modals.addPayment.amountPending')}
                    </FormLabel>
                    <FormControl>
                      <Input readOnly {...field} disabled />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name={'amount'}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>{t('modals.addPayment.amountPaid')}</FormLabel>
                    <FormControl>
                      <Input {...field} type="number" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => (
                <FormItem className="flex flex-col ">
                  <FormLabel>{t('tables.common.date')}</FormLabel>
                  <CalendarModal
                    value={field.value}
                    onAccept={field.onChange}
                    dropDownPicker
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="paymentMethod"
              render={({ field }) => (
                <FormItem className="pb-6">
                  <FormLabel>{t('modals.addPayment.paymentMethod')}</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {paymentMethods
                        .filter(
                          (payment) =>
                            payment.id != 'ef75aed8-40fb-489f-963c-11722aa12440'
                        )
                        .map((paymentMethod) => (
                          <SelectItem
                            value={paymentMethod.id}
                            key={paymentMethod.id}
                          >
                            {paymentMethod.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name={'comments'}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>{t('modals.addPayment.comments')}</FormLabel>
                    <FormControl>
                      <Textarea className="resize-none" {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <Button type="submit" className="w-full" disabled={loading}>
              {loading ? (
                <Loader className="w-4 h-4 mr-2 animate-spin" />
              ) : (
                t('common.save')
              )}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AddPaymentModal;
