import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import AlertModal from '@/components/common/modals/alert-modal/AlertModal';
import NoData from '@/components/common/no-data/NoData';
import ServiceDivisionForm from '@/components/forms/form-service-division/ServiceDivisionForm';
import { TForm } from '@/components/forms/form-service-division/ServiceDivisionForm.types';
import { Skeleton } from '@/components/ui/skeleton';
import useDeleteServiceDivision from '@/hooks/queries/service-division-api/useDeleteServiceDivision';
import useGetServiceDivisionById from '@/hooks/queries/service-division-api/useGetServiceDivisionById';
import usePatchServiceDivision from '@/hooks/queries/service-division-api/usePatchServiceDivision';
import useGetServiceById from '@/hooks/queries/services-api/useGetServiceById';
import { getChangedValues } from '@/utils/forms';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const EditServiceDivision = () => {
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState(false);
  const { divisionId, id: serviceId } = useParams();
  const {
    data: serviceDivision,
    isError,
    isLoading
  } = useGetServiceDivisionById(divisionId);
  const {
    data: service,
    isError: isErrorService,
    isLoading: isLoadingService
  } = useGetServiceById(serviceId);
  const { mutate, isPending } = usePatchServiceDivision();
  const { mutate: deleteServiceDivision, isPending: isPendingDelete } =
    useDeleteServiceDivision();

  if (isLoading || isLoadingService) {
    return (
      <>
        <GoBack />
        <Skeleton className="w-full h-[50vh]" />
      </>
    );
  }

  if (isError || isErrorService) {
    return (
      <>
        <GoBack />
        <NoData />
        <ErrorMessage />
      </>
    );
  }

  if (!serviceDivision || !service) {
    return (
      <>
        <GoBack />
        <NoData />
      </>
    );
  }

  const { name, description, quantity, duration, id } = serviceDivision;

  const defaultValues = {
    name,
    description: description || '',
    quantity,
    duration
  };

  const onSubmit = (data: TForm) => {
    const onlyChangedData = getChangedValues<Partial<TForm>>(
      defaultValues,
      data
    );
    mutate({
      payload: { ...onlyChangedData, service: service.id },
      divisionId: serviceDivision.id
    });
  };

  return (
    <>
      <GoBack />
      <div className="p-5 space-y-6 rounded-md bg-accent">
        <p className="font-bold text-md text-primary">
          {t('serviceDetail.updateServiceDivision')} - {service.name}
        </p>
        <ServiceDivisionForm
          isPending={isPending}
          onSubmit={onSubmit}
          isPendingDelete={isPendingDelete}
          onDelete={() => setDeleteModal(true)}
          defaultValues={{
            name,
            description: description || '',
            quantity,
            duration
          }}
        />
      </div>
      <AlertModal
        open={deleteModal}
        onOpenChange={setDeleteModal}
        title={t('modals.alert.title')}
        description={t('modals.serviceDivision.deleteDescription')}
        cancelText={t('common.cancel')}
        acceptText={t('form.delete')}
        onAccept={() => deleteServiceDivision(id)}
        buttonAcceptDestructive
      />
    </>
  );
};

export default EditServiceDivision;
