import { date, string, z } from 'zod';

const addPaymentSchema = (
  amountPending: number,
  amountRegistered?: number
) =>
  z
    .object({
      user: string().optional(),
      service: string().optional(),
      amount: z.coerce
        .number({ required_error: 'El importe es requerido.' })
        .min(1, 'El importe debe ser mayor que 0.'),
      paymentMethod: string({
        required_error: 'El método de pago es requerido.'
      }).min(1, 'El método de pago debe ser válido.'),
      amountPending: string(),
      total: string().optional(),
      comments: string().optional(),
      date: date({ required_error: 'La fecha es requerida.' })
    })
    .refine(
      (data) => {
        const amount = data.amount;
        let pending;
        if (amountRegistered) {
          pending = amountRegistered + amountPending;
        } else {
          pending = amountPending;
        }
        return amount <= pending;
      },
      {
        message: 'El total no puede superar el importe pendiente.',
        path: ['amount'] //Path of error
      }
    );

export default addPaymentSchema;
