import { TServiceDivision } from '@/services/servicesDivision';
import { AlignLeft, Clock, EditIcon } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ServiceDivisionCard: React.FC<{ data: TServiceDivision }> = ({
  data
}) => {
  const { t } = useTranslation();
  const { name, description, duration, quantity, id } = data;

  const items = [
    {
      title: t('serviceDetail.duration'),
      icon: Clock,
      value: `${duration} min`
    },
    {
      title: t('serviceDetail.quantity'),
      icon: AlignLeft,
      value: quantity
    }
  ];

  return (
    <div className="p-6 bg-accent rounded-md w-full md:w-[20rem]">
      <div className="flex items-start justify-between ">
        <h1 className="font-bold text-md text-primary">{name}</h1>
        <Link to={`./edit-division/${id}`}>
          <EditIcon className="cursor-pointer" />
        </Link>
      </div>
      <p className="py-4">{description || t('common.noDescription')}</p>
      <div className="p-6 space-y-4 bg-white rounded-md">
        {items.map((item, index) => (
          <div key={`${item.title}-${index}`}>
            <div className="flex items-center">
              {item.icon ? <item.icon className="w-4 h-4 mr-2" /> : null}
              <p className="font-semibold">{item.title}</p>
            </div>
            <p className="ml-7">{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceDivisionCard;
