import { Button } from '@/components/ui/button';
import { TServices } from '@/services/services';
import { getFeatureFlagValue } from '@/utils/utils';
import { Table } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { Loader, PlusIcon } from 'lucide-react';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TableColumnsToggle from '../../table-columns-toggle/TableColumnsToggle';
import TableSearchInput from '../common/table-search-input/TableSearchInput';

type TableToolBarProps = Readonly<{
  table: Table<TServices>;
  filtering: string;
  setFiltering: (value: string) => void;
  isFetching: boolean;
  disabled: boolean;
}>;

export default function TableToolBar({
  table,
  filtering,
  setFiltering,
  isFetching,
  disabled
}: TableToolBarProps) {
  const { t } = useTranslation();
  const [search, setSearch] = useState(filtering || '');
  const navigate = useNavigate();

  const featureFlagCrudValue = getFeatureFlagValue('crud');

  const debounceFn = useCallback(
    debounce((value) => {
      setFiltering(value);
    }, 750),
    []
  );

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debounceFn(event.target.value);
  };

  return (
    <div
      className={`flex items-center gap-2 py-4 ${featureFlagCrudValue ? 'justify-between' : 'justify-end'}`}
    >
      {featureFlagCrudValue && (
        <Button
          onClick={() => navigate('./create')}
          className="flex items-center gap-2"
        >
          <PlusIcon className="w-5" />
          <p>{t('form.create')}</p>
        </Button>
      )}
      <div className="flex gap-2">
        <div className="flex items-center w-full gap-3">
          <TableSearchInput value={search ?? ''} onChange={onSearch} />
          {search && isFetching && (
            <Loader className="text-gray-600 animate-spin" />
          )}
        </div>
        <TableColumnsToggle disabled={disabled} table={table} />
      </div>
    </div>
  );
}
