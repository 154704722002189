'use client';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import type { TOrganizationService } from '@/services/services';
import { CheckCircle, Circle } from 'lucide-react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const BasicCard: React.FC<{
  service: TOrganizationService;
  selectedService: TOrganizationService | undefined;
  setSelectedService: (value: TOrganizationService) => void;
}> = ({ service, selectedService, setSelectedService }) => {
  const { t } = useTranslation();
  const [seeMore, setSeeMore] = useState<string[]>([]);
  const continueRef = useRef<null | HTMLDivElement>(null);
  const CharacterLimit = 138;
  const description = service?.description ?? '';
  const isShortDescription = description.length <= CharacterLimit;

  return (
    <Card
      key={service.id}
      onClick={() => {
        setSelectedService(service);
        setTimeout(() => {
          if (continueRef.current) {
            continueRef.current.scrollIntoView({
              behavior: 'smooth'
            });
          }
        }, 0);
      }}
      className={`flex items-start h-full ${
        selectedService?.id === service.id ? 'border-primary border-2' : ''
      }`}
    >
      <div className="flex items-center p-6 pr-0">
        {selectedService?.id === service.id ? (
          <CheckCircle width={20} className="text-primary" />
        ) : (
          <Circle className="text-zinc-400" width={20} />
        )}
      </div>
      <div>
        <CardHeader>
          <CardTitle className="flex items-start justify-between mb-1">
            <span className="text-xl">{service.name}</span>
          </CardTitle>
          <CardDescription className="flex flex-col items-end">
            <span
              className={`${
                seeMore.includes(service.id)
                  ? 'line-clamp-none'
                  : 'line-clamp-3'
              }`}
            >
              {service.description}
            </span>
            {!isShortDescription && !seeMore.includes(service.id) && (
              <Button
                className="pr-0 pl-9 pt-7"
                variant={'link'}
                onClick={(e) => {
                  e.stopPropagation();
                  setSeeMore((prev) => prev.concat([service.id]));
                }}
              >
                {t('newBooking.seeMore')}
              </Button>
            )}
          </CardDescription>
        </CardHeader>
      </div>
    </Card>
  );
};

export default BasicCard;
