import _ from 'lodash';

// Función para comparar los valores de dos objetos. Devuelve un nuevo objeto solo con los valores que cambiaron.

export const getChangedValues = <T extends object>(obj1: T, obj2: T): Partial<T> => {
  const result: Partial<T> = {};

  _.forOwn(obj2, (value, key) => {
    const originalValue = obj1[key as keyof T];

    if (_.isArray(value) && _.isArray(originalValue)) {
      // Compara arreglos
      if (!_.isEqual(value, originalValue)) {
        result[key as keyof T] = value;
      }
    } else if (_.isObject(value) && _.isObject(originalValue)) {
      // Compara objetos de forma recursiva
      const nestedDifferences = getChangedValues(
        originalValue as any,
        value as any
      );
      if (!_.isEmpty(nestedDifferences)) {
        result[key as keyof T] = nestedDifferences as any;
      }
    } else {
      // Compara valores primitivos, incluyendo null y undefined
      if (value !== originalValue) {
        result[key as keyof T] = value;
      }
    }
  });


  return result;
};
