import { z } from 'zod';

const formBookingDatepickerSchema = (
  isBlockedTime: (date: Date) => boolean
) => {
  return z.discriminatedUnion('timeTabs', [
    z.object({
      date: z.date({
        required_error: 'Se requiere una fecha.'
      }),
      time: z
        .object({
          from: z.string(),
          to: z.string(),
          status: z.string()
        })
        .superRefine((obj, ctx) => {
          if (!obj.from && !obj.to) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Selecciona una hora.'
            });
          }
        }),
      timeTabs: z.literal<string>('default')
    }),
    z.object({
      date: z
        .date({
          required_error: 'Se requiere una fecha.'
        })
        .superRefine((value, ctx) => {
          if (isBlockedTime(value)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'El horario seleccionado no está disponible.'
            });
            return;
          }

          const isOpenOrganization = !(
            value.getHours() >= 11 && value.getHours() <= 19
          ); // TODO: Cambiar a la apertura de la escuela
          if (isOpenOrganization) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Ingresa un Horario valido!'
            });
            return;
          }
        }),
      time: z.undefined(),
      timeTabs: z.literal<string>('custom')
    })
  ]);
};

export default formBookingDatepickerSchema;
