import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip';
import useFormattedDate from '@/hooks/useFormattedDate';
import { ReservationWithResourceId } from '@/services/reservations';
import { getReservationsTexts } from '@/utils/reservations';
import { EventWrapperProps } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface EventWrapperPropsWithChildren
  extends EventWrapperProps<ReservationWithResourceId> {
  children?: React.ReactNode;
}

const MyEventWrapper = (data: EventWrapperPropsWithChildren) => {
  const { t } = useTranslation();

  const children = data?.children;
  const navigate = useNavigate();
  const { formattedDate } = useFormattedDate();

  return (
    <Dialog>
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <DialogTrigger asChild>
            <TooltipTrigger asChild>{children}</TooltipTrigger>
          </DialogTrigger>
          <TooltipContent>
            {!!data?.event.startTime && !!data.event.endTime && (
              <p>
                {formattedDate({
                  date: data?.event.startTime,
                  dateFormat: 'time'
                })}
                -
                {formattedDate({
                  date: data?.event.endTime,
                  dateFormat: 'time'
                })}
              </p>
            )}
            {data?.event.consumers.map((consumer) => (
              <div key={consumer.id}>
                <p>{`${consumer.firstName} ${consumer.lastName}`}</p>
                {consumer.equipment[0]?.name ? (
                  <p className="font-semibold">{`${consumer.equipment.map((eq) => eq.name + '(' + eq.code + ')').join(', ')}`}</p>
                ) : (
                  <p>-</p>
                )}
              </div>
            ))}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <DialogContent aria-describedby={undefined}>
        <DialogHeader>
          <DialogTitle>
            {`${formattedDate({ date: data.event.startTime, dateFormat: 'date' })} [ ${formattedDate({ date: data.event.startTime, dateFormat: 'time' })}-${formattedDate({ date: data.event.endTime, dateFormat: 'time' })} ] `}
          </DialogTitle>
        </DialogHeader>

        <div className="">
          <div className="flex items-center justify-between">
            <h1 className="font-bold text-primary">{`${data.event?.service.name}`}</h1>
            <Badge
              variant={data.event.reservationStatus.name}
              className="justify-center h-8 justify-self-end"
            >
              {getReservationsTexts(data.event.reservationStatus.name)}
            </Badge>
          </div>
          <h3 className="my-4 font-bold">{t('card.consumers')}</h3>
          {data.event.consumers.map((consumer) => (
            <div
              key={consumer.id}
              className="flex flex-col gap-2 p-4 mt-3 rounded-md bg-accent"
            >
              <p>{`${consumer.firstName} ${consumer.lastName} (${consumer.weight}kg - ${consumer.height}m)`}</p>

              <h3 className="mt-2 font-semibold ">
                {t('card.materialAssigned')}
              </h3>

              {consumer.equipment[0]?.name ? (
                consumer.equipment.map((equipment) => (
                  <p key={equipment.id}>
                    #{equipment.code} {equipment.name}
                  </p>
                ))
              ) : (
                <p>-</p>
              )}
            </div>
          ))}
          <Button
            className="w-full mt-6"
            onClick={() => navigate(`/reservations/${data.event.id}`)}
          >
            {t('modals.eventWrapper.manageReservation')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MyEventWrapper;
