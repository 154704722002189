import api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const useDeleteServiceDivision = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ['delete-service-division'],
    mutationFn: (divisionId: string) =>
      api.serviceDivision.deleteServiceDivision(divisionId),
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['services', 'service-division']
      });
      toast.success(t('toast.deleteSericeDivision'));
      navigate(-1);
    },
    retry: false
  });
};

export default useDeleteServiceDivision;
