import { StoreApi } from 'zustand';
import { RootStore } from '@/stores/root-store.types';
import api from '@/services/api';

export const createAuthSlice = (
  set: StoreApi<RootStore>['setState'],
  get: StoreApi<RootStore>['getState'],
  ...rest: StoreApi<RootStore>[]
) => ({
  token: null,
  setToken: (token: string) =>
    set((state) => ({ token: (state.token = token) })),
  removeToken: () =>
    set(() => {
      api.api.removeToken();
      return { token: null };
    })
});
