import api from '@/services/api';
import { TPostServiceDivision } from '@/services/servicesDivision';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const usePatchServiceDivision = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ['update-service-division'],
    mutationFn: ({
      payload,
      divisionId
    }: {
      payload: Partial<TPostServiceDivision>;
      divisionId: string;
    }) => api.serviceDivision.patchServiceDivision(payload, divisionId),
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: (response) => {
      const queryKey = ['services', 'service-division'];
      if (response) {
        queryKey.push(response.id);
        queryClient.removeQueries({
          queryKey: ['service-division', response.id],
          exact: true
        });
      }
      queryClient.invalidateQueries({
        queryKey
      });
      toast.success(t('toast.updateSericeDivision'));
      navigate(-1);
    },
    retry: false
  });
};

export default usePatchServiceDivision;
