import api from '@/services/api';
import { skipToken, useQuery } from '@tanstack/react-query';

const useGetServiceById = (serviceId: string | undefined) => {
  return useQuery({
    queryKey: ['services', serviceId],
    queryFn: serviceId
      ? () => api.services.getServiceById(serviceId)
      : skipToken
  });
};

export default useGetServiceById;
