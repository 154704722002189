import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import NoData from '@/components/common/no-data/NoData';
import FormBookingDatePicker from '@/components/forms/form-booking-datepicker/FormBookingDatePicker';
import { Skeleton } from '@/components/ui/skeleton';
import useServicesAvailability from '@/hooks/queries/services-api/useServicesAvailability';
import useCalendarPaginateNavigation from '@/hooks/useCalendarPaginateNavigation';
import { useRootStore } from '@/stores/root-store';
import { getTimeZone } from '@/utils/dates';
import { ChooseDateTimeStepProps } from './chooseDateTimeStep.types';

const ChooseDateTimeStep: React.FC<ChooseDateTimeStepProps> = ({organization}) => {
  const stepServicesData = useRootStore((store) => store.stepServicesData);

  const firstReservationStartTime = stepServicesData?.firstReservationStartTime;
  const currentReservationDate = firstReservationStartTime
    ? getTimeZone(firstReservationStartTime, organization.tz)
    : undefined;
  const calendarPaginateNavigation = useCalendarPaginateNavigation(
    currentReservationDate
  );
  const { month, year } = calendarPaginateNavigation;

  const participants = stepServicesData?.participants || stepServicesData?.consumers?.length;
  const serviceId = stepServicesData?.service?.id;

  const {
    data: availableDates,
    isPending,
    isError
  } = useServicesAvailability({
    serviceId,
    participants,
    month,
    year
  });

  const noServicesAvailable = isPending;

  if (noServicesAvailable) {
    return <Skeleton className="w-full h-96" />;
  }
  if (!availableDates) {
    return <NoData />;
  }
  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <FormBookingDatePicker
      availableDates={availableDates}
      calendarPaginateNavigation={calendarPaginateNavigation}
      organization={organization}
    />
  );
};

export default ChooseDateTimeStep;
