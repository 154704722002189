import { CancelSaleValues } from '@/components/common/modals/cancel-sale-modal/cancel-sale-modal.types';
import { ServiceSold } from '@/components/common/tables/services-sold-table/services-sold-tables.types';
import { GetSalesProps } from '@/hooks/queries/sales-api/useGetPaginatedSales';
import { ApiResponse } from 'apisauce';
import { format } from 'date-fns';
import { Api } from './api';

type TGetSales = {
  total: number;
  limit: number;
  offset: number;
  page: number;
  hasNextPage: boolean;
  results: ServiceSold[];
};

export type SalePostPayload = {
  email?: string;
  firstName?: string;
  lastName?: string;
  identification?: { type?: string; number?: string };
  phone?: string;
  service?: string;
  validUntil?: string;
  paymentType?: string;
  firstReservationStartTime?: number;
  consumers?: Array<CreateConsumerDto | string>;
};

export type CreateConsumerDto = {
  firstName?: string;
  lastName?: string;
  birthdate?: string;
  identification?: { type?: string; number?: string };
  weight?: number;
  height?: number;
  id?: string;
  phone?: string;
  address?: string;
  location?: string;
  postalCode?: string;
  email?: string;
  isOwnUser?: boolean;
  [key: string]: any;
};

export type GetSaleOutDto = {
  id: string;
  validUntil: Date;
  user: {
    id: string;
  };
  service: {
    id: string;
    name: string;
    participants: number;
    nextSessionDuration: number; // minutes
    remainingTime: number; // minutes
  };
};

export class SalesApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getSales(): Promise<any> {
    const response: ApiResponse<TGetSales> =
      await this.api.apisauce.get(`sale`);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results;
  }
  async getSaleById(saleId: string): Promise<ServiceSold | undefined> {
    const response: ApiResponse<ServiceSold> = await this.api.apisauce.get(
      `sale/${saleId}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async getSaleByConsumer(consumerId: string): Promise<ServiceSold[]> {
    const response: ApiResponse<TGetSales> = await this.api.apisauce.get(
      `sale?consumer=${consumerId}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results || [];
  }

  async newBooking(payload: SalePostPayload): Promise<any> {
    const response: ApiResponse<GetSaleOutDto> = await this.api.apisauce.post(
      `sale`,
      JSON.stringify(payload)
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getPaginatedSales({
    pagination,
    sorting,
    filtering,
    columnFilters
  }: GetSalesProps): Promise<TGetSales | undefined> {
    //set paignation
    const page = pagination.pageIndex + 1;
    const limit = pagination.pageSize;

    // set sorting
    let sortBy = '';
    let sortDirection = '';

    //set columnFilters
    let columnFilterURI = '';

    columnFilters.forEach((filter) => {
      const valuesOfFilter = filter.value;
      let valueQueryString: string = '';

      if (Array.isArray(valuesOfFilter)) {
        valueQueryString = `&filter.${filter.id}=string.in.${valuesOfFilter.join(',')}`;
      } else {
        const dateRange = valuesOfFilter as {
          from: string;
          to: Date | undefined;
        };
        if (!dateRange.to) {
          const date = new Date(dateRange.from);
          // Set the time to the last hour of the day (23:00:00)
          date.setHours(23, 0, 0, 0);
          dateRange.to = date;
        }
        if (dateRange.from && dateRange.to) {
          valueQueryString = `&filter.createdAt=date.between.${format(dateRange.from, "yyyy-MM-dd'T'HH:mm:ss")},${format(dateRange.to, "yyyy-MM-dd'T'HH:mm:ss")}`;
        }
      }

      columnFilterURI += valueQueryString;
    });

    for (let i = 0; i < sorting.length; i++) {
      const id = sorting[i].id,
        direction = sorting[i].desc ? 'DESC' : 'ASC';
      sortBy += id;
      sortDirection += direction;

      if (i !== sorting.length - 1) {
        sortBy += ',';
        sortDirection += ',';
      }
    }

    let URI = `sale?limit=${limit}&page=${page}`;
    if (filtering) {
      const filteringWithoutDot = filtering.replace('.', ''); //we remove [.] to avoid 500 error. Needs to be reviewd if we search for emails
      URI += `&filter.or.user.firstName=string.like.${filteringWithoutDot}&filter.or.user.lastName=string.like.${filteringWithoutDot}&filter.or.id=string.like.${filteringWithoutDot}`;
    }
    if (sorting.length) {
      URI += `&sortBy=${sortBy}&sortDirection=${sortDirection}`;
    }
    if (columnFilters.length) {
      URI += columnFilterURI;
    }
    const response: ApiResponse<TGetSales> = await this.api.apisauce.get(URI);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async patchCancelSale(body: CancelSaleValues, saleId: string): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `sale/cancel/${saleId}`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
