import { AlertCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const NoData = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center h-full text-center p-4 bg-gray-100 rounded-md mt-4">
      <AlertCircle className="w-16 h-16 text-gray-400 mb-4" />
      <h2 className="text-lg font-semibold text-gray-700">
        {t('noData.title')}
      </h2>
      <p className="text-gray-500">{t('noData.message')}</p>
    </div>
  );
};

export default NoData;
