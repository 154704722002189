import { Button } from '@/components/ui/button';
import useReservationActions from '@/hooks/useReservationActions';
import {
  ReservationStatus,
  type TReservationStatus
} from '@/utils/reservations';
import { CheckCircle2, XCircle } from 'lucide-react';
import type React from 'react';
import { useTranslation } from 'react-i18next';

const PresentButtons: React.FC<{
  status: TReservationStatus;
  reservationId: string;
}> = ({ status, reservationId }) => {
  const { handleAbsent, handleCompleted, isPending } = useReservationActions();
  const { t } = useTranslation();
  if (status === ReservationStatus.Completed) {
    return (
      <div>
        <div className="flex items-center">
          <CheckCircle2 className="w-3 text-green-500 mr-2" />
          <p>{t('common.status.present')}</p>
        </div>
        <Button
          disabled={isPending}
          size="sm"
          variant="destructive"
          onClick={() => handleAbsent(reservationId)}
        >
          {t('common.status.absent')}
        </Button>
      </div>
    );
  }

  if (status === ReservationStatus.Absent) {
    return (
      <div>
        <div className="flex items-center">
          <XCircle className="w-3 text-red-500 mr-2" />
          <p> {t('common.status.absent')}</p>
        </div>
        <Button
          disabled={isPending}
          size="sm"
          onClick={() => handleCompleted(reservationId)}
        >
          {t('common.status.present')}
        </Button>
      </div>
    );
  }

  return (
    <div className="flex gap-1">
      <Button
        disabled={isPending}
        size="sm"
        onClick={() => handleCompleted(reservationId)}
      >
        {t('common.status.present')}
      </Button>
      <Button
        disabled={isPending}
        size="sm"
        variant={'destructive'}
        onClick={() => handleAbsent(reservationId)}
      >
        {t('common.status.absent')}
      </Button>
    </div>
  );
};

export default PresentButtons;
