import { Badge } from '@/components/ui/badge';
import React from 'react';
import { useTranslation } from 'react-i18next';
type Status = {
  label: string;
  value: string;
  icon?: React.ComponentType<{ className?: string }>;
};
const useStatus = (type: 'reservations' | 'payments') => {
  const { t } = useTranslation();
  let statuses: Status[] = [];
  switch (type) {
    case 'reservations':
      statuses = [
        {
          value: 'Proposed',
          label: t('common.status.proposed'),
          icon: () => (
            <Badge variant={'Proposed'} className="w-4 h-4 mx-1 rounded-full" />
          )
        },
        {
          value: 'Accepted',
          label: t('common.status.accepted'),
          icon: () => (
            <Badge variant={'Accepted'} className="w-4 h-4 mx-1 rounded-full" />
          )
        },
        {
          value: 'Rejected',
          label: t('common.status.rejected'),
          icon: () => (
            <Badge variant={'Rejected'} className="w-4 h-4 mx-1 rounded-full" />
          )
        },
        {
          value: 'Requested',
          label: t('common.status.requested'),
          icon: () => (
            <Badge
              variant={'Requested'}
              className="w-4 h-4 mx-1 rounded-full"
            />
          )
        },
        {
          value: 'Completed',
          label: t('common.status.completed'),
          icon: () => (
            <Badge
              variant={'Completed'}
              className="w-4 h-4 mx-1 rounded-full"
            />
          )
        },
        {
          value: 'Absent',
          label: t('common.status.absent'),
          icon: () => (
            <Badge variant={'Absent'} className="w-4 h-4 mx-1 rounded-full" />
          )
        },
        {
          value: 'Canceled',
          label: t('common.status.canceled'),
          icon: () => (
            <Badge variant={'Canceled'} className="w-4 h-4 mx-1 rounded-full" />
          )
        },
        {
          value: 'Pending',
          label: t('common.status.pending'),
          icon: () => (
            <Badge variant={'Pending'} className="w-4 h-4 mx-1 rounded-full" />
          )
        }
      ];
      break;
    case 'payments':
      statuses = [
        {
          value: 'Approved',
          label: t('common.status.approved'),
          icon: () => (
            <Badge variant={'Approved'} className="w-4 h-4 mx-1 rounded-full" />
          )
        },
        {
          value: 'Rejected',
          label: t('common.status.rejected'),
          icon: () => (
            <Badge variant={'Rejected'} className="w-4 h-4 mx-1 rounded-full" />
          )
        },
        {
          value: 'Pending',
          label: t('common.status.pending'),
          icon: () => (
            <Badge variant={'Pending'} className="w-4 h-4 mx-1 rounded-full" />
          )
        }
      ];
      break;
    default:
      break;
  }
  return { statuses };
};

export default useStatus;
