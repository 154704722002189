import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetReservationByConsumer = (consumerId: string | undefined) => {
  return useQuery({
    queryKey: ['reservation', consumerId],
    queryFn: () => {
      if (consumerId)
        return api.reservations.getReservationByConsumer(consumerId);
    },
    enabled: !!consumerId
  });
};

export default useGetReservationByConsumer;
