import ServicesTable from '@/components/common/tables/services-table/ServicesTable';

const ServicesPage = () => {

  return (
    <div className="w-full">
      <ServicesTable  />
    </div>
  );
};

export default ServicesPage;
