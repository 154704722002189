import { z } from 'zod';

const cancelSaleSchema = () =>
  z.object({
    observation: z
      .string({ required_error: 'La observación es requerida.' })
      .min(10, 'La observación debe contener al menos 10 caracteres.')
  });

export default cancelSaleSchema;
