import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TimeRangePickerProps {
  startTime: Date;
  endTime: Date;
  setStartTime: (time: Date) => void;
  setEndTime: (time: Date) => void;
}

export default function TimeRangePicker({
  startTime,
  endTime,
  setStartTime,
  setEndTime
}: TimeRangePickerProps) {
  const [error, setError] = useState('');
  const { t } = useTranslation();

  // Generar opciones de tiempo en incrementos de 30 minutos
  const timeOptions = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor(i / 2)
      .toString()
      .padStart(2, '0');
    const minute = i % 2 === 0 ? '00' : '30';
    return `${hour}:${minute}`;
  });

  // Formatear objeto Date a cadena "HH:mm"
  const formatTime = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  // Convertir cadena "HH:mm" a objeto Date
  const parseTime = (timeStr: string) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  };

  useEffect(() => {
    if (startTime > endTime) {
      setError('La hora de inicio no puede ser mayor que la hora de fin');
    } else {
      setError('');
    }
  }, [startTime, endTime]);

  return (
    <div className="w-full max-w-2xl">
      <div className="flex flex-wrap items-end gap-4">
        {/* Selector de Hora de Inicio */}
        <div className="flex-1 min-w-[200px]">
          <Label htmlFor="start-time" className="block mb-2">
            {t('common.startTime')}
          </Label>
          <Select
            value={formatTime(startTime)}
            onValueChange={(value) => setStartTime(parseTime(value))}
          >
            <SelectTrigger
              id="start-time"
              className={cn('w-full', error && 'border-red-500')}
            >
              <SelectValue placeholder="Seleccionar hora de inicio" />
            </SelectTrigger>
            <SelectContent className="max-h-[240px]">
              {timeOptions.map((time) => (
                <SelectItem key={`start-${time}`} value={time}>
                  {time}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {/* Selector de Hora de Fin */}
        <div className="flex-1 min-w-[200px]">
          <Label htmlFor="end-time" className="block mb-2">
            {t('common.endTime')}
          </Label>
          <Select
            value={formatTime(endTime)}
            onValueChange={(value) => setEndTime(parseTime(value))}
          >
            <SelectTrigger
              id="end-time"
              className={cn('w-full', error && 'border-red-500')}
            >
              <SelectValue placeholder="Seleccionar hora de fin" />
            </SelectTrigger>
            <SelectContent className="max-h-[240px]">
              {timeOptions.map((time) => (
                <SelectItem key={`end-${time}`} value={time}>
                  {time}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* Mostrar error si la hora de inicio es mayor que la de fin */}
      {error && <div className="mt-2 text-sm text-red-500">{error}</div>}
    </div>
  );
}
