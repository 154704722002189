import IdentificationTypeSelect from '@/components/common/input/identification-types-select/IdentificationTypeSelect';
import CalendarModal from '@/components/common/modals/calendar-modal/CalendarModal';

import { Button } from '@/components/ui/button';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import type { TUsers } from '@/services/user';
import { useRootStore } from '@/stores/root-store';

import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const UserForm: React.FC<{
  form: UseFormReturn<any, any, undefined>;
  user: TUsers | undefined;
  onSave: () => Promise<void>;
}> = ({ form, user, onSave }) => {
  const { t } = useTranslation();

  const cleanConsumers = useRootStore((store) => store.cleanConsumers);
  const loadStepServicesData = useRootStore(
    (store) => store.loadStepServicesData
  );

  const handleValidate = () => {
    onSave();
    const data = form.getValues();
    cleanConsumers('user');
    loadStepServicesData(data.user);
  };

  return (
    <div
      className="flex flex-col gap-8 p-6 border rounded-md border-primary"
      key={'participant-participant'}
    >
      <FormField
        control={form.control}
        name={'user.firstName'}
        render={({ field }) => {
          const disabled = !!user?.firstName;
          return (
            <FormItem>
              <FormLabel>{t('stepParticipants.form.firstName')}</FormLabel>
              <FormControl>
                <Input {...field} readOnly={disabled} />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={'user.lastName'}
        render={({ field }) => {
          const disabled = !!user?.firstName;
          return (
            <FormItem>
              <FormLabel>{t('stepParticipants.form.lastName')}</FormLabel>
              <FormControl>
                <Input {...field} readOnly={disabled} />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="user.identification.type"
        render={({ field }) => {
          const disabled = !!user?.identification?.type;
          return (
            <FormItem>
              <IdentificationTypeSelect
                disabled={disabled}
                defaultValue={field?.value}
                onValueChange={(value) => {
                  field.onChange(value);
                }}
              />
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="user.identification.number"
        render={({ field }) => {
          const disabled = !!user?.identification?.number;
          return (
            <FormItem>
              <FormLabel>
                {t('stepParticipants.form.identificationNumber')}
              </FormLabel>
              <FormControl>
                <Input
                  readOnly={disabled}
                  placeholder={t('stepParticipants.form.id')}
                  {...field}
                  value={field?.value || ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="user.age"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('form.age')}</FormLabel>
            <FormControl>
              <Input
                placeholder={'12'}
                {...field}
                type="number"
                value={field?.value || ''}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="user.location"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('form.address')}</FormLabel>
            <FormControl>
              <Input {...field} value={field?.value || ''} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={'user.birthdate'}
        render={({ field }) => {
          const disabled = !!user?.birthdate;
          return (
            <FormItem className="flex flex-col ">
              <FormLabel>{t('stepParticipants.form.birth')}</FormLabel>
              <CalendarModal
                value={field.value}
                placeholder={t('stepParticipants.form.birth')}
                onAccept={(value) => {
                  field.onChange(value);
                }}
                disabledInputLabel={disabled}
                initialFocus
                dropDownPicker
              />
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="user.insurance"
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{t('form.medicalCoverage')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={'OSDE, Swiss Medical, etc'}
                  {...field}
                  value={field?.value || ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="user.size"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('form.tShirtSize')}</FormLabel>
            <FormControl>
              <Input
                placeholder={'S, M, L, XL'}
                {...field}
                value={field?.value || ''}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="user.holidayDate"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('form.holidayDate')}</FormLabel>
            <FormDescription>{t('form.holidayDescription')}</FormDescription>
            <FormControl>
              <Input
                placeholder={'Del 16/01 al 07/02'}
                {...field}
                value={field?.value || ''}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={'user.isExtension'}
        render={({ field }) => {
          const defaultValue =
            field.value === undefined ? undefined : field.value ? 'yes' : 'no';
          return (
            <FormItem>
              <div className="flex flex-row items-end gap-5">
                <FormLabel>{t('common.isExtension')}</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={(value) => field.onChange(value === 'yes')}
                    defaultValue={defaultValue}
                    className="flex space-x-2 mt-0 items-center"
                  >
                    <FormItem className="flex items-center space-x-1 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="yes" />
                      </FormControl>
                      <FormLabel className="font-normal">{t('form.yes')}</FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-1 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="no" />
                      </FormControl>
                      <FormLabel className="font-normal">{t('form.no')}</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
              </div>
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={'user.isAularWorker'}
        render={({ field }) => {
          const defaultValue =
            field.value === undefined ? undefined : field.value ? 'yes' : 'no';
          return (
            <FormItem>
              <div className="flex flex-row items-end gap-5">
                <FormLabel>{t('isAluarWorker')}</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={(value) => field.onChange(value === 'yes')}
                    defaultValue={defaultValue}
                    className="flex items-center mt-0 space-x-2"
                  >
                    <FormItem className="flex items-center space-x-1 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="yes" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {t('form.yes')}
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-1 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="no" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {t('form.no')}
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
              </div>
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={'user.isPartner'}
        render={({ field }) => {
          const defaultValue =
            field.value === undefined ? undefined : field.value ? 'yes' : 'no';
          return (
            <FormItem>
              <div className="flex flex-row items-end gap-5">
                <FormLabel>{t('isPartner')}</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={(value) => field.onChange(value === 'yes')}
                    defaultValue={defaultValue}
                    className="flex items-center mt-0 space-x-2"
                  >
                    <FormItem className="flex items-center space-x-1 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="yes" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {t('form.yes')}
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-1 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="no" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {t('form.no')}
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
              </div>
            </FormItem>
          );
        }}
      />
      <Button type="button" onClick={handleValidate}>
        {t('common.validate')}
      </Button>
    </div>
  );
};

export default UserForm;
