import PaymentsTable from '@/components/common/tables/payments-table/PaymentsTable';

const PaymentsPage = () => {
  return (
    <div className="w-full">
      <PaymentsTable />
    </div>
  );
};

export default PaymentsPage;
