import type { ReservationInterface } from '@/components/common/tables/tables.types';
import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetReservationsByServiceId = <TResult,>(
  serviceId: string | null,
  filterToday = false
) => {
  return useQuery<ReservationInterface[]>({
    queryKey: ['reservations', serviceId, filterToday],
    queryFn: async () => {
      const now = new Date();
      const startOfDay = new Date(now.setHours(0, 0, 0, 0)).getTime(); // Timestamp init of the day
      const endOfDay = new Date(now.setHours(23, 59, 59, 999)).getTime(); // Timestamp end of the day

      const URI = filterToday
        ? `filter.sale.service.id=string.eq.${serviceId}&filter.startTime=number.between.${startOfDay},${endOfDay}`
        : `filter.sale.service.id=string.eq.${serviceId}`;

      const response = await api.reservations.getReservationByServiceId(URI);
      return response;
    },
    enabled: Boolean(serviceId)
  });
};

export default useGetReservationsByServiceId;
