import api from '@/services/api';
import { skipToken, useQuery } from '@tanstack/react-query';

const useGetServiceDivisionById = (divisionId: string | undefined) => {
  return useQuery({
    queryKey: ['service-division', divisionId],
    queryFn: divisionId
      ? () => api.serviceDivision.getServiceDivisionById(divisionId)
      : skipToken
  });
};

export default useGetServiceDivisionById;
