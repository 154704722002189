import { ServicesFormValues } from '@/components/forms/form-service/ServiceForm.types';
import api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
function usePostService() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ['create-service'],
    mutationFn: (payload: Partial<ServicesFormValues>) => {
      return api.services.postService(payload);
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['services', 'service-division']
      });
      toast.success(t('toast.serviceUpdated'));
      navigate(-1);
    },
    retry: false
  });
}

export default usePostService;
