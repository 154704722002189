import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetOrganizationSports = () => {
  return useQuery({
    queryKey: ['sports'],
    queryFn: () => api.sports.getSports()
  });
};
export default useGetOrganizationSports;
