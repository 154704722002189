import { Reservation } from '@/components/common/tables/services-sold-table/services-sold-tables.types';
import { ReservationInterface } from '@/components/common/tables/tables.types';
import { addHours, isAfter, isBefore } from 'date-fns';
import { t } from 'i18next';

export const ReservationStatus = {
  Accepted: 'Accepted',
  Absent: 'Absent',
  Canceled: 'Canceled',
  Proposed: 'Proposed',
  Pending: 'Pending',
  Completed: 'Completed',
  Rejected: 'Rejected',
  Requested: 'Requested'
};

export type TReservationStatus = keyof typeof ReservationStatus;

export function getReservationsTexts(status: TReservationStatus) {
  switch (status) {
    case ReservationStatus.Requested:
      return t('common.status.requested');
    case ReservationStatus.Accepted:
      return t('common.status.accepted');
    case ReservationStatus.Rejected:
      return t('common.status.rejected');
    case ReservationStatus.Proposed:
      return t('common.status.proposed');
    case ReservationStatus.Completed:
      return t('common.status.completed');
    case ReservationStatus.Absent:
      return t('common.status.absent');
    case ReservationStatus.Canceled:
      return t('common.status.canceled');
    case ReservationStatus.Pending:
      return t('common.status.pending');
  }
}

export const PaymentStatus = {
  Pending: 'Pending',
  Rejected: 'Rejected',
  Approved: 'Approved'
};

export type TPaymentStatus = keyof typeof PaymentStatus;

export function getPaymentStatusTexts(status: TPaymentStatus) {
  switch (status) {
    case PaymentStatus.Rejected:
      return t('common.status.rejected');
    case PaymentStatus.Pending:
      return t('common.status.pending');
    case PaymentStatus.Approved:
      return t('common.status.approved');
  }
}

export const getHoursLeft = (reservations: Reservation[]) => {
  return reservations.filter(
    (reservation) =>
      reservation.status !== ReservationStatus.Completed &&
      reservation.status !== ReservationStatus.Absent &&
      reservation.status !== ReservationStatus.Canceled
  ).length;
};

export const getHoursConsumed = (reservations: Reservation[]) => {
  return reservations.filter(
    (reservation) =>
      reservation.status === ReservationStatus.Completed ||
      reservation.status === ReservationStatus.Absent ||
      reservation.status === ReservationStatus.Canceled
  ).length;
};

export const isWithinNext24Hours = (dateToCheck: number | Date) => {
  const now = new Date();
  const twentyFourHoursLater = addHours(now, 24);

  return (
    isAfter(dateToCheck, now) && isBefore(dateToCheck, twentyFourHoursLater)
  );
};

export const isBeforeToday = (
  reservationEndtime: number | Date | undefined
) => {
  if (reservationEndtime === undefined) return false;
  return !!isBefore(Number(reservationEndtime), new Date());
};

export const getPendingAction = (reservation: ReservationInterface) => {
  if (
    !!reservation.endTime &&
    isBeforeToday(reservation.endTime) &&
    (reservation.reservationStatus.name !== ReservationStatus.Absent &&
      reservation.reservationStatus.name !== ReservationStatus.Completed &&
      reservation.reservationStatus.name !== ReservationStatus.Canceled)
  ) {
    return t('card.completeAction');
  }
  if (reservation.reservationStatus.name === ReservationStatus.Requested) {
    return t('common.accept');
  }
  if (
    reservation.reservationStatus.name === ReservationStatus.Accepted &&
    !reservation.consumers.every((consumer) => !!consumer.equipment.length)
  ) {
    return t('card.assignMaterial');
  }
  return undefined;
};
