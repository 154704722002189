import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import FormInventory, {
  InventoryValues
} from '@/components/forms/form-inventory/FormInventory';
import { Skeleton } from '@/components/ui/skeleton';
import useGetEquipmentTypes from '@/hooks/queries/equipments-api/useGetEquipmentTypes';
import usePatchEquipment from '@/hooks/queries/equipments-api/usePatchEquipment';
import { getChangedValues } from '@/utils/forms';
import { useLocation } from 'react-router-dom';

const EditInventory = () => {
  const { search } = useLocation();
  const data = new URLSearchParams(search).get('inventoryItem');

  const parsedInventoryItem =
    typeof data === 'string' ? JSON.parse(data) : null;

  const defaultValues = {
    name: parsedInventoryItem?.name ?? '',
    code: parsedInventoryItem?.code ?? '',
    description: parsedInventoryItem?.description ?? '',
    equipmentType: parsedInventoryItem?.equipmentType?.id ?? ''
  };
  const { mutate, isPending } = usePatchEquipment();
  const {
    data: inventoryCategories,
    isLoading,
    isError
  } = useGetEquipmentTypes();

  if (isLoading) {
    return <Skeleton className="w-full h-2/3" />;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  if (!inventoryCategories?.length) {
    return <NoData />;
  }

  const onSubmit = (data: InventoryValues) => {
    const onlyChangedData = getChangedValues<Partial<InventoryValues>>(
      defaultValues,
      data
    );
    mutate({
      payload: onlyChangedData,
      equipmentId: parsedInventoryItem?.id ?? ''
    });
  };

  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <FormInventory
          onSubmit={onSubmit}
          isPending={isPending}
          defaultValues={defaultValues}
          inventoryCategories={inventoryCategories}
        />
      </div>
    </>
  );
};

export default EditInventory;
