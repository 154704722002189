export const rollbarConfig = {
  accessToken: process.env.POST_CLIENT_ITEM_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.ENVIRONMENT_NAME ?? 'development',
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true
      }
    }
  },
  ingnoredMeesages: ['/Login required$/'] // Ignore all errors ending in "Login required"
};
