import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetMetabaseAnalytics = (dashboardId: string | number) => {
  return useQuery({
    queryKey: ['dashboardId', dashboardId],
    queryFn: () => api.analytics.getMetabaseDashboard(dashboardId)
  });
};
export default useGetMetabaseAnalytics;
