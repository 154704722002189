import GenericCard from '@/components/common/cards/generic-card/GenericCard';
import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import NoData from '@/components/common/no-data/NoData';
import { Skeleton } from '@/components/ui/skeleton';
import useGetReservationsStats from '@/hooks/queries/reservations-api/useGetReservationsStats';
import { useTranslation } from 'react-i18next';

const QuickActionsStats = () => {
  const { t } = useTranslation();
  const reservationsStats = useGetReservationsStats();

  if (reservationsStats.isPending) {
    return (
      <div className="grid grid-cols-2 gap-3 mb-4 md:grid-cols-4">
        <Skeleton className="h-32" />
        <Skeleton className="h-32" />
        <Skeleton className="h-32" />
        <Skeleton className="h-32" />
      </div>
    );
  }

  if (reservationsStats.isError) {
    return <ErrorMessage />;
  }

  if (!reservationsStats.data) {
    return <NoData />;
  }

  return (
    <div className="grid grid-cols-2 gap-3 mb-4 md:grid-cols-4">
      <GenericCard
        title={t('quickActions.stats.today')}
        content={reservationsStats.data.today}
      />
      <GenericCard
        title={t('quickActions.stats.pendingAccept')}
        content={reservationsStats.data.requested}
      />
      <GenericCard
        title={t('quickActions.stats.pendingProposed')}
        content={reservationsStats.data.proposed}
      />
      <GenericCard
        title={t('quickActions.stats.rejected')}
        content={reservationsStats.data.rejected}
      />
    </div>
  );
};

export default QuickActionsStats;
