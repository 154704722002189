import ServicesCategoriesTable from '@/components/common/tables/services-categories-table/ServicesCategoriesTable';

const ServicesCategoriesPage = () => {
  return (
    <div className="w-full">
      <ServicesCategoriesTable />
    </div>
  );
};

export default ServicesCategoriesPage;
