import api from '@/services/api';
import { TequipmentSchedule } from '@/services/equipments/equipments';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function usePostClouserEquipment(onCloseModal: (state: boolean) => void) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['post-clouser-equipment'],
    mutationFn: ({
      blocks,
      equipmentId
    }: {
      blocks: TequipmentSchedule;
      equipmentId: string;
    }) => {
      return api.equipments.postClosureEquipment({
        blocks,
        equipment: [equipmentId]
      });
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: (response) => {
      onCloseModal(false);
      if (response.serviceId) {
        queryClient.invalidateQueries({
          queryKey: ['equipment-active-dates', response.serviceId]
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['equipment-active-dates']
        });
      }
      toast.success(t('toast.availabilitySuccess'));
    },
    retry: false
  });
}

export default usePostClouserEquipment;
