import { z } from 'zod';

const serviceSchema = z
  .object(
    {
      name: z.string(),
      price: z.coerce.number(),
      id: z.string(),
      reservationPct: z.coerce.number(),
      maxConsumers: z.coerce.number(),
      minConsumers: z.coerce.number(),
      minAge: z.coerce.number(),
      maxAge: z.coerce.number()
    },
    { required_error: 'Selecciona una actividad.' }
  )
  .superRefine((obj, ctx) => {
    if (!(obj.name && obj.price && obj.id)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Ocurrió un error al momento de procesar las actividades.'
      });
    }
  });

const formChooseServiceSchema = (dynamicValidations: {
  minParticipants: number;
  maxParticipants: number;
}) => {
  return z
    .object({
      service: serviceSchema,
      participants: z.coerce.number()
    })
    .superRefine((data, ctx) => {
      const { minParticipants, maxParticipants } = dynamicValidations;
      const participants = data.participants;

      if (participants < minParticipants) {
        ctx.addIssue({
          path: ['participants'],
          code: z.ZodIssueCode.custom,
          message: `Número mínimo de participantes para este servicio es ${minParticipants}.`
        });
        return;
      }

      if (participants > maxParticipants) {
        ctx.addIssue({
          path: ['participants'],
          code: z.ZodIssueCode.custom,
          message: `Número máximo de participantes para este servicio es ${maxParticipants}.`
        });
      }
    });
};

export default formChooseServiceSchema;
