import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetReservationsStats = () => {
  return useQuery({
    queryKey: ['reservationsStats'],
    queryFn: () => api.reservations.getReservationsStats()
  });
};
export default useGetReservationsStats;
