import CallbackPage from '@/pages/Callback';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ComponentType } from 'react';

export const AuthGuard = ({ component }: { component: ComponentType }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <CallbackPage />
  });

  return <Component />;
};
