import type { TCheckReservations } from '@/services/services';
import type { Closures } from '../check-resevations-modal/checkReservationsModal.types';
import type { TCheckModalState } from '@/lib/utils';

export type ModalState = {
  state: boolean;
  serviceId: string | null;
  closureCheck?: TCheckReservations;
  closures: Closures;
  modalsStep: 0 | 1 | 2;
  checkModal: TCheckModalState;
};

export type ModalActionsTypes =
  | { type: 'OPEN_MODAL'; payload: string }
  | { type: 'CLOSE_MODAL' }
  | { type: 'SET_CLOSURE_CHECK'; payload: TCheckReservations | undefined }
  | { type: 'SET_CLOSURES'; payload: Closures }
  | { type: 'SET_MODAL_STEP'; payload: 0 | 1 | 2 }
  | { type: 'SET_CHECK_MODAL'; payload: TCheckModalState };

export const initalBlockState: ModalState = {
  closureCheck: undefined,
  state: false,
  serviceId: null,
  closures: {},
  modalsStep: 0,
  checkModal: { data: undefined, state: false }
};

export function modalBlockReducer(
  state: ModalState,
  action: ModalActionsTypes
): ModalState {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { ...state, state: true, serviceId: action.payload };
    case 'CLOSE_MODAL':
      return {
        closureCheck: undefined,
        state: false,
        serviceId: null,
        closures: {},
        modalsStep: 0,
        checkModal: { data: undefined, state: false }
      };
    case 'SET_CLOSURE_CHECK':
      return { ...state, closureCheck: action.payload };
    case 'SET_CLOSURES':
      return { ...state, closures: action.payload };
    case 'SET_MODAL_STEP':
      return {
        ...state,
        modalsStep: action.payload
      };
    case 'SET_CHECK_MODAL':
      return { ...state, checkModal: action.payload };
    default:
      return state;
  }
}
