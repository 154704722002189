import { CancelSaleValues } from '@/components/common/modals/cancel-sale-modal/cancel-sale-modal.types';
import api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

const usePatchCancelSale = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['patchSale'],
    mutationFn: (data: { body: CancelSaleValues; saleId: string }) => {
      return api.sales.patchCancelSale(data.body, data.saleId);
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'sale' || query.queryKey[0] === 'sales'
      });
      toast.success(t('toast.cancelSale'));
    },
    retry: false
  });
};

export default usePatchCancelSale;
