import CalendarModal from '@/components/common/modals/calendar-modal/CalendarModal';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { maxHeightInputValidation, maxWeightInputValidation } from '@/lib/utils';
import { TUsers } from '@/services/user';
import { useRootStore } from '@/stores/root-store';
import { StepService } from '@/stores/slices/step-service/step-service.types';
import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ParticipantForm: React.FC<{
  form: UseFormReturn<any, any, undefined>;
  user: TUsers | undefined;
  index: number;
  onSave: () => Promise<void>;
}> = ({ form, user, index, onSave }) => {
  const stepServicesData: StepService | null = useRootStore(
    (store) => store.stepServicesData
  );
  const { t } = useTranslation();

  const formValue = form.getValues().participants[index];
  const participantFormValue = form.getValues().participants;

  const participantAvailable = user?.consumers?.filter(
    (consumer) =>
      !participantFormValue.some(
        (participant: any) => participant.id === consumer.id
      )
  );

  const isSelectedExistingParticipant = Boolean(formValue?.id);
  const disabledInputs = isSelectedExistingParticipant;
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState<string>('');

  const setExistingParticipant = (id: string) => {
    const participant = user?.consumers?.find((consumer) => consumer.id === id);
    if (participant) {
      const participantFormValue = {
        id: participant.id,
        firstName: participant.firstName,
        lastName: participant.lastName,
        birthdate: new Date(participant.birthdate),
        height: participant.height,
        weight: participant.weight
      };
      form.setValue(`participants.${index}`, participantFormValue);
    }

    onSave();
  };

  const removeSelectedParticipant = () => {
    form.setValue(`participants.${index}`, {
      firstName: '',
      lastName: '',
      birthdate: new Date(),
      height: 0,
      weight: 0,
      id: ''
    });
    onSave();
  };
  const handleSelect = () => {
    if (selectedParticipant) setExistingParticipant(selectedParticipant);
    setIsOpenDialog(false);
  };

  return (
    <div className="border border-primary p-6 rounded-md flex flex-col gap-5">
      <div className="flex flex-col justify-between gap-5 sm:flex-row sm:items-center">
        <p className="text-sm font-normal text-primary">
          {stepServicesData?.service?.name}
        </p>
        {((participantAvailable || []).length > 0 ||
          (user?.consumers || []).length > 0) && (
          <>
            {formValue.id !== '' ? (
              <Button
                type="button"
                variant="destructive"
                className="whitespace-normal h-full"
                onClick={removeSelectedParticipant}
              >
                {t('newBooking.participants.removeParticipant')}
              </Button>
            ) : (
              <>
                {(participantAvailable || []).length > 0 && (
                  <Button
                    type="button"
                    variant="outline"
                    className="whitespace-normal h-full"
                    onClick={() => {
                      setIsOpenDialog(true);
                      setSelectedParticipant('');
                    }}
                  >
                    {t('newBooking.participants.preloadParticipant')}
                  </Button>
                )}
              </>
            )}
          </>
        )}
        <Dialog open={isOpenDialog} onOpenChange={setIsOpenDialog}>
          <DialogContent className="sm:max-w-[425px] gap-8">
            <DialogHeader>
              <DialogTitle>
                {t('newBooking.participants.selectParticipant')}
              </DialogTitle>
              <DialogDescription>
                {t(
                  'newBooking.participants.selectedParticipantInfoMessage'
                )}
              </DialogDescription>
            </DialogHeader>

            <Select onValueChange={(value) => setSelectedParticipant(value)}>
              <SelectTrigger className="w-full">
                <SelectValue
                  placeholder={t(
                    'newBooking.participants.selectParticipant'
                  )}
                />
              </SelectTrigger>
              <SelectContent>
                {participantAvailable?.map((consumer) => (
                  <SelectItem key={consumer.id} value={consumer.id}>
                    {`${consumer.firstName} ${consumer.lastName}`}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button
              type="button"
              onClick={handleSelect}
              className="w-full"
              disabled={selectedParticipant === ''}
            >
              {t('common.accept')}
            </Button>
          </DialogContent>
        </Dialog>
      </div>
      <FormField
        control={form.control}
        name={`participants.${index}.firstName`}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{t('form.firstName')}</FormLabel>
              <FormControl>
                <Input disabled={disabledInputs} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={`participants.${index}.lastName`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('form.lastName')}</FormLabel>
            <FormControl>
              <Input disabled={disabledInputs} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`participants.${index}.birthdate`}
        render={({ field }) => (
          <FormItem className="flex flex-col ">
            <FormLabel>{t('form.birth')}</FormLabel>
            <CalendarModal
              value={field.value}
              disabledInputLabel={disabledInputs}
              onAccept={(value) => {
                field.onChange(value);
              }}
              initialFocus
              dropDownPicker
            />
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`participants.${index}.weight`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('form.weight')}</FormLabel>
            <FormControl>
              <Input
                disabled={disabledInputs}
                type="number"
                {...field}
                onChange={(e) => {
                  if (maxWeightInputValidation(Number(e.target.value))) {
                    field.onChange(e);
                  }
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`participants.${index}.height`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('form.height')}</FormLabel>
            <FormControl>
              <Input
                disabled={disabledInputs}
                step="1"
                type="number"
                {...field}
                onChange={(e) => {
                  if (maxHeightInputValidation(Number(e.target.value))) {
                    field.onChange(e);
                  }
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <Button type="button" onClick={onSave}>
        {t('common.validate')}
      </Button>
    </div>
  );
};

export default ParticipantForm;
