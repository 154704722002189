import React from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import { ActiveDaysCardProps } from './ActiveDaysCard.types';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';
import { t } from 'i18next';

const ActiveDaysCard: React.FC<ActiveDaysCardProps> = ({ organization }) => {
  const { i18n } = useTranslation();
  const lng = i18n.language === 'es' ? es : enUS;
  const days = JSON.parse(organization.activeDays);

  const formatTime = (time: number) => {
    return `${String(time).padStart(2, '0')}:00`;
  };

  const formatRange = (ranges: number[][]) => {
    return ranges.map((range, i) => {
      const [start, end] = range;
      return (
        <p key={`${start - end}-${i}}`}>
          {formatTime(start)} - {formatTime(end)}
        </p>
      );
    });
  };

  const dayText = (day: string) => {
    const date = parse(day, 'EEEE', new Date());
    const dayName = format(date, 'EEEE', { locale: lng });
    return dayName;
  };

  return (
    <Card className="w-[300px]">
      <CardHeader>
        <CardTitle>{t('activeDays.title')}</CardTitle>
        <CardDescription>
          {t('activeDays.description', { name: organization.name })}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          {Object.keys(days).map((day) => {
            const isActive = days[day].length;
            if (isActive) {
              return (
                <div key={day}>
                  <p className="font-bold">{dayText(day)}</p>
                  <div className="flex gap-5">
                    {formatRange(days[day])}
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </CardContent>
    </Card>
  );
};

export default ActiveDaysCard;
