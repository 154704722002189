import { useState } from 'react';
import { DateRange } from 'react-day-picker';

const useDateRange = ({ column }: { column: any }) => {
  const initialDate = {
    from: undefined,
    to: undefined
  };
  const [date, setDate] = useState<DateRange | undefined>(initialDate);
  const resetDateRange = () => {
    setDate(initialDate);
  };
  const handleOnSelectDate = (formattedDate: DateRange | undefined) => {
    setDate(formattedDate);
    column?.setFilterValue({
      from: formattedDate?.from ? formattedDate.from.getTime() : undefined,
      to: formattedDate?.to ? formattedDate.to.getTime() : undefined
    });
  };
  return {
    date,
    resetDateRange,
    handleOnSelectDate
  };
};

export default useDateRange;
