import { ApiResponse } from 'apisauce';
import { Api } from './api';

interface GetPresignedUrlBody {
  consumer: string;
  contentType: string;
}

export interface GetPresignedUrlDto {
  id: string;
  url: string;
}

export interface GetConsumerImageDto {
  url: string
  id: string
}

export class FileApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getConsumerImage(
    consumerId: string
  ): Promise<GetConsumerImageDto| undefined> {
    const response: ApiResponse<GetConsumerImageDto> = await this.api.apisauce.get(
      `consumer-file/${consumerId}`
    );

    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async deleteConsumerImage(consumerId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(
      `consumer-file/${consumerId}`
    );

    if (!response.ok) {
      throw response.originalError;
    }
  }

  async getPresignedUrl(
    body: GetPresignedUrlBody
  ): Promise<GetPresignedUrlDto | undefined> {
    const response: ApiResponse<GetPresignedUrlDto> = await this.api.apisauce.post(
      `consumer-file/presigned-url`,
      body
    );

    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
