import { Accordion } from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import {
  Form
} from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import formParticipantDataVpSchema from '@/schemas/formParticipantDataVp';
import { useRootStore } from '@/stores/root-store';
import type { StepService } from '@/stores/slices/step-service/step-service.types';
import AccordionFormItem from './components/AccordionFormItem';
import AuthorizedToPickUpForm from './components/AuthorizedToPickUpForm';
import ExperienceForm from './components/ExperienceForm';
import ParentsForm from './components/ParentsForm';
import QuestionsForm from './components/QuestionsForm';
import UserForm from './components/UserForm';
import type {
  FormParticipantDataVpProps,
  TForm
} from './FormParticipantDataVp.types';

const FormParticipantDataVp: React.FC<FormParticipantDataVpProps> = ({
  user,
  organization,
  isPending,
  onSubmit
}) => {
  const { t } = useTranslation();

  const onBack = () => {
    removeStep(currentStep);
  };

  //store
  const removeStep = useRootStore((store) => store.removeStep);
  const stepServicesData: StepService | null = useRootStore(
    (store) => store.stepServicesData
  );
  const minAge = stepServicesData?.service?.minAge;
  const maxAge = stepServicesData?.service?.maxAge;

  const [accordionValue, setAccordionValue] = useState('user');

  const currentStep = useRootStore((store) => store.currentStep);

  const defaultValues = {
    user: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      identification: {
        number: user?.identification?.number || '',
        type: user?.identification?.type || ''
      },
      birthdate: user?.birthdate ? new Date(user.birthdate) : undefined,
      location: '',
      phone: user?.phone || '',
      insurance: '',
      size: '',
      holidayDate: '',
      isAularWorker: undefined,
      isPartner: undefined
    },
    parents: Array.from({ length: 2 }, () => ({
      connection: '',
      identification: {
        number: '',
        type: ''
      },
      firstName: '',
      lastName: '',
      phone: '',
      email: ''
    })),
    authorizedToPickUp: {
      firstName: '',
      lastName: '',
      identification: {
        number: '',
        type: ''
      },
      phone: ''
    },
    experience: '',
    diseases: [
      {
        question:
          '¿Tiene Alguna enfermedad que requiera periódicamente tratamiento o control médico?',
        value: undefined,
        answer: ''
      },
      {
        question: '¿Durante los últimos tres años fue internado alguna vez?',
        value: undefined,
        answer: ''
      },
      {
        question: '¿Tiene alguna alergia?',
        placeholder: t('allergyPlaceholder'),
        value: undefined,
        answer: ''
      }
    ],
    treatments: [
      {
        question: '¿Recibe tratamiento médico?',
        value: undefined,
        answer: ''
      },
      {
        question: '¿Presenta alguna limitacion fisica?',
        value: undefined,
        answer: ''
      },
      {
        question: '¿Otros problemas de salud?',
        value: undefined,
        answer: ''
      }
    ]
  };

  defaultValues.parents[0].email = (user?.email as string) ?? stepServicesData?.email;

  const form = useForm<TForm>({
    resolver: zodResolver(formParticipantDataVpSchema({ minAge, maxAge })),
    defaultValues
  });

  const formError = Boolean(Object.keys(form.formState.errors).length);

  useEffect(() => {
    // if the value arrives from the user, validate them
    const initializeAccordionValue = async () => {
      if (user && stepServicesData?.participants) {
        const isValid = await form.trigger('user');
        if (isValid) setAccordionValue('experience');
      }
  };
    initializeAccordionValue();
  }, []);

  return (
    <Form {...form}>
      <h2 className="font-bold text-primary">
        {stepServicesData?.service?.name}
      </h2>
      <h2 className="font-bold mb-6">{t('stepParticipants.title')}</h2>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <Accordion
          type="single"
          collapsible
          className="space-y-6"
          onValueChange={setAccordionValue}
          value={accordionValue}
        >
          {/* User Form */}
          <AccordionFormItem
            accordionValue="user"
            form={form}
            formField="user"
            setAccordionValue={setAccordionValue}
            description={t('userDescription')}
            header={t('studentData')}
          >
            {(onSave) => (
              <div>
                <UserForm form={form} user={undefined} onSave={onSave} />
              </div>
            )}
          </AccordionFormItem>

          {/* Parents form */}

          <AccordionFormItem
            accordionValue="parents"
            form={form}
            formField="parents"
            setAccordionValue={setAccordionValue}
            header={t('parent')}
            description={t('parentDescription')}
          >
            {(onSave) => (
              <div>
                <ParentsForm
                  form={form}
                  formField="parents"
                  onSave={onSave}
                  // handleBlur={handleBlur}
                />
              </div>
            )}
          </AccordionFormItem>

          {/* authorized to pick up form */}

          <AccordionFormItem
            accordionValue="authorizedToPickUp"
            form={form}
            formField="authorizedToPickUp"
            setAccordionValue={setAccordionValue}
            header={t('authorizedToPickUp')}
            description={t('authorizedToPickUpDescription')}
          >
            {(onSave) => (
              <div>
                <AuthorizedToPickUpForm
                  form={form}
                  formField="authorizedToPickUp"
                  onSave={onSave}
                />
              </div>
            )}
          </AccordionFormItem>

          {/* Experience Form */}
          <AccordionFormItem
            accordionValue="experience"
            form={form}
            formField="experience"
            setAccordionValue={setAccordionValue}
            header={t('levelLabel')}
          >
            {(onSave) => (
              <div>
                <ExperienceForm form={form} onSave={onSave} />
              </div>
            )}
          </AccordionFormItem>

          {/* Diseases Form */}
          <AccordionFormItem
            accordionValue="diseases"
            form={form}
            formField="diseases"
            setAccordionValue={setAccordionValue}
            header={t('diseasesLabel')}
            description={t('diseasesDescription')}
          >
            {(onSave) => (
              <div>
                <QuestionsForm
                  form={form}
                  formField="diseases"
                  onSave={onSave}
                />
              </div>
            )}
          </AccordionFormItem>

          {/* Treatments Form */}
          <AccordionFormItem
            accordionValue="treatments"
            form={form}
            formField="treatments"
            setAccordionValue={setAccordionValue}
            header={t('treatmentsLabel')}
          >
            {(onSave) => (
              <div>
                <QuestionsForm
                  form={form}
                  formField="treatments"
                  onSave={onSave}
                />
              </div>
            )}
          </AccordionFormItem>
        </Accordion>

        {formError && (
          <p className="text-sm font-medium text-destructive">
            {t('stepParticipants.form.errorMessage')}
          </p>
        )}
        <div className="flex flex-col gap-2">
          <Button type="submit" className="w-full" disabled={isPending}>
            {isPending ? (
              <Loader className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              t('stepParticipants.form.continue')
            )}
          </Button>
          <Button
            onClick={() => {
              onBack();
            }}
            type="button"
            variant="outline"
            className="w-full"
          >
            {t('stepParticipants.form.back')}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default FormParticipantDataVp;
