import { Button } from '@/components/ui/button';
import { useRootStore } from '@/stores/root-store';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

interface Sale {
  id: string;
}

const FinalMessage: React.FC = () => {
  const { t } = useTranslation();

  const setStep = useRootStore((store) => store.setStep);
  const cleanStepServicesData = useRootStore(
    (store) => store.cleanStepServicesData
  );

  const queryClient = useQueryClient();
  const saleData = queryClient.getQueryData<Sale>(['createSale']);
  const [saleId, setSaleId] = useState<string | undefined>();

  useEffect(() => {
    if (saleData) setSaleId(saleData.id);
  }, []);

  return (
    <div className="flex flex-col items-center gap-3 text-center">
      <h3 className="mb-6 font-bold">{t('newBooking.finalMessageTitle')}</h3>
      <p>{t('newBooking.finalMessageDescription')}</p>

      <Button
        className="w-full max-w-md mt-10"
        onClick={() => {
          setStep(1);
          cleanStepServicesData();
        }}
      >
        {t('newBooking.newReservation')}
      </Button>
      {saleId ? (
        <Button className="w-full max-w-md" variant="outline" asChild>
          <Link to={`/services-sold/${saleId}`}>
            {t('newBooking.goToThisSale')}
          </Link>
        </Button>
      ) : null}
    </div>
  );
};

export default FinalMessage;
