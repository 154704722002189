import { StoreApi } from "zustand";
import { RootStore } from "@/stores/root-store.types";

export const createCounterSlice = (
  set: StoreApi<RootStore>["setState"],
  get: StoreApi<RootStore>["getState"],
  ...rest: StoreApi<RootStore>[]
) => ({
  count: 2,
  decrementCount: () => set((state) => ({ count: (state.count ?? 0) - 1 })),
  incrementCount: () => set((state) => ({ count: (state.count ?? 0) + 1 })),
  incrementFive: (number: number) =>
    set((state) => ({ count: (state.count ?? 0) + number })),
  getCount: () => (get().count !== null ? get().count : null), // Corrected implementation
});
