import GoBack from '@/components/common/go-back/GoBack';
import FormServicesCategory, {
  ServicesCategoryValues
} from '@/components/forms/form-services-category/FormServicesCategory';
import usePostSports from '@/hooks/queries/sports-api/usePostSports';

const CreateServicesCategory = () => {
  const { mutate, isPending } = usePostSports();

  const onSubmit = (data: ServicesCategoryValues) => {
    mutate({ data });
  };

  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <FormServicesCategory onSubmit={onSubmit} isPending={isPending} />
      </div>
    </>
  );
};

export default CreateServicesCategory;
