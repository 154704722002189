import GenericCard from '@/components/common/cards/generic-card/GenericCard';
import { PaymentStatus } from '@/utils/reservations';
import { currencyFormat } from '@/utils/utils';
import { Check, CircleEllipsis, DollarSign } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ServiceSold } from '../../tables/services-sold-table/services-sold-tables.types';
import { TOrganization } from '@/services/organization';

type TPaymentsStats = {
  sale: ServiceSold;
  organization: TOrganization;
};

const PaymentsStats = ({ sale, organization }: TPaymentsStats) => {
  const { t } = useTranslation();
  const totalPaid = sale.payments.reduce((acc, payment) => {
    return payment.paymentStatus.name === PaymentStatus.Approved
      ? acc + payment.amount
      : acc;
  }, 0);
  return (
    <div className="grid gap-3 mb-4 md:grid-cols-2 lg:grid-cols-3">
      <GenericCard
        title={t('tables.servicesSold.serviceTotal')}
        content={currencyFormat(sale.totalAmount, organization.currency)}
        description={`${sale.service.name} ${currencyFormat(
          sale.service.price,
          organization.currency
        )}.`}
        Icon={DollarSign}
      />
      <GenericCard
        title={t('tables.servicesSold.totalPaid')}
        content={currencyFormat(totalPaid, organization.currency)}
        description={t('tables.servicesSold.amountPendingMessage')}
        Icon={Check}
      />
      <GenericCard
        title={t('tables.servicesSold.amountPending')}
        content={currencyFormat(sale.amountPending, organization.currency)}
        description={t('tables.servicesSold.paymentsMissing')}
        Icon={CircleEllipsis}
      />
    </div>
  );
};

export default PaymentsStats;
