import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import useGetPaginatedReservations from '@/hooks/queries/reservations-api/useGetPaginatedReservation';
import useFormattedDate from '@/hooks/useFormattedDate';
import usePersistedPaginationFiltersAndSorting from '@/hooks/usePersistedPaginationFiltersAndSorting';
import useReservationActions from '@/hooks/useReservationActions';
import { getTimeZone } from '@/utils/dates';
import { getReservationsTexts } from '@/utils/reservations';
import { dateInDataRange, fullName } from '@/utils/utils';
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table';
import { t } from 'i18next';
import { ArrowRight, CalendarClock, X } from 'lucide-react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../error-message/ErrorMessage';
import AlertModal from '../../modals/alert-modal/AlertModal';
import ReprogrameDateModal from '../../modals/reprograme-date-modal/ReprogrameDateModal';
import TableTooltip from '../../tooltip/table-tooltip/TableTooltip';
import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import { ReservationInterface } from '../tables.types';
import TableToolBar from './TableToolBar';

export function ReservationsTable<TData, TValue>() {
  const { formattedDate } = useFormattedDate();

  const {
    pagination,
    setPagination,
    filtering,
    setFiltering,
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters
  } = usePersistedPaginationFiltersAndSorting({
    defaultSorting: [{ id: 'startTime', desc: false }]
  });

  const { data, isLoading, isError, isFetching } = useGetPaginatedReservations({
    pagination,
    filtering,
    sorting,
    columnFilters
  });

  const reservationsData = data?.results;
  const emptyData = !reservationsData || !reservationsData.length;

  const [showCancelReservation, setShowCancelReservation] = useState(false);
  const [showReprogrameModal, setShowReprogrameModal] = useState(false);

  const { data: organization } = useGetOrganization();

  const columns = useMemo<ColumnDef<ReservationInterface>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && 'indeterminate')
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
            disabled={emptyData}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
            disabled={emptyData}
          />
        ),
        enableSorting: false,
        enableHiding: false
      },
      {
        id: 'id',
        accessorKey: 'id',
        header: t('tables.common.id'),
        cell: ({ row }) => {
          const id = row?.original?.id;
          return <TableTooltip text={id} />;
        }
      },
      {
        id: 'sale.user.firstName',
        accessorKey: 'user.firstName',
        accessorFn: (originalRow) =>
          `${originalRow.user.firstName} ${originalRow.user.lastName}`,
        header: t('common.user')
      },
      {
        id: 'consumers',
        accessorKey: 'consumers',
        accessorFn: (originalRow) => {
          const participants = originalRow.consumers;
          const participantsName = participants.map((participant) =>
            fullName({
              firstName: participant.firstName,
              lastName: participant.lastName
            })
          );
          return participantsName.join(', ');
        },
        enableSorting: false,
        header: t('tables.common.participants')
      },
      {
        id: 'sale.saleItems.service.id',
        accessorKey: 'service.name',
        header: t('tables.common.activity'),
        cell: ({ row }) => {
          const reservation = row.original;
          return (
            <Link
              to={{
                pathname: `/services-sold/${reservation?.sale?.id}`
              }}
              className="underline"
            >
              {reservation.service.name}
            </Link>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        }
      },
      {
        id: 'startTime',
        accessorKey: 'date',
        accessorFn: (originalRow) => {
          const tz = organization?.tz ?? '';
          return tz && originalRow?.startTime
            ? formattedDate({
                date: getTimeZone(Number(originalRow.startTime), tz)
              })
            : '-';
        },
        header: t('tables.common.date'),
        filterFn: (row, id, value) =>
          dateInDataRange(new Date(row.getValue(id)), value)
      },
      {
        id: 'reservationStatus.name',
        accessorKey: 'reservationStatus.name',
        accessorFn: (originalRow) =>
          getReservationsTexts(originalRow.reservationStatus.name),
        cell: ({ row }) => {
          const reservation = row.original;
          return (
            <Badge
              variant={reservation.reservationStatus.name}
              className="justify-center h-8 justify-self-end"
            >
              {getReservationsTexts(reservation.reservationStatus.name)}
            </Badge>
          );
        },
        header: t('common.status.status'),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        }
      },
      {
        id: 'actions',
        enableHiding: false,
        cell: ({ row }) => {
          const reservation = row.original;
          return (
            <Link
              to={{
                pathname: `/reservations/${reservation?.id}`
              }}
            >
              <ArrowRight />
            </Link>
          );
        }
      }
    ],
    [emptyData, organization?.tz, formattedDate]
  );

  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data: reservationsData ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),

    // pagination config
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    rowCount: data?.total,
    pageCount: Math.ceil((data?.total || 0) / (data?.limit || 1)),
    manualPagination: true, //turn off client-side pagination

    // Sort configuration
    onSortingChange: setSorting,
    enableMultiSort: false,
    manualSorting: true,
    sortDescFirst: true,
    //filter configuration
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    //row selection
    onRowSelectionChange: setRowSelection,
    initialState: {
      columnVisibility: {
        id: false
      }
    },
    state: {
      columnFilters,
      rowSelection,
      pagination,
      sorting
    }
  });

  const reservationsChecked = table.getSelectedRowModel().rows;
  const reservationSelected = reservationsChecked[0]?.original;

  const { handleClose, handlePropose } = useReservationActions();

  const handleCancelReservation = () => {
    if (reservationsChecked.length) {
      reservationsChecked.forEach((reservation) => {
        const reservationId = reservation?.original?.id;
        handleClose(reservationId);
      });
    }
    table.toggleAllPageRowsSelected(false);
  };

  const handleReschedule = (date: number) => {
    setShowReprogrameModal(false);
    handlePropose(date, reservationSelected?.id);
    table.toggleAllPageRowsSelected(false);
  };
  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <TableToolBar
        table={table}
        filtering={filtering}
        setFiltering={setFiltering}
        isFetching={isFetching}
      />
      {(table.getIsAllPageRowsSelected() || table.getIsSomeRowsSelected()) && (
        <div className="flex items-center justify-start gap-2">
          <Button
            variant={'ghost'}
            onClick={() => {
              setShowCancelReservation(true);
            }}
          >
            <X color="#EF4444" />
          </Button>
          {reservationsChecked.length === 1 ? (
            <Button
              variant={'ghost'}
              onClick={() => {
                setShowReprogrameModal(true);
              }}
            >
              <CalendarClock color="#A855F7" />
            </Button>
          ) : null}
        </div>
      )}

      <TanStackBasicTableTableComponent table={table} isLoading={isLoading} />

      <AlertModal
        open={showCancelReservation}
        onOpenChange={setShowCancelReservation}
        title={t('modals.alert.title')}
        description={t('modals.alert.descriptionCancelReservation')}
        cancelText={t('modals.alert.no')}
        acceptText={t('modals.alert.yes')}
        onAccept={() => handleCancelReservation()}
      />
      {showReprogrameModal ? (
        <ReprogrameDateModal
          open={showReprogrameModal}
          onOpenChange={setShowReprogrameModal}
          onSave={(date) => handleReschedule(date)}
          serviceId={reservationSelected.service?.id}
          participants={reservationSelected.consumers.length}
          startTime={Number(reservationSelected.startTime)}
        />
      ) : null}
    </>
  );
}
