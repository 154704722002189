import { Skeleton } from '@/components/ui/skeleton';
import { PROTECTED_ROUTES } from '@/routes/protected';

export const NavbarSkeleton = () => {
  return (
    <Skeleton className="w-full py-2 bg-slate-200 lg:py-3">
      <div className="flex flex-row items-center justify-between px-7">
        <Skeleton className="h-10 w-28 bg-slate-300" />
        <div className="flex flex-row items-center gap-6">
          <Skeleton className="h-10 w-28 bg-slate-300" />
          <Skeleton className="w-12 h-12 rounded-full bg-slate-300" />
        </div>
      </div>
    </Skeleton>
  );
};

const CallbackPage = () => {
  return (
    <div className="flex flex-col h-screen">
      <NavbarSkeleton />
      <div className="flex flex-row h-screen ">
        <Skeleton className="flex flex-col justify-between shadow-md flex-grow-1">
          <ul className="flex flex-col py-5 ">
            {PROTECTED_ROUTES.map((route) => (
              <li
                className={`flex align-middle hover:text-primary px-7 py-3 justify-start`}
                key={route.path}
              >
                <Skeleton className="w-8 h-8 rounded-full bg-slate-300" />
                <Skeleton className="w-24 ml-3 bg-slate-300" />
              </li>
            ))}
          </ul>
        </Skeleton>
      </div>
    </div>
  );
};
export default CallbackPage;
