import IdentificationTypeSelect from '@/components/common/input/identification-types-select/IdentificationTypeSelect';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const AuthorizedToPickUpForm: React.FC<{
  form: UseFormReturn<any, any, undefined>;
}> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <div className="grid gap-6 md:grid-cols-2" key={`participant-participant`}>
      <FormField
        control={form.control}
        name={`authorizedToPickUp.firstName`}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{t('stepParticipants.form.firstName')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={`authorizedToPickUp.lastName`}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{t('stepParticipants.form.lastName')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={`authorizedToPickUp.phone`}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{t('phone')}</FormLabel>
              <FormControl>
                <Input
                  type="tel"
                  {...field}
                  placeholder={t('common.placeholder.phone')}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={`authorizedToPickUp.identification.type`}
        render={({ field }) => {
          return (
            <FormItem>
              <IdentificationTypeSelect
                defaultValue={field?.value}
                onValueChange={(value) => {
                  field.onChange(value);
                }}
              />
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={`authorizedToPickUp.identification.number`}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>
                {t('stepParticipants.form.identificationNumber')}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder={t('stepParticipants.form.id')}
                  {...field}
                  value={field?.value || ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
    </div>
  );
};

export default AuthorizedToPickUpForm;
