import StudentAttendenceRow from '@/components/common/student-row/StudentAttendenceRow';
import { Skeleton } from '@/components/ui/skeleton';
import useGetReservationsByServiceId from '@/hooks/queries/reservations-api/useGetReservationByServiceId';
import useGetOrganizationServices from '@/hooks/queries/services-api/useGetOrganizationServices';
import type { TServices } from '@/services/services';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

const Attendance = () => {
  const { t } = useTranslation();
  const today = format(new Date(), "d 'de' MMMM", { locale: es });

  const { data: services, isLoading: isLoadingServices } =
    useGetOrganizationServices<TServices[]>();

  const SERVICE_ID =
    services?.filter((item) => item.enabled === true)[0]?.id || null;

  const { data, isLoading: isLoadingReservations } =
    useGetReservationsByServiceId(SERVICE_ID, true);

  if (isLoadingServices || isLoadingReservations) {
    return (
      <div>
        <Skeleton className="w-60 h-8" />
        <Skeleton className="w-56 h-6 mt-1" />

        <div className="flex flex-col gap-2 mt-5">
          <Skeleton className="w-full h-14" />
          <Skeleton className="w-full h-14" />
          <Skeleton className="w-full h-14" />
        </div>
      </div>
    );
  }

  if (!SERVICE_ID) {
    return <p>{t('noServiceFound')}</p>;
  }

  return (
    <div>
      <h1 className="text-2xl font-bold">{t('activity.student.attendance')}</h1>
      <p className="mt-1">{today}</p>
      <div className="flex flex-col gap-2 mt-5">
        {data?.map((item) => (
          <StudentAttendenceRow
            key={item.id}
            reservationId={item.id}
            students={item.consumers}
            status={item.reservationStatus.name}
          />
        ))}
      </div>
    </div>
  );
};

export default Attendance;
