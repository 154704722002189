import { Button } from '@/components/ui/button';
import { ArrowLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const GoBack = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button variant={'ghost'} asChild className="mb-4">
      <Link
        to={'..'}
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
        className="flex items-center gap-2 "
      >
        <ArrowLeft />
        {t('common.back')}
      </Link>
    </Button>
  );
};

export default GoBack;
