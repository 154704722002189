import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetConsumer = () => {
  return useQuery({
    queryKey: ['consumers'],
    queryFn: () => api.consumer.getConsumers(),
    retry: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
};
export default useGetConsumer;
