import GoBack from '@/components/common/go-back/GoBack';
import StudentAttendanceDays from '@/components/common/student-attendance/StudentAttendanceDays';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import useGetConsumer from '@/hooks/queries/consumer-api/useGetConsumerById';
import { differenceInYears } from 'date-fns';
import { ChevronRight, Pencil } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const StudentView: React.FC = () => {
  const { t } = useTranslation();
  const { id: consumerId } = useParams();
  const { data: consumer, isPending } = useGetConsumer(consumerId);
  let additionalData = consumer?.additionalData;
  if (typeof consumer?.additionalData === 'string')
    additionalData = JSON.parse(consumer?.additionalData ?? '');

  const dateConsumer =
    consumer?.birthdate !== undefined
      ? new Date(consumer.birthdate)
      : new Date();
  const day = String(dateConsumer.getDate()).padStart(2, '0');
  const month = String(dateConsumer.getMonth() + 1).padStart(2, '0');
  const year = dateConsumer.getFullYear();
  const birthdateConsumer = `${day}/${month}/${year}`;

  return (
    <>
      <GoBack />
      <div className="">
        {/* Header */}
        <div className="mb-6">
          <div className="flex items-center gap-2 text-muted-foreground">
            <span>{t('activity.student.students')}</span>
            <ChevronRight className="w-4 h-4" />
            {isPending ? (
              <>...</>
            ) : (
              <span>{`${consumer?.firstName} ${consumer?.lastName}`}</span>
            )}
          </div>
        </div>

        <Card className="w-full p-8 mb-6 border-none bg-accent">
          {/* Personal Data Section */}
          <div className="flex items-start justify-between mb-8">
            <h2 className="text-xl font-semibold">
              {t('activity.student.personalData')}
            </h2>
            <Button asChild variant="outline" size="sm">
              <Link to={'edit'}>
                <Pencil className="w-4 h-4 mr-2" />
                {t('form.edit')}
              </Link>
            </Button>
          </div>
          {isPending ? (
            <>
              {[...Array(12)].map((_, index) => (
                <div key={index} className="flex gap-12 mb-6">
                  <Skeleton className="w-full h-6 bg-slate-300 justify-self-center" />
                  <Skeleton className="w-full h-6 bg-slate-300 justify-self-center" />
                </div>
              ))}
            </>
          ) : (
            <>
              <div className="grid gap-8 mb-8 md:grid-cols-2">
                <div className="space-y-4">
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.lastName')}:
                    </p>
                    <p>{consumer?.lastName}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.firstName')}:
                    </p>
                    <p>{consumer?.firstName}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {consumer?.identification.type}:
                    </p>
                    <p>{consumer?.identification.number}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.birthDate')}:
                    </p>
                    <p>{birthdateConsumer}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.address')}:
                    </p>
                    <p>{consumer?.location}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.age')}:
                    </p>
                    <p>
                      {consumer
                        ? differenceInYears(
                            new Date(),
                            new Date(consumer?.birthdate)
                          )
                        : additionalData.age}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.shirtSize')}:
                    </p>
                    <p>{additionalData?.size?.toUpperCase()}</p>
                  </div>
                </div>

                <div className="space-y-4">
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.contacts')}:
                    </p>
                    {additionalData?.parents?.map(
                      (parent: {
                        idetification: { number: string };
                        firstName: string;
                        lastName: string;
                        connection: string;
                        identification: { number: string };
                        phone: string;
                        email: string;
                      }) => (
                        <div
                          key={parent.identification?.number}
                          className="mt-2"
                        >
                          <p>
                            {`${t('activity.student.contactName')}: ${parent.firstName} ${parent.lastName}`}
                          </p>
                          <p>{`${t('activity.student.contactRelation')}: ${parent.connection}`}</p>
                          <p>{`${t('activity.student.contactDNI')}: ${parent.identification.number}`}</p>
                          <p>{`${t('activity.student.contactPhone')}: ${parent.phone}`}</p>
                          <p>{`${t('activity.student.contactEmail')}: ${parent.email}`}</p>
                        </div>
                      )
                    )}
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.medicalCoverage')}:
                    </p>
                    <p>{additionalData?.insurance}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.waterSkillLevel')}:
                    </p>
                    <p>{additionalData?.experience}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.vacationPeriod')}:
                    </p>
                    <p>{additionalData?.holidayDate}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.parentAluarEmployee')}:
                    </p>
                    <p>{`${additionalData?.isAularWorker ? t('form.yes') : t('form.no')}`}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.clubMember')}:
                    </p>
                    <p>
                      <p>{`${additionalData?.isPartner ? t('form.yes') : t('form.no')}`}</p>
                    </p>
                  </div>
                </div>
              </div>

              {/* Authorized Pickup Section */}
              <div className="mb-8">
                <h2 className="mb-4 text-xl font-semibold">
                  {t('activity.student.authorizedPickup')}
                </h2>
                <div className="space-y-2">
                  <p>
                    {`${t('activity.student.authorizedName')}: ${additionalData?.authorizedToPickUp?.firstName} ${additionalData?.authorizedToPickUp?.lastName}`}
                  </p>
                  <p>
                    {`${t('activity.student.authorizedDNI')}: ${additionalData?.authorizedToPickUp?.identification?.number}`}
                  </p>
                  <p>
                    {`${t('activity.student.authorizedPhone')}: ${additionalData?.authorizedToPickUp?.phone}`}
                  </p>
                </div>
              </div>

              {/* Medical History Section */}
              <div className="mb-8">
                <h2 className="mb-4 text-xl font-semibold">
                  {t('activity.student.backgroundAndTreatments')}
                </h2>
                <div className="space-y-2">
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.periodicTreatment')}:
                    </p>
                    <p>{additionalData?.diseases[0]?.value ? 'Sí' : 'No'}</p>
                    <p>{`${additionalData?.diseases[0]?.value ? t('activity.student.periodicTreatmentDetails') + ': ' + additionalData?.diseases[0]?.answer : ''}`}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.recentHospitalization')}:
                    </p>
                    <p>{additionalData?.diseases[1]?.value ? 'Sí' : 'No'}</p>
                    <p>{`${additionalData?.diseases[1]?.value ? t('activity.student.hospitalizationReason') + ': ' + additionalData?.diseases[1]?.answer : ''}`}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.allergy')}:
                    </p>
                    <p>{additionalData?.diseases[2]?.value ? 'Sí' : 'No'}</p>
                    <p>{`${additionalData?.diseases[2]?.value ? t('activity.student.allergyDetails') + ': ' + additionalData?.diseases[2]?.answer : ''}`}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.medicalTreatment')}:
                    </p>
                    <p>{additionalData?.treatments[0]?.value ? 'Sí' : 'No'}</p>
                    <p>{`${additionalData?.treatments[0]?.value ? t('activity.student.medicalTreatmentDetails') + ': ' + additionalData?.treatments[0]?.answer : ''}`}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.physicalLimitation')}:
                    </p>
                    <p>{additionalData?.treatments[1]?.value ? 'Sí' : 'No'}</p>
                    <p>{`${additionalData?.treatments[1]?.value ? t('activity.student.physicalLimitationDetails') + ': ' + additionalData?.treatments[1]?.answer : ''}`}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">
                      {t('activity.student.otherHealthIssues')}:
                    </p>
                    <p>{additionalData?.treatments[2]?.value ? 'Sí' : 'No'}</p>
                    <p>{`${additionalData?.treatments[2]?.value ? t('activity.student.otherHealthIssuesDetails') + ': ' + additionalData?.treatments[2]?.answer : ''}`}</p>
                  </div>
                </div>
              </div>

              {/* Payments Section */}
              {/* <div className="mb-8">
          <h2 className="mb-4 text-xl font-semibold text-red-500">
            {t('activity.student.pendingPayments')}
          </h2>
          <div className="space-y-2">
            <p>{t('activity.student.totalPaid')}: $10</p>
            <p>{t('activity.student.pendingAmount')} $12312</p>
          </div>
        </div> */}
            </>
          )}

          {/* Attendance Section */}
          <div>
            <h2 className="mb-4 text-xl font-semibold">
              {t('activity.student.attendance')}
            </h2>
            <StudentAttendanceDays
              consumerId={consumerId}
              disabledDays={true}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default StudentView;
