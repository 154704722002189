import { addMonths, differenceInMonths, format, parse } from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';

export const getTimeZone = (date: string | number | Date, tz: string) => {
  if (!tz || !date) {
    throw new Error(`Invalid time zone or date provided`);
  }
  return dateFnsTz.toZonedTime(date, tz);
};
// Cambiar el nombre de esta funcion?
export function getTimestampUTC({
  date,
  time,
  tz
}: {
  date: Date;
  tz: string;
  time?: string;
}) {
  if (!date || !tz) {
    throw new Error(`Internal. Invalid time zone or date provided`);
  }
  if (time) {
    date.setHours(parseInt(time.split(':')[0])); // Set the hour
    date.setMinutes(parseInt(time.split(':')[1])); // Set the minutes
  }

  const parseDate: any = format(date, 'y M d H m')
    .split(' ')
    .map((value) => Number(value)); // parse date to Number[year, month, day, hour, minute]
  return (
    Date.UTC(
      parseDate[0], // year
      parseDate[1] - 1, // Month, start in 0 to 11
      parseDate[2], // Day
      parseDate[3], // Hour
      parseDate[4] // Minute
    ) - dateFnsTz.getTimezoneOffset(tz, date)
  ); // return date UTC-02
}
export function getRemainingMonths(targetDate: Date | string) {
  const today = new Date();
  let monthsDifference = differenceInMonths(targetDate, today);

  // Adjust for partial months
  const adjustedToday = addMonths(today, monthsDifference);
  if (adjustedToday < targetDate) {
    monthsDifference += 1;
  }

  return monthsDifference;
}
export const parseDate = (dateStr: string) =>
  parse(dateStr, 'dd-MM-yyyy', new Date());
