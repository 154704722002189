import {
  type ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import useGetPaginatedReservations from '@/hooks/queries/reservations-api/useGetPaginatedReservation';
import usePersistedPaginationFiltersAndSorting from '@/hooks/usePersistedPaginationFiltersAndSorting';
import { cn, getAgeRange } from '@/lib/utils';
import { differenceInYears, format, isValid } from 'date-fns';
import { ArrowRight, CalendarIcon, Loader } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../error-message/ErrorMessage';
import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import type { ReservationInterface } from '../tables.types';
import { exportAttendanceToExcel, exportToExcel } from './exportToExcel';
import useGetOrganizationServices from '@/hooks/queries/services-api/useGetOrganizationServices';
import type { TServices } from '@/services/services';
import PresentButtons from './PresentButtons';
import TableToolBar from './TableToolBar';
import useCalendarPaginateNavigation from '@/hooks/useCalendarPaginateNavigation';

export type Student = {
  estado: string;
  alumno: string;
  presente: boolean;
  contacto: string;
  fechaNacimiento: Date;
  observaciones: string;
};

const getTodayDateFormatted = (date: Date | undefined): string => {
  if (!date || !isValid(date)) {
    return 'Fecha no válida';
  }
  return format(date, 'dd/MM');
};
const StudentTables: React.FC = () => {
  const { t } = useTranslation();

  const { data: services } = useGetOrganizationServices<TServices[]>();
  const SERVICE_ID =
    services?.filter((item) => item.enabled === true)[0].id || [];

  const {
    pagination,
    setPagination,
    filtering,
    setFiltering,
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters
  } = usePersistedPaginationFiltersAndSorting({
    defaultSorting: [{ id: 'startTime', desc: false }]
  });

  const { date, setDay } = useCalendarPaginateNavigation(new Date());
  const TIMESTAMP_INIT = date
    ? new Date(date.setHours(0, 0, 0, 0)).getTime()
    : new Date().getTime();
  const TIMESTAMP_END = date
    ? new Date(date.setHours(23, 59, 59, 999)).getTime()
    : new Date().getTime();

  const {
    data: attendance,
    isLoading,
    isError,
    isFetching
  } = useGetPaginatedReservations({
    pagination,
    filtering,
    sorting,
    columnFilters: [
      ...columnFilters,
      { id: 'sale.service.id', value: SERVICE_ID },
      { id: 'startTime', value: { from: TIMESTAMP_INIT, to: TIMESTAMP_END } }
    ]
  });

  const [rowSelection, setRowSelection] = useState({});
  const reservationsData = attendance?.results;

  const handleExport = async () => {
    if (reservationsData) {
      exportToExcel(reservationsData);
    }
  };

  const { data: attendanceOfAllDays } = useGetPaginatedReservations({
    pagination,
    filtering,
    sorting,
    columnFilters: [
      ...columnFilters,
      { id: 'sale.service.id', value: SERVICE_ID }
    ]
  });

  const handleExportAttendance = async () => {
    if (attendanceOfAllDays?.results) {
      exportAttendanceToExcel(attendanceOfAllDays?.results);
    }
  };

  const columns = useMemo<ColumnDef<ReservationInterface>[]>(
    () => [
      // {
      //   id: 'estado',
      //   accessorKey: 'estado',
      //   header: t('activity.table.state'),
      //   cell: ({ row }) => (
      //     <StudentStatusBadge status={row.getValue('estado') as StatusType} />
      //   )
      // },
      {
        id: 'consumers.firstName',
        accessorKey: 'consumers.firstName',
        enableSorting: false,
        accessorFn: (originalRow) =>
          originalRow.consumers
            ? `${originalRow.consumers[0]?.firstName} ${originalRow.consumers[0]?.lastName}`
            : undefined,
        header: t('activity.table.student')
      },
      {
        id: 'reservationStatus.name',
        accessorKey: 'reservationStatus.name',
        meta: { headerText: t('activity.table.attend') },
        enableSorting: false,
        header: () => (
          <div className="flex items-center gap-2">
            <span>{`${t('activity.table.attend')} (${getTodayDateFormatted(date)})`}</span>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    'w-[40px] p-0 hover:bg-muted',
                    date && 'text-primary'
                  )}
                >
                  <CalendarIcon className="h-4 w-4" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={date}
                  disabled={date}
                  defaultMonth={date}
                  onSelect={(selectedDate) => {
                    if (selectedDate) setDay(selectedDate);
                  }}
                />
              </PopoverContent>
            </Popover>
          </div>
        ),
        cell: ({ row }) => {
          const reservation = row.original;
          const status = reservation.reservationStatus.name;
          return (
            <PresentButtons status={status} reservationId={reservation.id} />
          );
        },
        enableColumnFilter: true
      },
      {
        id: 'contact',
        accessorKey: 'contact',
        header: t('activity.table.contact'),
        enableSorting: false,
        accessorFn: (originalRow) => {
          const additionalData = originalRow.consumers[0]?.additionalData;

          return additionalData
            ? `${additionalData?.authorizedToPickUp?.firstName} ${additionalData?.authorizedToPickUp?.firstName}`
            : undefined;
        }
      },
      {
        id: 'sale.saleConsumers.consumer.birthdate',
        accessorKey: 'sale.saleConsumers.consumer.birthdate',
        accessorFn: (originalRow) => {
          return originalRow.consumers[0]?.birthdate
            ? getAgeRange(new Date(originalRow.consumers[0]?.birthdate))
            : undefined;
        },
        header: t('activity.student.group')
      },
      {
        id: 'group',
        accessorKey: 'sale.saleConsumers.consumer.birthdate',
        accessorFn: (originalRow) => {
          return originalRow.consumers[0]?.birthdate
            ? differenceInYears(
                new Date(),
                new Date(originalRow.consumers[0]?.birthdate)
              )
            : undefined;
        },
        header: t('activity.student.age')
      },
      {
        id: 'obvervations',
        accessorKey: 'obvervations',
        enableSorting: false,
        header: t('activity.table.observation'),
        accessorFn: (originalRow) => {
          const additionalData = originalRow.consumers[0]?.additionalData;
          return additionalData ? additionalData?.obvervations : undefined;
        }
      },
      {
        id: 'actions',
        enableHiding: false,
        cell: ({ row }) =>
          row.original.consumers && (
            <Link
              to={`students/${row.original.consumers[0]?.id}`}
              className="w-full"
            >
              <ArrowRight />
            </Link>
          )
      }
    ],
    [date, t]
  );

  const table = useReactTable({
    data: reservationsData ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),

    // pagination config
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    rowCount: attendance?.total,
    pageCount: Math.ceil((attendance?.total || 0) / (attendance?.limit || 1)),
    manualPagination: true, //turn off client-side pagination

    // Sort configuration
    onSortingChange: setSorting,
    enableMultiSort: false,
    manualSorting: true,
    sortDescFirst: true,
    //filter configuration
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    //row selection
    onRowSelectionChange: setRowSelection,
    initialState: {
      columnVisibility: {
        id: false
      }
    },
    state: {
      columnFilters,
      rowSelection,
      pagination,
      sorting
    }
  });

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <div className="space-y-2">
      <div className="w-full flex gap-2 items-center mt-8">
        <Button onClick={handleExport}>Descargar datos de alumnos</Button>
        <Button onClick={handleExportAttendance}>
          Descargar planilla de asistencias
        </Button>
      </div>
      <TableToolBar
        table={table}
        filtering={filtering}
        setFiltering={setFiltering}
        isFetching={isFetching}
      />
      <TanStackBasicTableTableComponent table={table} isLoading={isLoading} />
    </div>
  );
};

export default StudentTables;
