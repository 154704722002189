import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import FormInventory, {
  InventoryValues
} from '@/components/forms/form-inventory/FormInventory';
import { Skeleton } from '@/components/ui/skeleton';
import useGetEquipmentTypes from '@/hooks/queries/equipments-api/useGetEquipmentTypes';
import usePostEquipment from '@/hooks/queries/equipments-api/usePostEquipment';

const CreateInventory = () => {
  const { mutate, isPending } = usePostEquipment();

  const onSubmit = (data: InventoryValues) => {
    mutate({ data });
  };
  const {
    data: inventoryCategories,
    isLoading,
    isError
  } = useGetEquipmentTypes();

  if (isLoading) {
    return <Skeleton className="w-full h-2/3" />;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  if (!inventoryCategories?.length) {
    return <NoData />;
  }

  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <FormInventory
          onSubmit={onSubmit}
          isPending={isPending}
          inventoryCategories={inventoryCategories}
        />
      </div>
    </>
  );
};

export default CreateInventory;
