import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetEquipmentsAvailability = (equipmentId: string) => {
  return useQuery({
    queryKey: ['equipmentAvailability', equipmentId],
    queryFn: () => api.equipments.getEquipmentsAvailability(equipmentId)
  });
};
export default useGetEquipmentsAvailability;
