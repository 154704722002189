import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetEquipmentTypes = () => {
  return useQuery({
    queryKey: ['equipmentTypes'],
    queryFn: () => api.equipments.getEquipmentTypes()
  });
};
export default useGetEquipmentTypes;
