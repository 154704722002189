import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/components/ui/accordion';
import { AlertTriangle, CheckCircle2 } from 'lucide-react';
import { type SetStateAction, useEffect, useRef, useState } from 'react';
import type { FieldPath, UseFormReturn } from 'react-hook-form';
import type { TForm } from '../FormParticipantDataVp.types';

const AccordionFormItem: React.FC<{
  accordionValue: FieldPath<TForm>;
  form: UseFormReturn<any, any, undefined>;
  formField: FieldPath<TForm>;
  setAccordionValue: (value: SetStateAction<string>) => void;
  children: (onSave: () => Promise<void>) => React.ReactElement;
  header: string;
  description?: string;
}> = ({
  accordionValue,
  form,
  formField,
  setAccordionValue,
  children,
  header,
  description
}) => {
  const headerRef = useRef<HTMLHeadingElement>(null);
  const [isError, setIsError] = useState(false);
  const [isAllValid, setIsAllValid] = useState(true);

  const formValue = form.getValues()[formField];
  const firstName = formValue?.firstName || '';
  const lastName = formValue?.lastName || '';
  const field = formField;
  const isInvalid = form.getFieldState(field).invalid;
  const isValidated = form.getFieldState(field).isValidating;
  const isReady = isAllValid && !isInvalid && isValidated && !isError;

  const onSave = async () => {
    const isValid = await form.trigger(field);
    if (isValid) {
      // scroll to the header
      if (headerRef?.current) {
        headerRef.current.scrollIntoView({ behavior: 'smooth' });
      }

      setIsAllValid(true);
      setIsError(false);
      setAccordionValue('');
    } else {
      setIsError(true);
      setIsAllValid(false);
    }
  };

  // Validate the parents user field
  useEffect(() => {
    const validateField = async () => {
      if (formField === 'parents') {
        const { errors } = await form.control._executeSchema([]);
        if (errors) {
          const fieldErrors = (errors as any)[formField];
          const isValid = fieldErrors ? !Object.keys(fieldErrors).length : true;
          setIsAllValid(isValid);
        }
      }
    };
    validateField();
  }, [form.getValues().parents[0].email]);

  const renderHeader = () => {
    const head = header;
    const name =
      formField !== 'diseases' && formField !== 'treatments' ? (
        <span className="text-sm text-muted-foreground">
          {formField === 'user' ||
          formField === 'parents' ||
          formField === 'authorizedToPickUp'
            ? `${firstName} ${lastName}`
            : `${formValue}`}
        </span>
      ) : undefined;

    const desc = <span className="text-xs">{description}</span>;

    if (isReady) {
      return (
        <div className="pb-4">
          <AccordionTrigger
            className="pb-0 text-green-600"
            rightcomponent={<CheckCircle2 className=" shrink-0 h-4 w-4" />}
          >
            {head}
          </AccordionTrigger>
          <div className="flex flex-col gap-1">
            {desc}
            {name}
          </div>
        </div>
      );
    }

    if (isInvalid) {
      return (
        <div className="pb-4">
          <AccordionTrigger
            className="pb-0 text-red-600"
            rightcomponent={
              <AlertTriangle className="text-red-600 shrink-0 h-4 w-4" />
            }
          >
            {head}
          </AccordionTrigger>
          <div className="flex flex-col gap-1">
            {desc}
            {name}
          </div>
        </div>
      );
    }
    return (
      <div className="pb-4 flex flex-col">
        <AccordionTrigger className="pb-0">{header}</AccordionTrigger>
        {desc}
        {name}
      </div>
    );
  };

  return (
    <AccordionItem value={accordionValue}>
      <div ref={headerRef}>{renderHeader()}</div>
      <AccordionContent>{children(onSave)}</AccordionContent>
    </AccordionItem>
  );
};

export default AccordionFormItem;
