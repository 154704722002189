import { useTranslation } from 'react-i18next';

const AvailabilityReference = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center gap-2">
      <div className="flex align-middle">
        <div className="w-3 h-3 bg-[#D4F5DD] rounded-full" />
        <p className="ml-2 text-xs text-gray-500">
          {t('availabilityReference.fullAvailability')}
        </p>
      </div>
      <div className="flex align-middle">
        <div className="w-3 h-3 bg-[#ffffba] rounded-full" />
        <p className="ml-2 text-xs text-gray-500">
          {t('availabilityReference.mostlyAvailability')}
        </p>
      </div>
      <div className="flex align-middle">
        <div className="w-3 h-3 bg-[#F2DEA2] rounded-full" />
        <p className="ml-2 text-xs text-gray-500">
          {t('availabilityReference.mostlyBlocked')}
        </p>
      </div>
      <div className="flex align-middle">
        <div className="w-3 h-3 bg-[#F2CDC4] rounded-full" />
        <p className="ml-2 text-xs text-gray-500">
          {t('availabilityReference.fullyBlocked')}
        </p>
      </div>
    </div>
  );
};
export default AvailabilityReference;
