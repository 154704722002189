import IdentificationTypeSelect from '@/components/common/input/identification-types-select/IdentificationTypeSelect';
import CalendarModal from '@/components/common/modals/calendar-modal/CalendarModal';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import formUserDetailSchema from '@/schemas/userDetail-form';
import { maxHeight, maxWeight, minHeight, minWeight } from '@/utils/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { TForm, TFormUserUpdate } from './FormUserUpdate.type';

const FormUserUpdate = ({ user, onSubmit, isSending }: TFormUserUpdate) => {
  const isConsumer = !!user?.user;
  const form = useForm<z.infer<TForm>>({
    resolver: zodResolver(formUserDetailSchema()),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      birthdate: user?.birthdate ? new Date(user?.birthdate) : undefined,
      identification: {
        number: user.identification?.number ? user.identification?.number : '',
        type: user.identification?.type ? user.identification?.type : ''
      },
      address: user?.address ?? '',
      location: user?.location ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
      postalCode: user?.postalCode ?? '',
      height: user?.height ?? 0,
      weight: user?.weight ?? 0,
      contactOrigin: '',
      emergencyContact: {
        fullName: user?.emergencyContact?.fullName ?? '',
        phone: user?.emergencyContact?.phone ?? ''
      }
    }
  });

  const hasChanged = !form.formState.isDirty;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={`bg-accent p-8 rounded-md`}
      >
        <div className={` grid grid-cols-1 gap-5 md:grid-cols-2`}>
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.firstName')}</FormLabel>
                <FormControl>
                  <Input placeholder="Ana" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.lastName')}</FormLabel>
                <FormControl>
                  <Input placeholder="Gomez" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="birthdate"
            render={({ field }) => {
              return (
                <FormItem className="flex flex-col mt-3">
                  <FormLabel>{t('form.birth')}</FormLabel>
                  <CalendarModal
                    value={field.value}
                    onAccept={field.onChange}
                    trigger={() => form.trigger(['birthdate'])}
                    dropDownPicker
                  />
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="identification.type"
            render={({ field }) => (
              <FormItem>
                <IdentificationTypeSelect
                  defaultValue={field?.value}
                  onValueChange={field.onChange}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="identification.number"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('identificationTypes.identificationNumber')}
                </FormLabel>
                <FormControl>
                  <Input placeholder={t('form.id')} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.address')}</FormLabel>
                <FormControl>
                  <Input type="string" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="location"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.city')}</FormLabel>
                <FormControl>
                  <Input type="string" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.email')}</FormLabel>
                <FormControl>
                  <Input type="email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.phone')}</FormLabel>
                <FormControl>
                  <Input
                    type="tel"
                    {...field}
                    placeholder={t('common.placeholder.phone')}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="postalCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.postalCode')}</FormLabel>
                <FormControl>
                  <Input type="string" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="weight"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.weight')}</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    step="1"
                    min={field.value != 0 ? minWeight : undefined}
                    max={maxWeight}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="height"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.height')}</FormLabel>
                <FormControl>
                  <Input
                    placeholder="150 cm"
                    pattern="\d+(\.\d{1,2})?"
                    type="number"
                    step="1"
                    min={field.value != 0 ? minHeight : undefined}
                    max={maxHeight}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="emergencyContact.fullName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.emergencyName')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="emergencyContact.phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.emergencyPhone')}</FormLabel>
                <FormControl>
                  <Input
                    type="tel"
                    {...field}
                    placeholder={t('common.placeholder.phone')}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-end">
          <Button
            type="submit"
            className="mt-8"
            disabled={isSending || hasChanged}
          >
            {isSending ? (
              <Loader className="w-4 h-4 animate-spin" />
            ) : (
              t('form.save')
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default FormUserUpdate;
