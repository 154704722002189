import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';

const Pagination = ({
  actualPage,
  totalPages,
  onPreviousPage,
  onNextPage,
  isPreviousPageAvailable,
  isNextPageAvailable,
  results
}: {
  actualPage: number;
  totalPages: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  isPreviousPageAvailable: boolean;
  isNextPageAvailable: boolean;
  results: boolean;
}) => {
  const { t } = useTranslation();
  if (!results) return null;
  return (
    <div className="px-4 my-8 flex justify-between items-center">
      <div className="flex items-center gap-1">
        <p className="text-sm">{t('pagination.page')}</p>
        <p className="text-sm">
          {actualPage} {t('pagination.of')} {totalPages}
        </p>
      </div>
      <div className="flex justify-end ">
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={onPreviousPage}
            disabled={!isPreviousPageAvailable}
          >
            {t('pagination.previous')}
          </Button>

          <Button
            variant="outline"
            size="sm"
            onClick={onNextPage}
            disabled={!isNextPageAvailable}
          >
            {t('pagination.next')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
