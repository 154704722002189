import ChooseServiceStep from '@/components/section/new-booking/choose-service-step/ChooseServiceStep';
import ParticipantsDataStep from '@/components/section/new-booking/participants-data-step/ParticipantsDataStep';
import Stepper from '@/components/section/new-booking/stepper/Stepper';
import type { TOrganizationFAQ } from '@/services/organization';
import { useRootStore } from '@/stores/root-store';
import { useCallback, useEffect } from 'react';
import RegistrationSummary from '../registration-summary/RegistrationSummary';

const NewBookingSectionVp: React.FC<{ organization: TOrganizationFAQ }> = ({
  organization
}) => {
  const currentStep = useRootStore((store) => store.currentStep);
  const organizationId = useRootStore((state) => state.organizationId);
  const setStep = useRootStore((store) => store.setStep);
  const setInIframe = useRootStore((store) => store.setInIframe);
  const cleanStepServicesData = useRootStore(
    (store) => store.cleanStepServicesData
  );
  const setOrganizationId = useRootStore((store) => store.setOrganizationId);
  const isDataExpired = useRootStore((store) => store.isDataExpired);

  const iniFrame = useCallback(() => {
    if (window.location !== window.parent.location) {
      // The page is in an iFrames
      setInIframe(true);
    } else {
      // The page is not in an iFrame
      setInIframe(false);
    }
  }, [setInIframe]);

  useEffect(() => {
    iniFrame();
  }, [iniFrame]);

  useEffect(() => {
    const isExpired = isDataExpired();
    if (isExpired) {
      cleanStepServicesData();
      setStep(1);
    }
  }, [isDataExpired, cleanStepServicesData, setStep]);

  useEffect(() => {
    if (organization && organizationId !== organization.id) {
      setStep(1);
      cleanStepServicesData();
      setOrganizationId(organization.id);
    }
  }, [organization]);

  // scroll to top when the step changes
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return (
    <div>
      <Stepper totalSteps={3} />
      <div className="mt-8 mb-14 md:container">
        {currentStep === 1 && <ChooseServiceStep organization={organization} />}
        {currentStep === 2 && (
          <ParticipantsDataStep organization={organization} />
        )}
        {currentStep === 3 && (
          <RegistrationSummary organization={organization} />
        )}
      </div>
    </div>
  );
};

export default NewBookingSectionVp;
