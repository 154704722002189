import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';
import { PlusCircleIcon } from 'lucide-react';
import { useState } from 'react';

interface Option {
  label: string;
  value: string;
  minDate: Date | undefined;
  maxDate: Date | undefined;
}

interface GroupFilterProps {
  disabled?: boolean;
  title: string;
  options: Option[];
  onSelect: (option: Option) => void;
  resetDateRange: () => void
  column: any;
}

const GroupFilter = ({
  disabled,
  title,
  options,
  onSelect,
  resetDateRange,
  column
}: GroupFilterProps) => {
  const [selectedValues, setSelectedValues] = useState('');

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          disabled={disabled}
          className="flex items-center justify-start w-full"
        >
          <PlusCircleIcon className="w-4 h-4 mr-2" />
          {title}
          {selectedValues && (
            <>
              <Separator orientation="vertical" className="h-4 mx-2" />

              <Badge
                variant="secondary"
                className="px-1 font-normal rounded-sm lg:hidden"
              >
                {1}
              </Badge>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-[200px] p-2 bg-white"
        align="start"
        side="right"
      >
        <div className="grid gap-2">
          {options.map((option) => (
            <Button
              key={option.value}
              onClick={() => {

                if (option.value === selectedValues) {
                  setSelectedValues('');
                  resetDateRange()
                  column?.setFilterValue(undefined)
                  return;
                }

                setSelectedValues(option.value);
                onSelect(option);
              }}
              variant={option.value === selectedValues ? 'default' : 'outline'}
            >
              {option.label}
            </Button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default GroupFilter;
