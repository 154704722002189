import { RouterProvider } from 'react-router-dom';
import { Provider as RollbarProvider } from '@rollbar/react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { router } from './routes';
import './i18n';
import { Toaster } from './components/ui/sonner';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { toast } from 'sonner';
import { rollbarConfig } from './utils/rollbar';
import { ErrorBoundaryRollbar } from './components/common/errors/ErrorBoundary';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      //For server errors we render toast with global message.
      if (error.request.status >= 500) {
        toast.error(`Something went wrong. Please try again later.`);
      }
      // 🎉 only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined) {
        toast.error(`Something went wrong: ${query.queryKey}`);
      }
    }
  })
});

function App() {
  return (
    <RollbarProvider config={rollbarConfig}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundaryRollbar>
          <ReactQueryDevtools initialIsOpen={false} />
          <RouterProvider router={router} />
          <Toaster richColors toastOptions={{}} closeButton />
        </ErrorBoundaryRollbar>
      </QueryClientProvider>
    </RollbarProvider>
  );
}

export default App;
