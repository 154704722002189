import ServiceSoldCardVP from '@/components/common/cards/service-sold-card-vp/ServiceSoldCard';
import ServiceSoldCard from '@/components/common/cards/service-sold-card/ServiceSoldCard';
import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import AddPaymentModal from '@/components/common/modals/add-payment-modal/AddPaymentModal';
import { CancelSaleValues } from '@/components/common/modals/cancel-sale-modal/cancel-sale-modal.types';
import CancelSaleModal from '@/components/common/modals/cancel-sale-modal/CancelSaleModal';
import NoData from '@/components/common/no-data/NoData';
import { Skeleton } from '@/components/ui/skeleton';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import usePostPayments from '@/hooks/queries/payments-api/usePostPayments';
import useGetSale from '@/hooks/queries/sales-api/useGetSale';
import usePatchCancelSale from '@/hooks/queries/sales-api/usePatchSale';

import { useState } from 'react';
import { useParams } from 'react-router-dom';

const ServicesSoldDetail = () => {
  const params = useParams();
  const id = params.id ? params.id : '';

  const { data: sale, isLoading, isError } = useGetSale(id);

  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [cancelSaleModal, setCancelSaleModal] = useState(false);
  const { mutate, isPending } = usePostPayments();
  const { data: organization } = useGetOrganization();
  const { mutate: patchCancelSale, isPending: isPendingPatchCancelSale } =
    usePatchCancelSale();

  if (isLoading) {
    return <Skeleton className="w-full h-4/5" />;
  }
  if (!sale || !organization) {
    return (
      <>
        <GoBack />
        <NoData />
      </>
    );
  }
  if (isError) {
    return (
      <>
        <GoBack />
        <ErrorMessage />
      </>
    );
  }

  const onSubmit = (values: CancelSaleValues) => {
    patchCancelSale(
      {
        saleId: sale.id,
        body: values
      },
      { onSuccess: () => setCancelSaleModal(false) }
    );
  };

  if (organization.code === 'viento-en-popa') {
    return (
      <>
        <GoBack />
        <div className="flex justify-center w-full">
          <ServiceSoldCardVP
            sale={sale}
            organization={organization}
            handleAddPayment={() => setAddPaymentModal(true)}
            handleCancelSale={() => setCancelSaleModal(true)}
          />
        </div>
        <AddPaymentModal
          organization={organization}
          sale={sale}
          open={addPaymentModal}
          onOpenChange={setAddPaymentModal}
          onSave={(formValues) => {
            mutate(formValues);
            setAddPaymentModal(false);
          }}
          loading={isPending}
        />
        <CancelSaleModal
          open={cancelSaleModal}
          onOpenChange={setCancelSaleModal}
          loading={isPendingPatchCancelSale}
          onSave={onSubmit}
        />
      </>
    );
  }

  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <ServiceSoldCard
          sale={sale}
          organization={organization}
          handleAddPayment={() => setAddPaymentModal(true)}
          handleCancelSale={() => setCancelSaleModal(true)}
        />
      </div>
      <AddPaymentModal
        organization={organization}
        sale={sale}
        open={addPaymentModal}
        onOpenChange={setAddPaymentModal}
        onSave={(formValues) => {
          mutate(formValues);
          setAddPaymentModal(false);
        }}
        loading={isPending}
      />
      <CancelSaleModal
        open={cancelSaleModal}
        onOpenChange={setCancelSaleModal}
        loading={isPendingPatchCancelSale}
        onSave={onSubmit}
      />
    </>
  );
};

export default ServicesSoldDetail;
