import api from '@/services/api';
import { GetConsumersProps } from '@/services/consumer';
import {
  keepPreviousData,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { useEffect } from 'react';


const getQueryOptions = ({
  pagination,
  filtering,
  sorting,
  columnFilters
}: GetConsumersProps) => {
  return {
    queryKey: [
      'consumers',
      pagination,
      { search: filtering },
      sorting,
      columnFilters
    ],
    queryFn: () =>
      api.consumer.getPaginatedConsumer({
        pagination,
        filtering,
        sorting,
        columnFilters
      })
  };
};

const useGetPaginatedConsumers = (props: GetConsumersProps) => {
  const queryClient = useQueryClient();

  //prefetch next page
  useEffect(() => {
    queryClient.prefetchQuery(
      getQueryOptions({
        ...props,
        pagination: {
          ...props.pagination,
          pageIndex: props.pagination.pageIndex + 1
        }
      })
    );
  }, [queryClient, props]);

  return useQuery({
    ...getQueryOptions(props),
    staleTime: 5 * 1000,
    placeholderData: keepPreviousData
  });
};

export default useGetPaginatedConsumers;
