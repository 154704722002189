import { minAges } from '@/utils/utils';
import { z } from 'zod';
import {
  birthdateSchema,
  emailSchema,
  firstNameSchema,
  heightSchema,
  identificationSchema,
  lastNameSchema,
  phoneSchema,
  weightSchema
} from './common/schemas';

const userSchema = (minAge: number) =>
  z.discriminatedUnion('userIsParticipant', [
    z.object({
      userIsParticipant: z.literal<boolean>(true), // user is participant
      identification: z
        .object({
          number: z.string(),
          type: z.string()
        })
        .partial(),
      email: emailSchema,
      firstName: firstNameSchema,
      lastName: lastNameSchema,
      phone: phoneSchema,
      birthdate: birthdateSchema({ minAge: 18 }),
      height: heightSchema,
      weight: weightSchema
    }),
    z.object({
      userIsParticipant: z.literal<boolean>(false), // user is not participant
      identification: identificationSchema,
      email: emailSchema,
      firstName: firstNameSchema,
      lastName: lastNameSchema,
      phone: phoneSchema,
      birthdate: z.date().optional(),
      height: z.coerce.number().optional(),
      weight: z.coerce.number().optional()
    })
  ]);

const formParticipantDataSchema = ({
  minAge,
  maxAge
}: {
  minAge?: number;
  maxAge?: number;
}) => {
  return z
    .object({
      participants: z.array(
        z.object({
          id: z.string().optional(),
          firstName: firstNameSchema,
          lastName: lastNameSchema,
          birthdate: birthdateSchema({ minAge, maxAge }),
          height: heightSchema,
          weight: weightSchema
        })
      ),
      user: userSchema(minAges.userMinAge)
    })
    .transform((value) => {
      // Removing values depending on whether the user is a participant or not.
      if (value.user.userIsParticipant)
        return {
          ...value,
          user: { ...value.user, identification: undefined }
        };
      else
        return {
          ...value,
          user: {
            ...value.user,
            weight: undefined,
            height: undefined,
            birthdate: undefined
          }
        };
    });
};

export default formParticipantDataSchema;
