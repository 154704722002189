import { ApiResponse } from 'apisauce';
import { Api } from './api';

export class AnalyticsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getMetabaseDashboard(id: number | string): Promise<string | null> {
    const response: ApiResponse<string> = await this.api.apisauce.get(
      `analytics/metabase-dashboard/${id}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data ?? null;
  }
}
