import { HTMLProps, useState } from 'react';
import { cn } from '@/lib/utils';
import { Skeleton } from '@/components/ui/skeleton';
import useGetMetabaseAnalytics from '@/hooks/queries/analytics-api/useGetMetabaseAnalytics';
import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import NoData from '@/components/common/no-data/NoData';

type Props = {
  containerClassName?: HTMLProps<HTMLDivElement>['className'];
  iframeClassName?: HTMLProps<HTMLIFrameElement>['className'];
  dashboardId: number | string;
};

const MetabaseDashboard = ({
  containerClassName = '',
  iframeClassName = '',
  dashboardId
}: Props) => {
  const [loadingMetabaseDashboard, setLoadingMetabaseDashboard] =
    useState(true);

  const {
    data: metabaseUrl,
    isLoading,
    isError
  } = useGetMetabaseAnalytics(dashboardId);
  if (isError) {
    return <ErrorMessage />;
  }
  if (isLoading) {
    return <Skeleton className="w-full h-screen" />;
  }

  if (!metabaseUrl) {
    return <NoData />;
  }
  return (
    <div
      id={`metabase-dashboard-container-${dashboardId}`}
      className={cn(
        'mb-8 overflow-hidden max-[1030px]:h-[2250px] max-[1202px]:h-[1000px] max-[1362px]:h-[1020px] max-[1494px]:h-[1150px] max-[1720px]:h-[1300px] h-[1600px]',
        containerClassName
      )}
    >
      {loadingMetabaseDashboard ? (
        <Skeleton className="w-full h-screen" />
      ) : null}
      <iframe
        onLoad={(e) => setLoadingMetabaseDashboard(false)}
        id={`metabase-dashboard-iframe-${dashboardId}`}
        src={metabaseUrl}
        title="mainDashboard"
        className={cn(
          `w-full h-full overflow-hidden border-none ${loadingMetabaseDashboard ? 'hidden' : 'block'}`,
          iframeClassName
        )}
      />
    </div>
  );
};

export default MetabaseDashboard;
