import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

export const useGetUserByEmail = (email:string) => {
  // const enabled = Boolean(isEmailValid && email);
  const enabled = Boolean(email);
  return useQuery({
    queryKey: ['user', 'email', email],
    queryFn: () => api.user.getUserByEmail(email),
    retry: false,
    enabled,
    refetchOnWindowFocus: false
  });
};
