import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { useTranslation } from 'react-i18next';
import { DeleteServicesModalT } from './delete-services-modal.types';
import { Loader } from 'lucide-react';
import { useParams } from 'react-router-dom';
import useDeleteService from '@/hooks/queries/services-api/useDeleteService';

const DeleteServicesModal = ({ open, onOpenChange }: DeleteServicesModalT) => {
  const { t } = useTranslation();
  const { id: serviceId } = useParams();
  const { mutate, isPending } = useDeleteService();

  const handleDelete = () => {
    if (serviceId !== undefined) mutate(serviceId);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('modals.alert.title')}</DialogTitle>
          <DialogDescription>
            {t('modals.alert.deleteService')}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t('modals.alert.close')}</Button>
          </DialogClose>
          <Button
            variant="destructive"
            onClick={handleDelete}
            disabled={isPending}
          >
            {isPending ? (
              <Loader className="w-4 h-4 animate-spin" />
            ) : (
              t('modals.alert.delete')
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteServicesModal;
