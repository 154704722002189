import type { ReservationInterface } from '../tables.types';
import * as XLSX from 'xlsx';
import { parseDateToDMY } from '@/utils/utils';
import { getAgeRange } from '@/lib/utils';

type GroupedData = {
  Apellido: string;
  Nombre: string;
  Edad: string;
  Contacto: string;
  Extension: string;
  'Manejo en el agua': string;
  Alergias: string;
  'Tratamiento Medico': string;
  Observaciones: string;
  'Fecha de Nacimiento': string;
};

const groupByAgeRange = (data: ReservationInterface[] | undefined) => {
  const groups: { [key: string]: GroupedData[] } = {};
  const addedCustomerIds = new Set<string>();

  if (data) {
    // biome-ignore lint/complexity/noForEach: <explanation>
    data.forEach((row) => {
      const consumer = row.consumers[0];
      if (!consumer || addedCustomerIds.has(consumer.id)) return;

      const ageRange = consumer.birthdate
        ? consumer.additionalData.age
        : 'Sin rango';

      if (!groups[ageRange]) {
        groups[ageRange] = [];
      }

      const bornDate = consumer.birthdate
        ? parseDateToDMY(consumer.birthdate)
        : 'No disponible';

      const parent1 = `${consumer.additionalData.parents[0].connection}: ${consumer.additionalData.parents[0].phone}`;
      const parent2 = `${consumer.additionalData.parents[1].connection}: ${consumer.additionalData.parents[1].phone}`;

      groups[ageRange].push({
        Apellido: consumer.lastName,
        Nombre: consumer.firstName,
        Edad: consumer.additionalData.age || '',
        Contacto: `${parent1}, ${parent2}`,
        Extension: consumer.additionalData.extension ? 'SI' : 'NO',
        'Manejo en el agua': consumer.additionalData.experience,
        Alergias: consumer.additionalData.diseases[2].value
          ? `SI, ${consumer.additionalData.diseases[2].answer}`
          : 'NO',
        'Tratamiento Medico': consumer.additionalData.treatments[2].value
          ? `SI, ${consumer.additionalData.treatments[2].answer}`
          : 'NO',
        Observaciones: row.consumers[0]?.additionalData?.observations || '',
        'Fecha de Nacimiento': bornDate
      });

      addedCustomerIds.add(consumer.id);
    });

    return groups;
  }
};

export const exportToExcel = (data: ReservationInterface[] | undefined) => {
  const workbook = XLSX.utils.book_new();

  const groupedData = groupByAgeRange(data);

  if (groupedData) {
    for (const ageRange of Object.keys(groupedData)) {
      const sheetData = groupedData[ageRange];

      // Crear una hoja vacía
      const worksheet = XLSX.utils.json_to_sheet([]);

      // Agregar el encabezado del grupo en la fila 1
      XLSX.utils.sheet_add_aoa(worksheet, [[`Grupo: ${ageRange}`]], {
        origin: 'A1'
      });

      // Agregar los encabezados de las columnas en la fila 2
      const headers = Object.keys(sheetData[0] || {});
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A2' });

      // Agregar los datos en la fila 3
      XLSX.utils.sheet_add_json(worksheet, sheetData, {
        origin: 'A3',
        skipHeader: true
      });

      // Agregar la hoja al workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, ageRange);
    }
  }

  // Descargar el archivo
  XLSX.writeFile(workbook, 'DatosDeAlumnos.xlsx');
};

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp);
  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;
};

// Exportar asistencias agrupadas por rango de edad
export const exportAttendanceToExcel = (data: any[]) => {
  const workbook = XLSX.utils.book_new();

  // Mapa para organizar los datos por rango de edad
  const groupedData: { [key: string]: any[] } = {};
  const allDates = new Set<string>();

  // biome-ignore lint/complexity/noForEach: <explanation>
  data.forEach((reservation) => {
    const consumer = reservation.consumers[0];
    if (!consumer) return;

    const ageRange = consumer.birthdate
      ? getAgeRange(new Date(consumer.birthdate))
      : 'Sin grupo';

    if (!groupedData[ageRange]) {
      groupedData[ageRange] = [];
    }

    const date = formatDate(reservation.startTime);
    allDates.add(date);

    let consumerData = groupedData[ageRange].find(
      (entry) =>
        entry.Apellido === consumer.lastName &&
        entry.Nombre === consumer.firstName
    );

    if (!consumerData) {
      consumerData = {
        Apellido: consumer.lastName,
        Nombre: consumer.firstName
      };
      groupedData[ageRange].push(consumerData);
    }

    const status =
      reservation.reservationStatus?.name === 'Accepted'
        ? 'Presente'
        : reservation.reservationStatus?.name === 'Absent'
          ? 'Ausente'
          : 'Pendiente';

    consumerData[date] = status;
  });

  const sortedDates = Array.from(allDates).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );

  // biome-ignore lint/complexity/noForEach: <explanation>
  Object.keys(groupedData).forEach((group) => {
    const groupData = groupedData[group].map((row) => {
      // biome-ignore lint/complexity/noForEach: <explanation>
      sortedDates.forEach((date) => {
        if (!row[date]) row[date] = 'Pendiente';
      });
      return row;
    });

    const headers = ['Apellido', 'Nombre', ...sortedDates];
    const worksheet = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_aoa(worksheet, [[`Grupo: ${group}`]], {
      origin: 'A1'
    });

    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A2' });

    XLSX.utils.sheet_add_json(worksheet, groupData, {
      origin: 'A3',
      skipHeader: true
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, group);
  });

  XLSX.writeFile(workbook, 'AsistenciasPorGrupo.xlsx');
};
