import { AuthGuard } from '@/components/section/auth-guard/authGuard';
import { Auth0ProviderWithNavigate } from '@/components/section/auth-provider-with-navigation/authProviderWithNavigation';
import Home from '@/pages/Home';
import NotFound from '@/pages/NotFound';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import CallbackPage from '@/pages/Callback';
import Root from '@/pages/Root';
import { PROTECTED_ROUTES } from './protected';

export const router = createBrowserRouter([
  {
    element: <Auth0ProviderWithNavigate />,
    errorElement: <NotFound />,
    children: [
      // add public routes here
      { path: '/', element: <Home /> },
      { path: '/callback', element: <CallbackPage /> },
      {
        // this layout checks if user is authenticated
        element: <AuthGuard component={Outlet} />,

        children: [
          {
            // layout with navigation components only for protected routes
            element: <Root />,
            // add protected routes here
            children: PROTECTED_ROUTES
          }
        ]
      }
    ]
  }
]);
