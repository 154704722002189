import StudentTables from '@/components/common/tables/students-table/StudentsTable';
import { useTranslation } from 'react-i18next';

const Activity = () => {
  const { t } = useTranslation();
  return (
    <section className="w-full">
      <div className="space-y-2">
        <h1 className="text-2xl font-bold">{t('activity.title')}</h1>
        <h2 className="text-xl ">{t('activity.season')}: 2025</h2>
      </div>

      <div className="my-4">
        <StudentTables />
      </div>
    </section>
  );
};

export default Activity;
