import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import AlertModal from '@/components/common/modals/alert-modal/AlertModal';
import NoData from '@/components/common/no-data/NoData';
import FormInventoryCategory, {
  InventoryCategoryValues
} from '@/components/forms/form-inventory-category/FormInventoryCategory';
import { Skeleton } from '@/components/ui/skeleton';
import useDeleteEquipmentType from '@/hooks/queries/equipments-api/useDeleteEquipmentType';
import usePatchEquipmentTypes from '@/hooks/queries/equipments-api/usePatchEquipmentTypes';
import useGetOrganizationSports from '@/hooks/queries/sports-api/useGetOrganizationSports';
import { TEquipmentType } from '@/services/equipments/equipments.types';
import { getChangedValues } from '@/utils/forms';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const EditInventoryCategory = () => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const data = new URLSearchParams(search).get('category');
  const { data: sports, isLoading, isError } = useGetOrganizationSports();

  const [deleteModal, setDeleteModal] = useState(false);

  const parsedInventoryType: TEquipmentType =
    typeof data === 'string' ? JSON.parse(data) : null;

  const defaultValues = {
    name: parsedInventoryType?.name ?? '',
    description: parsedInventoryType?.description ?? '',
    sports: parsedInventoryType?.sports?.map((sport) => {
      return { label: sport.name, value: sport.id };
    })
  };
  const { mutate, isPending } = usePatchEquipmentTypes();
  const { mutate: deleteEquipmentType, isPending: isPendingDelete } =
    useDeleteEquipmentType();

  const onSubmit = (data: InventoryCategoryValues) => {
    const onlyChangedData = getChangedValues<Partial<InventoryCategoryValues>>(
      defaultValues,
      data
    );
    mutate({
      payload: {
        ...onlyChangedData,
        sports: onlyChangedData.sports?.map((sport) => sport.value)
      },
      equipmentTypeId: parsedInventoryType?.id ?? ''
    });
  };

  if (isLoading) {
    return <Skeleton className="w-full h-2/3" />;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  if (!sports?.length) {
    return <NoData />;
  }

  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <FormInventoryCategory
          onSubmit={onSubmit}
          isPending={isPending}
          defaultValues={defaultValues}
          sports={sports}
          isPendingDelete={isPendingDelete}
          onDelete={() => setDeleteModal(true)}
        />
      </div>
      <AlertModal
        open={deleteModal}
        onOpenChange={setDeleteModal}
        title={t('modals.deleteEquipmentType.title')}
        description={t('modals.deleteEquipmentType.description')}
        cancelText={t('common.cancel')}
        acceptText={t('form.delete')}
        onAccept={() => deleteEquipmentType(parsedInventoryType?.id ?? '')}
        buttonAcceptDestructive
      />
    </>
  );
};

export default EditInventoryCategory;
