import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetConsumerImage = (consumerId: string) => {
  return useQuery({
    queryKey: ['consumer-image', consumerId],
    queryFn: () => api.file.getConsumerImage(consumerId),
    retry: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
};

export default useGetConsumerImage;
