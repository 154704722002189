import DeleteEquipmentModal from '@/components/common/modals/delete-equipment-modal/DeleteEquipmentModal';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import inventorySchema from '@/schemas/inventory-form';
import { TEquipmentType } from '@/services/equipments/equipments.types';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

type TForm = ReturnType<typeof inventorySchema>;

export type InventoryValues = z.infer<TForm>;

type FormInventoryProps = {
  onSubmit: (data: InventoryValues) => void;
  isPending: boolean;
  inventoryCategories: TEquipmentType[];
  defaultValues?: InventoryValues;
};

const FormInventory = ({
  defaultValues,
  isPending,
  onSubmit,
  inventoryCategories
}: FormInventoryProps) => {
  const form = useForm<InventoryValues>({
    resolver: zodResolver(inventorySchema()),
    defaultValues: defaultValues ?? undefined
  });

  const [deleteEquipmentModal, setDeleteEquipmentModal] = useState(false);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={`bg-accent p-8 rounded-md w-full`}
      >
        <div className="flex flex-col gap-3">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.name')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="code"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.code')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('form.description')}
                  <span className="ml-2 text-sm font-light text-gray-500">
                    {t('form.optional')}
                  </span>
                </FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="equipmentType"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>{t('form.category')}</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={t('form.category')} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {inventoryCategories?.map((category) => {
                        return (
                          <SelectItem key={category.id} value={category.id}>
                            {category.name}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button type="submit" className="mt-8" disabled={isPending}>
            {isPending ? (
              <Loader className="w-4 h-4 animate-spin" />
            ) : defaultValues ? (
              t('form.update')
            ) : (
              t('form.create')
            )}
          </Button>

          {defaultValues && (
            <>
              <Button
                variant="destructive"
                type="button"
                className="mt-8"
                onClick={() => setDeleteEquipmentModal(true)}
              >
                {t('form.delete')}
              </Button>
              <DeleteEquipmentModal
                open={deleteEquipmentModal}
                onOpenChange={setDeleteEquipmentModal}
              />
            </>
          )}
        </div>
      </form>
    </Form>
  );
};

export default FormInventory;
