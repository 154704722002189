import { Button } from "@/components/ui/button";
import { Link, isRouteErrorResponse, useRouteError } from "react-router-dom";

const NotFoundPage = () => {

  const error = useRouteError() 
  let errorMessage: string;

  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    errorMessage = error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = 'Unknown error';
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen gap-2 align-middle ">
      <h1 className="mb-6 text-6xl font-bold text-primary" >Oops!</h1>
      <p className="">Sorry, an unexpected error has occurred.</p>
      <p className="text-slate-500">
        <i>{errorMessage}</i>
      </p>
      <Link to={'/'} className="mt-4"><Button>Go back home</Button></Link>
    
    </div>

  );
};

export default NotFoundPage;
