import api from '@/services/api';
import { TUsers } from '@/services/user';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

const useUpdateAdmin = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['uptade-user'],
    mutationFn: (body: TUsers) => api.user.patchAdmin(body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user']
      });
      toast.success(t('toast.userSuccess'));
    },
    onError: (error) => {
      const errorMessage = error?.message;
      toast.error(t('toast.error', { errorMessage }));
    }
  });
};
export default useUpdateAdmin;
