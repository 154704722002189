import { Badge } from '@/components/ui/badge';
import useGetPaginatedSales from '@/hooks/queries/sales-api/useGetPaginatedSales';
import useFormattedDate from '@/hooks/useFormattedDate';
import usePersistedPaginationFiltersAndSorting from '@/hooks/usePersistedPaginationFiltersAndSorting';
import { getHoursConsumed } from '@/utils/reservations';
import { statusVariant } from '@/utils/sales';
import { dateInDataRange, fullName } from '@/utils/utils';
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table';
import { ArrowRight } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../error-message/ErrorMessage';
import TableTooltip from '../../tooltip/table-tooltip/TableTooltip';
import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import TableToolBar from './TableToolBar';
import { ServiceSold } from './services-sold-tables.types';

export function ServicesSoldTableVP<TData, TValue>() {
  const { formattedDate } = useFormattedDate();
  const { t } = useTranslation();

  const {
    pagination,
    setPagination,
    filtering,
    setFiltering,
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters
  } = usePersistedPaginationFiltersAndSorting({
    defaultSorting: [{ id: 'createdAt', desc: true }]
  });

  console.log('filtering', filtering);

  const { data, isLoading, isError, isFetching } = useGetPaginatedSales({
    pagination,
    filtering,
    sorting,
    columnFilters
  });

  const sales = data?.results;

  const columns = useMemo<ColumnDef<ServiceSold>[]>(
    () => [
      {
        id: 'id',
        accessorKey: 'id',
        header: t('tables.common.id'),
        cell: ({ row }) => {
          const id = row?.original?.id;
          return <TableTooltip text={id} />;
        }
      },
      {
        id: 'participants',
        accessorKey: 'participants',
        accessorFn: (originalRow) => {
          const participants = originalRow.participants;
          const participantsName = participants.map((participant) =>
            fullName({
              firstName: participant.firstName,
              lastName: participant.lastName
            })
          );
          return participantsName.join(', ');
        },
        enableSorting: false,
        header: 'Alumno'
      },
      {
        id: 'responsible',
        accessorKey: 'responsible',
        accessorFn: (originalRow) => {
          const { parents } = originalRow.participants[0].additionalData;
          const { firstName, lastName, email, connection } = parents[0];
          return `${connection}: ${firstName} ${lastName} - ${email}`;
        },
        enableSorting: false,
        header: 'Responsable (usuario)'
      },
      {
        id: 'saleItems.id',
        accessorKey: 'service.name',
        header: t('tables.common.activity'),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        }
      },
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        accessorFn: (originalRow) =>
          formattedDate({ date: originalRow.createdAt }),
        header: t('tables.common.date'),
        filterFn: (row, id, value) =>
          dateInDataRange(new Date(row.getValue(id)), value)
      },
      {
        id: 'status',
        accessorKey: 'status',
        accessorFn: (originalRow) => {
          const status = originalRow.status.name;
          return t(`tables.servicesSold.${status}`);
        },
        header: t('tables.common.status'),
        cell: ({ row }) => {
          const status = row.original.status.name;

          return (
            <Badge variant={statusVariant[status]}>
              {t(`tables.servicesSold.${status}`)}
            </Badge>
          );
        }
      },
      {
        id: 'status.observation',
        accessorKey: 'status.observation',
        header: t('tables.servicesSold.observation')
      },
      {
        id: 'actions',
        cell: ({ row }) => {
          const serviceSold = row.original;
          return (
            <Link to={`/services-sold/${serviceSold.id}`}>
              <ArrowRight />
            </Link>
          );
        },
        enableHiding: false
      }
    ],
    [formattedDate, t]
  );

  const table = useReactTable({
    data: sales ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    //pagination config
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    rowCount: data?.total,
    pageCount: Math.ceil((data?.total || 0) / (data?.limit || 1)),
    manualPagination: true, //turn off client-side pagination
    //sort config
    onSortingChange: setSorting,
    enableMultiSort: false,
    manualSorting: true,
    sortDescFirst: true,
    //filter configuration
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    initialState: {
      columnVisibility: {
        id: false
      }
    },
    state: {
      columnFilters,
      pagination,
      sorting
    }
  });

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <TableToolBar
        table={table}
        filtering={filtering}
        setFiltering={setFiltering}
        isFetching={isFetching}
      />

      <TanStackBasicTableTableComponent table={table} isLoading={isLoading} />
    </>
  );
}
