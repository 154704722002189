import { Button } from '@/components/ui/button';
import useGetOrganizationSports from '@/hooks/queries/sports-api/useGetOrganizationSports';
import usePersistedPaginationFiltersAndSorting from '@/hooks/usePersistedPaginationFiltersAndSorting';
import { TSports } from '@/services/sports';
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { EditIcon, PlusIcon } from 'lucide-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ErrorMessage from '../../error-message/ErrorMessage';
import TableColumnsToggle from '../../table-columns-toggle/TableColumnsToggle';
import TableTooltip from '../../tooltip/table-tooltip/TableTooltip';
import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import TableSearchInput from '../common/table-search-input/TableSearchInput';
import { getFeatureFlagValue } from '@/utils/utils';

const ServicesCategoriesTable: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: sports, isLoading, isError } = useGetOrganizationSports();
  const emptyData = !sports?.length;

  const {
    pagination,
    setPagination,
    filtering,
    setFiltering,
    sorting,
    setSorting
  } = usePersistedPaginationFiltersAndSorting({
    defaultSorting: [{ id: 'name', desc: false }]
  });

  const featureFlagCrudValue = getFeatureFlagValue('crud');

  const columns = useMemo<ColumnDef<TSports>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: t('sports.name')
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: t('sports.description'),
        cell: ({ row }) => {
          const description = row?.original?.description;
          return (
            <TableTooltip
              text={description || '-'}
              className="w-[12rem] line-clamp-3"
            />
          );
        }
      },
      {
        id: 'id',
        accessorKey: 'id',
        header: t('tables.common.id'),
        cell: ({ row }) => {
          const id = row?.original?.id;
          return <TableTooltip text={id} className="w-[20rem] line-clamp-3" />;
        }
      },
      {
        id: 'actions-edit',
        enableHiding: false,
        cell: ({ row }) => {
          const categoryId = row.original.id;
          if (!featureFlagCrudValue) {
            return undefined;
          }
          return (
            <Link
              to={{
                pathname: `./edit/${categoryId}`,
                search: `?category=${JSON.stringify(row.original)}`
              }}
              className="w-full"
            >
              <EditIcon />
            </Link>
          );
        }
      }
    ],
    [t, featureFlagCrudValue]
  );

  const table = useReactTable({
    data: sports ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //pagination config
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    autoResetPageIndex: false,
    //filter config
    onGlobalFilterChange: setFiltering,
    getFilteredRowModel: getFilteredRowModel(),
    //sort config
    enableMultiSort: false,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      globalFilter: filtering,
      pagination
    }
  });

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <div
        className={`flex items-center gap-2 py-4 ${featureFlagCrudValue ? 'justify-between' : 'justify-end'}`}
      >
        {featureFlagCrudValue && (
          <Button
            onClick={() => navigate('./create')}
            className="flex items-center gap-2"
          >
            <PlusIcon className="w-5" />
            <p>{t('form.create')}</p>
          </Button>
        )}
        <div className="flex items-center gap-2">
          <TableSearchInput
            value={filtering ?? ''}
            onChange={(event) => {
              setFiltering(event.target.value);
            }}
          />
          <TableColumnsToggle disabled={emptyData} table={table} />
        </div>
      </div>
      <TanStackBasicTableTableComponent table={table} isLoading={isLoading} />
    </>
  );
};

export default ServicesCategoriesTable;
