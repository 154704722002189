import BlockEquipment from '@/components/section/block-equipment/BlockEquipment';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useTranslation } from 'react-i18next';
import { TEquipmentAvailModal } from './equipmentAvailModal';

export function EquipmentAvailabilityModal({
  open,
  onOpenChange,
  equipmentData
}: Readonly<TEquipmentAvailModal>) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {`${t('form.availabilityEquipment.materialAvailability')}: `}
            <p className="mt-2 text-base text-gray-600 ">
              {`${t('common.code')}: ${equipmentData.code} - ${t('common.name')}: ${equipmentData.name}`}
            </p>
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className="max-h-[80vh] pr-4">
          <BlockEquipment
            equipment={equipmentData}
            onOpenChange={onOpenChange}
          />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
