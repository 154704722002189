import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable
} from '@tanstack/react-table';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table';
import usePatchPayments from '@/hooks/queries/payments-api/usePatchPayments';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import useFormattedDate from '@/hooks/useFormattedDate';
import { getPaymentStatusTexts } from '@/utils/reservations';
import { currencyFormat } from '@/utils/utils';
import { format } from 'date-fns';
import { EditIcon } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddPaymentModal from '../../modals/add-payment-modal/AddPaymentModal';
import {
  Payment,
  ServiceSold,
  TOrganization
} from '../services-sold-table/services-sold-tables.types';

export function ServicesSoldPaymentsTable<TData, TValue>({
  sale,
  organization
}: {
  sale: ServiceSold;
  organization: TOrganization;
}) {
  const { mutate, isPending } = usePatchPayments();
  const { t } = useTranslation();
  const { formattedDate } = useFormattedDate();

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [paymentSelected, setPaymentSelected] = useState<Payment | null>(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const currentLocale = useCurrentLanguage();

  const columns: ColumnDef<Payment>[] = [
    {
      accessorKey: 'reference',
      header: t('modals.addPayment.orderReference'),
      accessorFn: (originalRow) =>
        originalRow.reference && originalRow.paymentType.name === 'TPV'
          ? `#${originalRow.reference}`
          : '-'
    },
    {
      accessorKey: 'amount',
      header: t('modals.addPayment.amountPaid'),
      accessorFn: (originalRow) =>
        currencyFormat(originalRow.amount, organization.currency)
    },
    {
      accessorKey: 'paymentStatus.name',
      header: t('modals.addPayment.paymentStatus'),
      cell: ({ row }) => {
        const paymentStatus = row?.original?.paymentStatus.name;
        return paymentStatus ? (
          <Badge
            variant={paymentStatus}
            className="justify-center h-8 justify-self-end"
          >
            {getPaymentStatusTexts(paymentStatus)}
          </Badge>
        ) : null;
      }
    },
    {
      accessorKey: 'paymentType.name',
      header: t('modals.addPayment.paymentType')
    },
    {
      accessorKey: 'observations',
      header: t('modals.addPayment.comments')
    },
    {
      accessorKey: 'paidAt',
      header: t('tables.common.paidAt'),
      accessorFn: (originalRow) =>
        formattedDate({ date: Number(originalRow.paidAt) })
    },

    {
      accessorKey: 'updatedAt',

      header: t('tables.common.updatedAt'),
      accessorFn: (originalRow) =>
        formattedDate({ date: originalRow.updatedAt })
    },
    {
      id: 'actions',
      enableSorting: false,
      enableHiding: false,
      cell: ({ row }) => {
        const isWeb = row.original.paymentType.name === 'TPV';
        if (isWeb) return null;
        return (
          <Button
            variant={'ghost'}
            onClick={() => {
              setPaymentSelected(row.original);
              setOpenPaymentModal(true);
            }}
          >
            <EditIcon />
          </Button>
        );
      }
    }
  ];

  const table = useReactTable({
    data: sale.payments,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    state: {
      globalFilter
    }
  });

  return (
    <>
      <div className="bg-white border rounded-b-md">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel()?.rows?.length ? (
              table.getRowModel()?.rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t('common.noResults')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {openPaymentModal && paymentSelected ? (
        <AddPaymentModal
          organization={organization}
          open={openPaymentModal}
          onOpenChange={setOpenPaymentModal}
          sale={sale}
          paymentSelected={paymentSelected}
          onSave={(formValues) => {
            mutate({ paymentId: paymentSelected?.id, data: formValues });
            setOpenPaymentModal(false);
            setPaymentSelected(null);
          }}
          loading={isPending}
        />
      ) : null}
    </>
  );
}
