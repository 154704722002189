import api from '@/services/api';
import { skipToken, useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

const useDeleteConsumerImage = (consumerId: string | undefined) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['delete-consumer-image'],
    mutationFn: (fileId: string) => api.file.deleteConsumerImage(fileId),
    onError: () => {
      toast.error(t('toast.errorDeletingImage'), { duration: 5000 });
    },
    onSuccess: () => {
      queryClient.setQueryData(['consumer-image', consumerId], null);
      toast.success(t('toast.deletingImageSuccess'));
    },
    retry: false
  });
};

export default useDeleteConsumerImage;
