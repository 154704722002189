import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip';
import React from 'react';
import TableTooltipProps from './TableTooltip.types';

const TableTooltip: React.FC<TableTooltipProps> = ({
  text,
  content,
  className = 'max-w-[6rem] line-clamp-1'
}) => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <p className={className}>{text}</p>
        </TooltipTrigger>
        <TooltipContent>
          <p className="max-w-64">{content ?? text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default TableTooltip;
