import {
  ColumnFiltersState,
  PaginationState,
  SortingState
} from '@tanstack/react-table';
import { ApiResponse } from 'apisauce';
import { format } from 'date-fns';
import { Api } from './api';
import { TConsumer } from './consumer';

export interface TIdentification {
  number?: string;
  type?: string;
}

export interface TEmergencyContact {
  phone?: string;
  fullName?: string;
}

export interface TUser {
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  id: string;
  authId: string;
  firstName: string;
  lastName: string;
  username: string | null;
  email: string | null;
  phone: string | null;
  birthdate: string;
  weight: number;
  height: number;
  address: string | null;
  location: string | null;
  postalCode: string | null;
  verified: boolean;
  identification: TIdentification | null;
}

export interface TUsers {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  active?: boolean;
  id?: string;
  authId?: string;
  firstName?: string;
  lastName?: string;
  username?: string | null;
  email?: string;
  phone?: string;
  birthdate?: string;
  weight?: number;
  height?: number;
  address?: string | null;
  location?: string | null;
  postalCode?: string | null;
  verified?: boolean;
  user?: TUser;
  identification?: TIdentification;
  emergencyContact?: TEmergencyContact;
  contactOrigin?: string;
  receiveNotifications?: boolean;
  consumers?: TConsumer[];
}

export type GetUsersProps = {
  pagination: PaginationState;
  filtering: string;
  sorting: SortingState;
  columnFilters: ColumnFiltersState;
};

type TGetUsers = {
  total: number;
  limit: number;
  offset: number;
  page: number;
  hasNextPage: boolean;
  results: TUsers[];
};

export class UserApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async userExistsByEmail(email: string): Promise<boolean | undefined> {
    const response: ApiResponse<boolean> = await this.api.apisauce.post(
      'user/check-email',
      { email }
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getUsers(): Promise<TUsers[] | undefined> {
    const response: ApiResponse<{
      total: number;
      offset: number;
      hasNextPage: boolean;
      results: TUsers[] | undefined;
    }> = await this.api.apisauce.get('user');
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results;
  }

  async getUserByEmail(email: string): Promise<TUsers | undefined> {
    const response: ApiResponse<TUsers> = await this.api.apisauce.get(
      `user/email/${email}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async patchUser(id: string, body: TUsers): Promise<any | undefined> {
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `user/admin/${id}`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results;
  }

  async patchAdmin(body: TUsers): Promise<any | undefined> {
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `user`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results;
  }

  async getUser(): Promise<TUsers | undefined> {
    const response: ApiResponse<TUsers> =
      await this.api.apisauce.get('user/me');
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getPaginatedUsers({
    pagination,
    sorting,
    filtering,
    columnFilters
  }: GetUsersProps): Promise<TGetUsers | undefined> {
    //set paignation
    const page = pagination.pageIndex + 1;
    const limit = pagination.pageSize;

    // set sorting
    let sortBy = '';
    let sortDirection = '';

    //set columnFilters
    let columnFilterURI = '';

    columnFilters.forEach((filter) => {
      const valuesOfFilter = filter.value;
      let valueQueryString: string = '';

      if (Array.isArray(valuesOfFilter)) {
        valueQueryString = `&filter.${filter.id}=string.in.${valuesOfFilter.join(',')}`;
      } else {
        const dateRange = valuesOfFilter as {
          from: string;
          to: Date | undefined;
        };
        if (!dateRange.to) {
          const date = new Date(dateRange.from);
          // Set the time to the last hour of the day (23:00:00)
          date.setHours(23, 0, 0, 0);
          dateRange.to = date;
        }
        if (dateRange.from && dateRange.to) {
          valueQueryString = `&filter.createdAt=date.between.${format(dateRange.from, "yyyy-MM-dd'T'HH:mm:ss")},${format(dateRange.to, "yyyy-MM-dd'T'HH:mm:ss")}`;
        }
      }

      columnFilterURI += valueQueryString;
    });

    for (let i = 0; i < sorting.length; i++) {
      const id = sorting[i].id,
        direction = sorting[i].desc ? 'DESC' : 'ASC';
      sortBy += id;
      sortDirection += direction;

      if (i !== sorting.length - 1) {
        sortBy += ',';
        sortDirection += ',';
      }
    }

    let URI = `user?limit=${limit}&page=${page}`;
    if (filtering) {
      const filteringWithoutDot = filtering.replace('.', ''); //we remove [.] to avoid 500 error. Needs to be reviewd if we search for emails
      URI += `&filter.or.firstName=string.like.${filteringWithoutDot}&filter.or.lastName=string.like.${filteringWithoutDot}&filter.or.id=string.like.${filteringWithoutDot}`;
    }
    if (sorting.length) {
      URI += `&sortBy=${sortBy}&sortDirection=${sortDirection}`;
    }
    if (columnFilters.length) {
      URI += columnFilterURI;
    }
    const response: ApiResponse<TGetUsers> = await this.api.apisauce.get(URI);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
