import Pagination from '@/components/common/pagination/pagination';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/components/ui/table';
import { flexRender, Header, Table as TableType } from '@tanstack/react-table';
import { t } from 'i18next';
import { Loader } from 'lucide-react';

interface TanStackBasicTableTableComponentProps<TData> {
  table: TableType<TData>;
  isLoading: boolean;
}

export function TanStackBasicTableTableComponent<TData>({
  table,
  isLoading
}: TanStackBasicTableTableComponentProps<TData>) {
  const sortToggler = (header: Header<TData, unknown>) => {
    if (header.column.getCanSort()) {
      header.column.toggleSorting(undefined, true);
    }
  };

  return (
    <>
      <div className="border rounded-md">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((header) => (
              <TableRow key={header?.id}>
                {header.headers.map((header) => (
                  <TableHead key={header?.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        onClick={() => sortToggler(header)}
                        className={
                          header.column.getCanSort()
                            ? 'hover:cursor-pointer'
                            : ''
                        }
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {(header.column.getIsSorted() === 'asc' ||
                          header.column.getIsSorted() === 'desc') && (
                          <span>
                            {header.column.getIsSorted() === 'asc' && '↑'}
                            {header.column.getIsSorted() === 'desc' && '↓'}
                          </span>
                        )}
                      </div>
                    )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={100} className="h-24 text-center">
                  <div className="flex items-center justify-center">
                    <Loader className="animate-spin" />
                  </div>
                </TableCell>
              </TableRow>
            ) : table.getRowModel()?.rows?.length ? (
              table.getRowModel()?.rows.map((row) => (
                <TableRow
                  key={row?.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell?.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={100} className="h-24 text-center">
                  {t('common.noResults')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <Pagination
        actualPage={table.getState().pagination.pageIndex + 1}
        totalPages={table.getPageCount()}
        onPreviousPage={() => table.previousPage()}
        onNextPage={() => table.nextPage()}
        isPreviousPageAvailable={table.getCanPreviousPage()}
        isNextPageAvailable={table.getCanNextPage()}
        results={!!table.getRowModel().rows.length}
      />
    </>
  );
}
