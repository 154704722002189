import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog';
import { AlertModalT } from './AlertModal.types';
import { buttonVariants } from '@/components/ui/button';
const AlertModal = ({
  open,
  onOpenChange,
  title,
  description,
  cancelText,
  acceptText,
  onCancel,
  onAccept,
  buttonAcceptDestructive
}: AlertModalT) => {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {cancelText ? (
            <AlertDialogCancel>{cancelText}</AlertDialogCancel>
          ) : null}
          <AlertDialogAction
            onClick={() => (onAccept ? onAccept() : null)}
            className={
              buttonAcceptDestructive
                ? buttonVariants({ variant: 'destructive' })
                : undefined
            }
          >
            {acceptText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AlertModal;
