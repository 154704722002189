import EquipmentAssignmentForm from '@/components/forms/form-equipment-assign/EquipmentAssignmentForm';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import useGetEquipmentsAssignable from '@/hooks/queries/equipments-api/useGetEquipmentsAssignable';
import { t } from 'i18next';
import { Loader } from 'lucide-react';
import { z } from 'zod';
import { MaterialModalT } from './material-modal.types';

export function MaterialModal({
  open,
  onOpenChange,
  onSave,
  reservation,
  consumer
}: MaterialModalT) {
  const { data: materialAvailable, isLoading } = useGetEquipmentsAssignable(
    reservation.id
  );
  const createValidationSchema = () => {
    let schema = z.object({});
    const selectSchema = z.string().min(1, {
      message: t('form.materialEquipmentAssign.selectOption')
    });
    materialAvailable?.forEach((option, index) => {
      schema = schema.merge(z.object({ [`select${index}`]: selectSchema }));
    });
    return schema;
  };
  const createDefaultValues = () => {
    let defaultValues = {};
    materialAvailable?.forEach((option, index) => {
      defaultValues = { ...defaultValues, [`select${index}`]: '' };
    });
    return defaultValues;
  };
  const validationSchema = createValidationSchema();
  const defaultValues = createDefaultValues();
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('tables.reservations.material')}</DialogTitle>
        </DialogHeader>
        {!isLoading && materialAvailable ? (
          <EquipmentAssignmentForm
            reservationId={reservation.id}
            consumerId={consumer.id}
            onSave={onSave}
            materialAvailable={materialAvailable}
            validationSchema={validationSchema}
            defaultValues={defaultValues}
          />
        ) : (
          <div className="flex items-center justify-center">
            <Loader className="animate-spin" />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
