import { Badge } from '@/components/ui/badge';
import { TOrganizationFAQ } from '@/services/organization';
import { capitalizeFirstLetter, currencyFormat } from '@/utils/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ServiceSold } from '../../tables/services-sold-table/services-sold-tables.types';

const UserSalesCard = ({
  sales,
  organization
}: {
  sales: ServiceSold[];
  organization: TOrganizationFAQ;
}) => {
  const { t } = useTranslation();

  return (
    <div className="min-w-full p-5 rounded-md bg-accent">
      <h1 className="mb-6 font-bold">
        {t("userSalesCard.title")}
      </h1>
      <div className="grid grid-cols-1 gap-3 xl:grid-cols-2">
        {sales.map((sale) => (
          <Link key={sale.id} to={`/services-sold/${sale.id}`}>
            <div className="p-6 bg-white rounded-md ">
              <div className="flex items-start justify-between mb-3">
                <h1 className="font-bold text-md text-primary">
                  {sale.service.name}
                </h1>
                <div className="flex-shrink-0 ml-3">
                  {sale.amountPending === 0 ? (
                    <Badge  variant={'Approved'}>{t("userSalesCard.paid")}</Badge>
                  ) : (
                    <Badge
                      variant={'Pending'}
                    >{`${t("userSalesCard.pendingPayment")}: ${currencyFormat(sale.amountPending, organization.currency)}`}</Badge>
                  )}
                </div>
              </div>

              <h2 className="font-semibold ">
                {t('tables.servicesSold.participants')}
              </h2>
              {sale.participants.map((consumer) => (
                <div className="flex flex-col gap-2" key={consumer.id}>
                  <p className="text-start">
                    {capitalizeFirstLetter(consumer.firstName)}{' '}
                    {capitalizeFirstLetter(consumer.lastName)}
                  </p>
                </div>
              ))}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default UserSalesCard;
