import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/components/ui/accordion';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import { TOrganization } from '@/services/organization';
import { getHoursLeft, getReservationsTexts } from '@/utils/reservations';
import { statusVariant } from '@/utils/sales';
import { capitalizeFirstLetter } from '@/utils/utils';
import { ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AlertSection from '../../alert/AlertSection';
import { ServicesSoldPaymentsTable } from '../../tables/services-sold-payments-table/ServicesSoldPaymentsTable';
import { ServiceSold } from '../../tables/services-sold-table/services-sold-tables.types';
import PaymentsStats from './PaymentsStats';
const ServiceSoldCard = ({
  sale,
  handleAddPayment,
  handleCancelSale,
  organization
}: {
  sale: ServiceSold;
  handleAddPayment: () => void;
  handleCancelSale: () => void;
  organization: TOrganization;
}) => {
  const { t } = useTranslation();
  const saleCanceled = sale.status.name === 'canceled';

  return (
    <div className="flex flex-col justify-between min-w-full p-5 rounded-md bg-accent">
      <div className="flex items-start justify-between mb-6">
        <div>
          <h1 className="font-bold">{sale.service.name}</h1>
          <h3 className="mt-2 mb-1 text-sm font-bold">
            {t('tables.servicesSold.participants')}
          </h3>
          {sale.participants.map((consumer) => (
            <div className="flex flex-col gap-2" key={consumer.id}>
              <Link to={`/users/edit/${consumer.id}`}>
                <p className="mt-1 cursor-pointer text-start hover:underline">
                  {capitalizeFirstLetter(consumer.firstName)}{' '}
                  {capitalizeFirstLetter(consumer.lastName)}
                </p>
              </Link>
            </div>
          ))}
        </div>

        <Badge variant={statusVariant[sale.status.name]}>
          {t(`tables.servicesSold.${sale.status.name}`)}
        </Badge>
      </div>

      {saleCanceled ? (
        <AlertSection
          variant="error"
          text={t('alertSection.saleCanceled', {
            observation: sale.status.observation
          })}
        />
      ) : (
        <>
          <Progress
            value={sale.service.completedPct}
            className="border border-primary"
          />
          <p className="mt-2 mb-6 text-sm">
            {`${getHoursLeft(sale.reservations)} ${t('tables.servicesSold.hoursLeftMessage')}`}
          </p>

          <Accordion
            type="single"
            className="px-4 bg-white rounded-md"
            collapsible
            defaultValue="1"
          >
            <AccordionItem value={'1'}>
              <AccordionTrigger className="px-2">
                {t('tables.servicesSold.hoursLeft')}
              </AccordionTrigger>
              <AccordionContent className="flex flex-col pb-0">
                {sale.reservations
                  .sort((a, b) => a.order - b.order)
                  .map((reservation, index) => {
                    return (
                      <Link
                        key={reservation.id}
                        to={`/reservations/${reservation.id}`}
                        className={`py-4 px-2  flex items-center justify-between border-gray-300 ${
                          index === 0 ? '' : 'border-t'
                        } `}
                      >
                        <span>{reservation.name}</span>
                        <div className="flex items-center gap-2">
                          <Badge variant={reservation.status}>
                            {getReservationsTexts(reservation.status)}
                          </Badge>
                          <ChevronRight width={16} height={16} />
                        </div>
                      </Link>
                    );
                  })}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
          <Accordion
            type="single"
            className="w-full px-4 mt-2 bg-white rounded-md"
            collapsible
            defaultValue="1"
          >
            <AccordionItem value={'1'}>
              <AccordionTrigger className="px-2">
                {t('tables.servicesSold.payments')}
              </AccordionTrigger>
              <AccordionContent className="flex flex-col">
                <>
                  <PaymentsStats sale={sale} organization={organization} />
                  <ServicesSoldPaymentsTable
                    sale={sale}
                    organization={organization}
                  />
                </>
              </AccordionContent>
            </AccordionItem>
          </Accordion>

          <div className="flex flex-col gap-4 mt-6">
            <Button onClick={handleAddPayment} disabled={!sale.amountPending}>
              {t('modals.addPayment.title')}
            </Button>
            <Button variant="destructive" onClick={handleCancelSale}>
              {t('modals.canceledServiceSold.title')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ServiceSoldCard;
