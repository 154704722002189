import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetServicesEnabled = () => {
  return useQuery({
    queryKey: ['services', 'enabled'],
    queryFn: () => api.services.getServicesEnabled(),
  });
}


export default useGetServicesEnabled;
