import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import ServiceForm from '@/components/forms/form-service/ServiceForm';
import { ServicesFormValues } from '@/components/forms/form-service/ServiceForm.types';
import { Skeleton } from '@/components/ui/skeleton';
import usePostService from '@/hooks/queries/services-api/usePostService';
import useGetOrganizationSports from '@/hooks/queries/sports-api/useGetOrganizationSports';
import { useTranslation } from 'react-i18next';

const CreateService = () => {
  const { t } = useTranslation();
  const {
    data: sport,
    isError: isErrorSport,
    isLoading: isLoadingSport
  } = useGetOrganizationSports();
  const { mutate, isPending } = usePostService();

  if (isLoadingSport) {
    return (
      <>
        <GoBack />
        <Skeleton className="w-full h-[50vh]" />
      </>
    );
  }

  if (isErrorSport) {
    return (
      <>
        <GoBack />
        <NoData />
        <ErrorMessage />
      </>
    );
  }

  if (!sport) {
    return (
      <>
        <GoBack />
        <NoData />
      </>
    );
  }

  const onSubmit = (data: ServicesFormValues) => {
    mutate(data);
  };

  return (
    <div className="mb-4">
      <GoBack />
      <div className="p-5 space-y-6 rounded-md bg-accent">
        <p className="font-bold text-md text-primary">
          {t('form.createService')}
        </p>
        <ServiceForm onSubmit={onSubmit} isPending={isPending} sport={sport} />
      </div>
    </div>
  );
};

export default CreateService;
