import { Button } from '@/components/ui/button';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ExperienceForm: React.FC<{
  form: UseFormReturn<any, any, undefined>;
  onSave: () => Promise<void>;
}> = ({ form, onSave }) => {
  const { t } = useTranslation();

  const handleValidate = () => {
    onSave();
  };

  return (
    <div
      className="flex flex-col gap-8 p-6 border rounded-md border-primary"
      key={'participant-participant'}
    >
      <FormField
        control={form.control}
        name="experience"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('levels-vp.level')}</FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder={t('levels-vp.placeholder')} />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value={t('levels-vp.high')}>
                  {t('levels-vp.high')}
                </SelectItem>
                <SelectItem value={t('levels-vp.medium')}>
                  {t('levels-vp.medium')}
                </SelectItem>
                <SelectItem value={t('levels-vp.low')}>
                  {t('levels-vp.low')}
                </SelectItem>
                <SelectItem value={t('levels-vp.beginner')}>
                  {t('levels-vp.beginner')}
                </SelectItem>
              </SelectContent>
            </Select>
            <FormDescription>{t('levels-vp.levelDescription')}</FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      <Button type="button" onClick={handleValidate}>
        {t('common.validate')}
      </Button>
    </div>
  );
};

export default ExperienceForm;
