import ServiceDivisionCard from '@/components/common/cards/service-division-card/ServiceDivisionCard';
import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import { Skeleton } from '@/components/ui/skeleton';
import { TServiceDivision } from '@/services/servicesDivision';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const ServiceDivision: React.FC<{ serviceDivision:  TServiceDivision[] | undefined, isLoading: boolean, isError: boolean }> = ({
  serviceDivision,
  isLoading,
  isError
}) => {
  const { t } = useTranslation();

  const renderServiceDivision = () => {
    if (isLoading) {
      return (
        <div className="w-full lg:w-[20rem] bg-accent p-4 rounded-md">
          <Skeleton className="w-full h-[15rem]" />
        </div>
      );
    }

    if (isError) {
      return <ErrorMessage />;
    }

    if (!serviceDivision) {
      return null
    }

    return serviceDivision.map((division) => (
      <ServiceDivisionCard key={division.id} data={division} />
    ));
  };

  return (
    <div className="flex flex-wrap gap-4">
      {renderServiceDivision()}
      <Link to={`./create-division`}>
        <div className="p-6 bg-accent rounded-md w-full md:w-[20rem] min-h-[15rem] active:bg-gray-200">
          <div className='flex items-center gap-2 mb-3'>
            <Plus className="w-5 h-5" />
            <h1 className="text-lg font-bold">
              {t('serviceDetail.createServiceDivision.title')}
            </h1>
          </div>
          <p className='text-sm'>{t('serviceDetail.createServiceDivision.description')}</p>
        </div>
      </Link>
    </div>
  );
};

export default ServiceDivision;
