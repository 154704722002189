import api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const useDeleteService = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: ['deleteService'],
    mutationFn: (serviceId: string) => {
      return api.services.deleteService(serviceId);
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: (serviceId) => {
      queryClient.invalidateQueries({ queryKey: ['services', `${serviceId}`] });
      toast.success(t('toast.deletedService'));
      navigate(-2);
    },
    retry: false
  });
};

export default useDeleteService;
