import { Input } from '@/components/ui/input';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

type TableSearchInputProps = {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export default function TableSearchInput({
  value,
  onChange,
}: TableSearchInputProps) {
  const { t } = useTranslation();

  return (
    <Input
      type="search"
      placeholder={t('common.search') + '...'}
      value={value ?? ''}
      onChange={onChange}
      className="max-w-sm"
    />
  );
}
